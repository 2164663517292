import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from '.';
import {accessoriesService} from '../api/accessories';
import {setSuccess} from './presetSlice';
import {currencyPrice} from "../../utils/currencyPrice";

export type Accessories = {
    id: string;
    name_dk: string;
    name_eng: string;
    item_number: number;
    image?: File;
    price_dkk: number;
    price_sek: number;
    price_nok: number;
    price_gbp: number;
    price_eur: number;
    net_weight: number;
    quantity: number;
    total_price: number;
};

type accessoriesSliceInitialState = {
    loading: boolean;
    accessoriesList: Accessories[];
    accessoriesListTemp: Accessories[]; // Временное сохранение несохраненных accessories.
    // составляется из общего списка accessoriesList и списка, который приходит из getPresetAccessories
    // При загрузке сохранении апдейте пресета, удаляется
    sceneIsClear: boolean;
};

const initialState: accessoriesSliceInitialState = {
    loading: false,
    accessoriesList: [],
    accessoriesListTemp: [],
    sceneIsClear: false,
};

type TotalSumPayload = {
    id?: string;
    operator: '+' | '-';
    currency?: string;
};

const accessoriesSlice = createSlice({
    name: 'accessories',
    initialState,
    reducers: {
        setLoading: (state, { payload }) => {
            if (payload) {
                console.log('loading accessories');
            }
            state.loading = payload;
        },
        setAccessoriesList: (state, { payload }) => {
            state.accessoriesList = payload;
        },
        setAccessoriesListTempTotalSum: (state, { payload }: { payload: TotalSumPayload }) => {
            const { id, operator, currency} = payload;
            state.accessoriesListTemp = state.accessoriesListTemp.map((i) => {
                if (i.id === id) {
                    const newQuantity = operator === '+' ? i.quantity + 1 : i.quantity - 1;
                    const fixed = (currencyPrice(i, currency) * newQuantity).toFixed(2);
                    return {
                        ...i,
                        quantity: newQuantity,
                        total_price: Number(fixed),
                    }
                }
                return i;
            });
        },
        setAccessoriesListTemp: (state, { payload }: { payload: Accessories[] }) => {
            state.accessoriesListTemp = payload;
        },
        setSceneIsClear: (state, { payload }) => {
            state.sceneIsClear = payload;
        },
    },
});

export const {
    setLoading,
    setAccessoriesList,
    setSceneIsClear,
    setAccessoriesListTemp,
    setAccessoriesListTempTotalSum,
} = accessoriesSlice.actions;

export const getAccessoriesList = createAsyncThunk('/accessories', async (_, { dispatch }) => {
    dispatch(setLoading(true));
    dispatch(setSuccess(null));
    const response = await accessoriesService.getListAccessories();
    if (response.status === 200) {
        let data = response.data;
        dispatch(setAccessoriesList(data));
    }
    dispatch(setLoading(false));
});

export const selectAccessories = (state: RootState) => state.accessories.accessoriesList;
export const selectAccessoriesTemp = (state: RootState) => state.accessories.accessoriesListTemp;
export const sceneIsClear = (state: RootState) => state.accessories.sceneIsClear;
export default accessoriesSlice.reducer;
