import React, { useState } from 'react';
import { Box, Step, Stepper, styled, IconButton } from '@mui/material';
import StepActiveIcon from '../../icons/StepActiveIcon.svg';
import StepEmptyIcon from '../../icons/StepEmptyIcon.svg';
import BackButton from '../../icons/BackButtonIcon.svg';
import { useHistory } from 'react-router-dom';
import { useHelp } from '../../hooks/useHelp';
import { useTranslation } from 'react-i18next';
import { relative } from 'path';

interface StepProps {
    step?: number;
    label?: string;
    action?: () => void;
}

const StepPanel = ({ step, label, action }: StepProps) => {
    const history = useHistory();
    const { t } = useTranslation();
    const stepItem = [
        {
            title: t('stepper.firstStepTitle').toUpperCase(),
            sub_title: t('stepper.firstStepSubTitle'),
        },
        {
            title: t('stepper.secondStepTitle').toUpperCase(),
            sub_title: t('stepper.secondStepSubTitle'),
        },
        {
            title: t('stepper.thirdStepTitle').toUpperCase(),
            sub_title: t('stepper.thirdStepSubTitle'),
        },
        {
            title: t('stepper.fourthStepTitle').toUpperCase(),
            sub_title: t('stepper.fourthStepSubTitle'),
        },
    ];
    const [activeStep, setActiveStep] = useState(step ? step : 0);
    const [visible, setVisible] = useState(step === 0 ? false : true);
    return (
        <>
            <Box
                component="div"
                display="flex"
                flexDirection="row"
                alignItems="center"
                style={{
                    background: '#E6EBEF',
                }}
                sx={{
                    height: {
                        xl: '87px',
                        lg: '87px',
                        md: '50px',
                    },
                    padding: {
                        xl: '4px 60px',
                        lg: '4px 60px',
                        md: '4px 60px',
                        sm: '4px 0px',
                    },
                }}
            >
                <Box component="div" display="flex" justifyContent="flex-start" flex="0.5">
                    {visible ? (
                        <IconButton
                            onClick={() => {
                                history.goBack();
                                if (action) action();
                            }}
                        >
                            <Box component="div" display="flex" flexDirection="row" gap="5px">
                                <Box component="img" alt="" src={BackButton} />
                                <TextButton>{label}</TextButton>
                            </Box>
                        </IconButton>
                    ) : (
                        <></>
                    )}
                </Box>
                <Stepper activeStep={activeStep} sx={{position:'relative'}}>
                    {stepItem.map((item, index) => (
                        <StyledStep key={index} className={activeStep === index ? 'active' : 'inactive'}>
                            <Box
                                component="div"
                                display="flex"
                                alignItems="center"
                                sx={{
                                    flexDirection: {
                                        lg: 'column',
                                        md: 'row',
                                    },
                                    gap: {
                                        xl: '0px',
                                        lg: '0px',
                                        md: '4px',
                                        sm: '4px',
                                    },
                                }}
                            >
                                {activeStep === index ? (
                                    <Box
                                        component="img"
                                        alt=""
                                        src={StepActiveIcon}
                                        sx={{
                                            width: {
                                                xl: '28px',
                                                lg: '28px',
                                                md: '16px',
                                                sm: '16px',
                                            },
                                            height: {
                                                xl: '28px',
                                                lg: '28px',
                                                md: '16px',
                                                sm: '16px',
                                            },
                                        }}
                                    />
                                ) : index < activeStep ? (
                                    <Box
                                        component="img"
                                        alt=""
                                        src={StepActiveIcon}
                                        sx={{
                                            width: {
                                                xl: '28px',
                                                lg: '28px',
                                                md: '16px',
                                                sm: '16px',
                                            },
                                            height: {
                                                xl: '28px',
                                                lg: '28px',
                                                md: '16px',
                                                sm: '16px',
                                            },
                                        }}
                                    />
                                ) : (
                                    <Box
                                        component="img"
                                        alt=""
                                        src={StepEmptyIcon}
                                        sx={{
                                            width: {
                                                xl: '28px',
                                                lg: '28px',
                                                md: '16px',
                                                sm: '16px',
                                            },
                                            height: {
                                                xl: '28px',
                                                lg: '28px',
                                                md: '16px',
                                                sm: '16px',
                                            },
                                        }}
                                    />
                                )}
                                <StyledTitle>{item.title}</StyledTitle>
                            </Box>
                            <StyledSubTitle>{item.sub_title}</StyledSubTitle>
                        </StyledStep>
                    ))}
                </Stepper>
            </Box>
        </>
    );
};

const StyledStep = styled(Step)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    opacity: '0.6',
    '&.active': {
        opacity: '1',
    },
    [theme.breakpoints.down('lg')]: {
        alignItems: 'flex-start',
    },
}));

const StyledTitle = styled(Box)(({ theme }) => ({
    marginTop: '4px',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '20px',
    color: theme.palette.primary.main,
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
        fontSize: '14px',
        lineHeight: '16px',
    },
}));

const StyledSubTitle = styled(Box)(({ theme }) => ({
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '14px',
    lineHeight: '16px',
    textAlign: 'center',
    color: '#66869f',
    [theme.breakpoints.down('lg')]: {
        fontSize: '12px',
        lineHeight: '14px',
    },
}));

const TextButton = styled(Box)(({ theme }) => ({
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '20px',
    color: theme.palette.primary.main,
}));

export default StepPanel;
