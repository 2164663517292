
/*
 A simple method for iteratively finding a point offset
 to get a rectangle with dimensions X and Z into the camera's view angle,
 given that the camera lies on the continuation of the diagonal of the rectangle.
 X, Z - dimensions of rectangle
 cosAngle - cos(A), where A is an angle of view of camera
 returns delta X
*/
export function findDeltaXOfOptimalCameraPosition(x: number, z: number, cosAngle: number) : number {
  const d2 = x*x + z*z;
  const step = x / 100;
  let xD = 0;
  let zD = 0;
  let f = 1000000;
  let minXD = xD;
  for (let i = 0; i < 1000; i += 1) {
    xD = i * step;
    zD = xD * z/x;
    const l1 = Math.sqrt((x + xD) * (x+xD) + zD * zD);
    const l2 = Math.sqrt(xD*xD + (z+zD)*(z+zD));
    let newF = Math.abs(l1*l1 + l2*l2 - 2*l1*l2*cosAngle - d2);
    if (newF < f) {
      minXD = xD;
      f = newF;
    }
  }
  return minXD;
}
