import React, { useEffect, useMemo, useState } from 'react';
import StepPanel from '../../components/StepPanel';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    styled,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
} from '@mui/material';
import useAppSelector from '../../hooks/useAppSelector';
import { overviewPreset, allComponentsPreset } from '../../services/store/presetSlice';
import useAppDispatch from '../../hooks/useAppDispatch';
import GeneratePdf from '../../components/GeneratePdf';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useHelp } from '../../hooks/useHelp';
import { currentName } from '../../utils/currentName';
import { setShow } from '../../services/store/app3dSlice';
import { ImageEntity } from '../../types/app3d';

const Overview = () => {
    const { presetId } = useParams<{
        presetId: string;
    }>();

    const { t } = useTranslation();
    const [tab, setTab] = useState<number>(0);
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(setShow(false));
        return () => {
            dispatch(setShow(true));
        };
    }, [dispatch]);
    const preset = useAppSelector((state) => state.presets.overviewPreset);
    const idNewPreset = useAppSelector((state) => state.presets.idNewPreset);
    const [isAssembly, setIsAssembly] = useState('0');
    const [pdfViewer, setPdfViewer] = useState(false);
    const { currency, language, brand, packagesModel, setTotalSum, setWeight } =
      useHelp();
    const itemList = useAppSelector((state) => state.presets.overviewItemList);
    const me = useAppSelector((state) => state.user.me);
    const {previews} = useAppSelector((state) => state.app3d);
    let img = previews.find(p=>p.label === 'overview') as ImageEntity;

    // useEffect(() => {
    //     const images: ImageEntity[] = [];
    //     const usedKeys: string[] = [];
    //     previews.forEach(preview => {
    //         if (preview.label !== 'overview' && !usedKeys.includes(preview.label)) {
    //             images.push(preview);
    //             usedKeys.push(preview.label);
    //         }
    //     });
    //
    //     console.log(images);
    //
    //     images.forEach((image, index) => {
    //         const box = document.createElement('div');
    //         const img = document.createElement('img');
    //         img.src = image.src;
    //         img.style.height = '500px';
    //         box.appendChild(img);
    //         box.style.position = 'absolute';
    //         box.style.top = 500 * index + 'px';
    //         box.style.left = '0';
    //         box.style.zIndex = '999999';
    //         document.body.appendChild(box);
    //     });
    // }, [previews])

    useEffect(() => {
        dispatch(overviewPreset(presetId));
        dispatch(allComponentsPreset(presetId));
    }, [idNewPreset]);

    const currencyPrice = (item: any) => {
        switch (currency) {
            case 'gbp':
                return (item?.price_gbp ?? 0) + ' GBP';
            case 'dkk':
                return (item?.price_dkk ?? 0) + ' DKK';
            case 'eur':
                return (item?.price_eur ?? 0) + ' EUR';
            case 'nok':
                return (item?.price_nok ?? 0) + ' NOK';
            case 'sek':
                return (item?.price_sek ?? 0) + ' SEK';
            default:
                return (item?.price_dkk ?? 0) + " DKK";
        }
    };
    const filteredComponents = isAssembly === '0' ? itemList?.with_assembly : itemList?.without_assembly;
    let totalSumAccessoiries = currencyPrice(preset?.total_sum_accessories);
    let totalSum = isAssembly === '0' ? currencyPrice(preset?.total_sum_assembly) : currencyPrice(preset?.total_sum);
    useEffect(() => {
        setTotalSum(totalSum);
    }, [totalSum, setTotalSum]);

    const newTotalWeightWithAccessories = useMemo(() => {
      const totalWeightAccessories = preset?.accessories.reduce(
        (totalWeight, item) =>
          totalWeight + Number(item.net_weight * item.quantity),
        0
      );

      const unitPresetsWeights = preset?.unit_presets.map((unitPreset) =>
        unitPreset.components.reduce(
          (unitPresetWeight, component) =>
            unitPresetWeight + Number(component.net_weight),
          0
        )
      );

      const totalWeightUnitPresets = unitPresetsWeights?.reduce(
        (totalWeight, unitPresetWeight) => totalWeight + unitPresetWeight,
        0
      );

      const totalWeight =
        (totalWeightAccessories ?? 0) + (totalWeightUnitPresets ?? 0);

      return totalWeight.toFixed(3);
    }, [preset?.accessories, preset?.unit_presets]);

    useEffect(() => {
      setWeight(newTotalWeightWithAccessories);
    }, [newTotalWeightWithAccessories, setWeight]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsAssembly(event.target.value);
    };
    const handleCloseViewer = () => {
        setPdfViewer(false);
    };

    return (
        <Box
            component="div"
            sx={{
                minHeight: '80vh',
            }}
        >
            <StepPanel step={3} label={t('Overview.backButton')} />
            <Box component="div">
                <Box component="div" display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                    <StyledTabsList variant="standard" orientation="horizontal" value={tab}>
                        <StyledTab label={t('Overview.tabs.firsTab')} onClick={() => setTab(0)} />
                        <StyledTab label={t('Overview.tabs.secondTab')} onClick={() => setTab(1)} />
                    </StyledTabsList>
                </Box>
                {tab === 0 ? (
                    <Box
                        component="div"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                            minHeight: '60vh',
                            flexDirection: {
                                xl: 'row',
                                lg: 'row',
                                md: 'row',
                                sm: 'column',
                            },
                        }}
                    >
                        <Box component="div">
                            {img ? (
                                <Box component="img" src={img.src} style={{ height: 'auto', width: '40vw' }} />
                            ) : (
                                <></>
                            )}
                        </Box>
                        <Box component="div">
                            <StyledCheckoutBox>
                                <CheckoutTitle>{t('Overview.chekoutCard.title')}</CheckoutTitle>
                                <TableContainer sx={{ marginBottom: '20px' }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <CheckoutTableCell>
                                                    {t('Overview.chekoutCard.tableColumn.colPos')}
                                                </CheckoutTableCell>
                                                <CheckoutTableCell>
                                                    {t('Overview.chekoutCard.tableColumn.colName')}
                                                </CheckoutTableCell>
                                                <CheckoutTableCell>
                                                    {t('Overview.chekoutCard.tableColumn.colTP')}
                                                </CheckoutTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {preset?.unit_presets?.map((row, index) => (
                                                <CheckoutTableRow key={index}>
                                                    <CheckoutTableRowCell>{row.position_in_auto}</CheckoutTableRowCell>
                                                    <CheckoutTableRowCell>{row.name}</CheckoutTableRowCell>
                                                    <CheckoutTableRowCell>
                                                        {currencyPrice(row.total_sum)}
                                                    </CheckoutTableRowCell>
                                                </CheckoutTableRow>
                                            ))}
                                            <CheckoutTableRow>
                                                <CheckoutTableCell colSpan={1}>
                                                    {t('Overvirw.chekoutCard.firstTableRow')}
                                                </CheckoutTableCell>
                                                <CheckoutTableCell></CheckoutTableCell>
                                                <CheckoutTableCell>
                                                    {currencyPrice(preset?.total_sum_accessories)}
                                                </CheckoutTableCell>
                                            </CheckoutTableRow>
                                            <CheckoutTableRow>
                                                <CheckoutTableCell colSpan={1}>
                                                    {t('Overvirw.chekoutCard.secondTableRow')}
                                                </CheckoutTableCell>
                                                <CheckoutTableCell></CheckoutTableCell>
                                                <CheckoutTableCell>
                                                    {isAssembly === '0'
                                                        ? currencyPrice(preset?.assembly_per_section)
                                                        : '-'}
                                                </CheckoutTableCell>
                                            </CheckoutTableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <FormControl sx={{ marginBottom: '20px' }}>
                                    <RadioGroup value={isAssembly} onChange={handleChange}>
                                        <FormControlLabel
                                            value="0"
                                            control={<Radio />}
                                            label={t('Overview.chekoutCard.radioButton.saveTime')}
                                        />
                                        <FormControlLabel
                                            value="1"
                                            control={<Radio />}
                                            label={t('Overview.chekoutCard.radioButton.saveMoney')}
                                        />
                                    </RadioGroup>
                                </FormControl>
                                <Box component="div" display="flex" flexDirection="row" justifyContent="space-between">
                                    <Box component="div" display="flex" flexDirection="column">
                                        <CheckoutFooterTitle>
                                            {t('Overview.chekoutCard.footerCard.title')}
                                        </CheckoutFooterTitle>
                                        <CheckoutFooterSubTitle>
                                            {t('Overview.chekoutCard.footerCard.subTitle')}
                                        </CheckoutFooterSubTitle>
                                    </Box>
                                    <Box component="div" display="flex" alignItems="center">
                                        <CheckoutFooterTitle>
                                            {isAssembly === '0'
                                                ? currencyPrice(preset?.total_sum_assembly)
                                                : currencyPrice(preset?.total_sum)}
                                        </CheckoutFooterTitle>
                                    </Box>
                                </Box>
                            </StyledCheckoutBox>
                        </Box>
                    </Box>
                ) : (
                    <Box
                        component="div"
                        mt="0px"
                        sx={{
                            padding: {
                                xl: '0px 360px 0px 360px',
                                lg: '0px 130px 0px 130px',
                                md: '0px 60px 0px 60px',
                                sm: '0px 30px 0px 30px',
                            },
                        }}
                    >
                        <TableContainer
                            sx={{
                                height: {
                                    xl: '684px',
                                    lg: '649px',
                                    md: '452px',
                                    sm: '754px',
                                },
                            }}
                        >
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <StyledHeadCell>{t('Overview.listTable.colName')}</StyledHeadCell>
                                        <StyledHeadCell>{t('Overview.listTable.colId')}</StyledHeadCell>
                                        <StyledHeadCell>{t('Overview.listTable.colUP')}</StyledHeadCell>
                                        <StyledHeadCell>{t('Overview.listTable.colWeight')}</StyledHeadCell>
                                        <StyledHeadCell>{t('Overview.listTable.colQ')}</StyledHeadCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredComponents?.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableRowCell>{currentName(row, language)}</StyledTableRowCell>
                                            <StyledTableRowCell>{row.item_number}</StyledTableRowCell>
                                            <StyledTableRowCell>{currencyPrice(row)}</StyledTableRowCell>
                                            <StyledTableRowCell>{row.net_weight}</StyledTableRowCell>
                                            <StyledTableRowCell>{row.quantity}</StyledTableRowCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                )}
                <Box
                    component="div"
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                    mt="10px"
                    gap="12px"
                >
                    <StyledButton onClick={() => setPdfViewer(true)}>{t('Overview.buttons.pdfButton')}</StyledButton>
                </Box>
                <Dialog open={pdfViewer} onClose={handleCloseViewer} style={{ minWidth: '600px', minHeight: '800px' }}>
                    <DialogContent>
                        <PDFViewer style={{ width: '600px', height: '700px' }}>
                            <GeneratePdf
                                preset={preset}
                                user={me}
                                isAssemble={isAssembly === '0'}
                                code={preset?.code}
                                accessories={preset?.accessories}
                                components={isAssembly === '0' ? itemList?.with_assembly : itemList?.without_assembly}
                                unitPresets={
                                    isAssembly === '0'
                                        ? itemList?.unit_preset.with_assembly
                                        : itemList?.unit_preset.without_assembly
                                }
                                sumAccessories={totalSumAccessoiries}
                                totalSum={totalSum}
                                brand={brand}
                                model={packagesModel}
                                previews={previews}
                                overviewPreview={img}
                            />
                        </PDFViewer>
                    </DialogContent>
                    <DialogActions>
                        <PDFDownloadLink
                            document={
                                <GeneratePdf
                                    preset={preset}
                                    user={me}
                                    isAssemble={isAssembly === '0'}
                                    code={preset?.code}
                                    accessories={preset?.accessories}
                                    components={
                                        isAssembly === '0' ? itemList?.with_assembly : itemList?.without_assembly
                                    }
                                    unitPresets={
                                        isAssembly === '0'
                                            ? itemList?.unit_preset.with_assembly
                                            : itemList?.unit_preset.without_assembly
                                    }
                                    sumAccessories={totalSumAccessoiries}
                                    totalSum={totalSum}
                                    brand={brand}
                                    model={packagesModel}
                                    previews={previews}
                                    overviewPreview={img}
                                />
                            }
                            fileName="Config.pdf"
                            style={{
                                textDecoration: 'none',
                                padding: '5px',
                                color: '#FCFDFD',
                                backgroundColor: '#00365F',
                                border: '1px solid #4a4a4a',
                                textAlign: 'center',
                                width: '167px',
                                borderRadius: '14px',
                                fontStyle: 'normal',
                                fontSize: '24px',
                                lineHeight: '24px',
                            }}
                        >
                            Save file
                        </PDFDownloadLink>
                    </DialogActions>
                </Dialog>
            </Box>
        </Box>
    );
};

const StyledTabsList = styled(Tabs)(({ theme }) => ({
    background: '#e6ebef',
    borderLeft: '100px transparent',
    borderRight: '100px transparent',
    borderRadius: '0 0 15px 15px / 0 0 100% 100% ',
    boxShadow: '0px 2px 2px rgba(0, 54, 95, 0.15)',
    '& .MuiTabs-indicator': {
        height: '0',
    },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
    display: 'flex',
    height: '50px',
    width: '170px',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '18px',
    lineHeight: '18px',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    color: theme.palette.secondary.main,
    '&.Mui-selected': {
        background: '#F2F5F7',
        boxShadow: 'inset -4px 6px 3px rgba(0, 54, 95, 0.15)',
    },
}));

const StyledHeadCell = styled(TableCell)(({ theme }) => ({
    background: '#F2F5F7',
    fontStyle: 'normal',
    fontSize: '18px',
    lineHeight: '20px',
    fontWeight: '600',
    color: theme.palette.primary.main,
    padding: '8px',
    border: 'none',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(2n)': {
        backgroundColor: '#FCFDFD',
    },
    backgroundColor: '#F2F5F7',
}));

const StyledTableRowCell = styled(TableCell)(({ theme }) => ({
    padding: '8px',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '20px',
    color: theme.palette.primary.main,
    border: 'none',
}));

const StyledButton = styled(Button)(({ theme }) => ({
    background: theme.palette.primary.main,
    color: '#FCFDFD',
    width: '167px',
    borderRadius: '14px',
    fontStyle: 'normal',
    fontSize: '24px',
    lineHeight: '24px',
    textTransform: 'none',
}));

const StyledCheckoutBox = styled(Box)(({ theme }) => ({
    padding: '32px',
    maxWidth: '390px',
    width: '100%',
    maxHeight: '432px',
    height: '100%',
    background: '#FCFDFD',
    border: '1px solid #E5EBEF',
}));

const CheckoutTitle = styled(Box)(({ theme }) => ({
    marginBottom: '20px',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '36px',
    lineHeight: '40px',
    textAlign: 'center',
    color: theme.palette.primary.main,
}));

const CheckoutTableCell = styled(TableCell)(({ theme }) => ({
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '20px',
    letterSpacing: '0.1px',
    textAlign: 'left',
    padding: '2px',
    color: theme.palette.secondary.main,
}));

const CheckoutTableRowCell = styled(TableCell)(({ theme }) => ({
    fontStyle: 'normal',
    padding: '2px',
    fontWeight: '400',
    fontSize: '18px',
    textAlign: 'left',
    lineHeight: '20px',
    letterSpacing: '0.1px',
    color: theme.palette.secondary.main,
}));

const CheckoutTableRow = styled(TableRow)(({ theme }) => ({
    borderBottom: '1px solid #F4F5F5',
}));

const CheckoutFooterTitle = styled(Box)(({ theme }) => ({
    fontStyle: 'normal',
    fontSize: '18px',
    fontWeight: '900',
    lineHeight: '20px',
    letterSpacing: '0.1px',
    color: theme.palette.primary.main,
}));

const CheckoutFooterSubTitle = styled(Box)(({ theme }) => ({
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '14px',
    letterSpacing: '0.1px',
    color: theme.palette.secondary.main,
}));

export default Overview;
