import { ISceneEntity, Shelf } from "./Shelf";
import * as THREE from "three";
import {
  isRackFeatureHuge,
  setMaterialForHugeRackFeature,
  createText,
  createLine,
} from "./helpers";
import CarEntity from "./CarEntity";
import { Default } from "../../consts/scene";
import { RackComponent, ShelfComponent } from "../../types/component";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import { positionToSide } from "../../utils/component";
import { Side } from "../../types/general";
import { ShelfType } from "../../types/scene";
import { getShelfType } from "./helpers";
import { Resource, GltfResource } from "../../types/resources";
import {
  Mode,
  NotDragableShelf,
  OuterShelf,
  isNotDragableShelf,
  isOuterShelf,
} from "../../types/scene";

type ConstraintRackByHeight = { min?: number; max?: number };
type CheckCanAddShelfResult = { result: boolean; message: string };
export type ButtonAction = "remove" | "select" | "configure" | "rotate";
const ActionsPerState = {
  rack: ["close", "choose", "edit", "delete", "rotate"],
  place: ["close", "choose"],
};
export type ButtonParams = {
  material: THREE.SpriteMaterial;
  action: ButtonAction;
  detail: ButtonDetail;
};
export type objToBeAttachedTo = {
  add: (...objects: Array<THREE.Object3D>) => void;
};

export type ButtonDetail = { uuid: string; parentUuid?: string };
export class ButtonEntity extends THREE.Sprite implements ISceneEntity {
  private _entityType = "button";
  private _action: string;
  private _detail: ButtonDetail;

  constructor({ material, action, detail }: ButtonParams) {
    super(material);
    this._action = action;
    this._detail = detail;
  }
  public get entityType(): string {
    return this._entityType;
  }
  public get action(): string {
    return this._action;
  }
  public get detail(): ButtonDetail {
    return this._detail;
  }
}

export type Entity = "mesh" | "button" | "shelf" | "staff" | "label";

export type ViewEntity<T extends string, N extends string> = {
  type: T;
  object: THREE.Object3D;
  name: N;
};

export class View<T extends string, N extends string> {
  private _group = new THREE.Group();
  private entities = new Map<N, ViewEntity<T, N>>();

  public getEntitiesByType(type: Entity): Array<ViewEntity<T, N>> {
    return Array.from(this.entities.values()).filter((e) => e.type === type);
  }
  public getEntityByName(name: N): ViewEntity<T, N> | null {
    return this.entities.get(name) ?? null;
  }
  public removeEntityByName(name: N): void {
    const entity = this.getEntityByName(name);
    if (entity) {
      entity.object.parent?.remove(entity.object);
      this.entities.delete(name);
    }
  }
  public getEntitiesNames(): Array<N> {
    return Array.from(this.entities.keys());
  }
  public get position(): THREE.Vector3 {
    return this._group.position;
  }
  public get worldPosition(): THREE.Vector3 {
    return this._group.getWorldPosition(new THREE.Vector3());
  }
  public addEntity(viewEntity: ViewEntity<T, N>): void {
    if (this.getEntityByName(viewEntity.name))
      throw Error("Name must be unique");
    this._group.updateWorldMatrix(true, true);
    this._group.add(viewEntity.object);
    this.entities.set(viewEntity.name, viewEntity);

    this._group.updateWorldMatrix(true, true);
  }
  public reattachEntity(viewEntity: ViewEntity<T, N>): void {
    this._group.attach(viewEntity.object);

    this._group.updateWorldMatrix(true, true);
  }

  public attachTo<T extends objToBeAttachedTo>(object: T): void {
    object.add(this._group);
  }
  public hide(): void {
    this._group.visible = false;
  }
  public show(): void {
    this._group.visible = true;
  }
  public setPosition(vector: THREE.Vector3) {
    this._group.position.copy(vector);
    this._group.updateWorldMatrix(true, true);
  }
  public get group(): THREE.Group {
    return this._group;
  }
}

function createButton(
  action: ButtonAction,
  detail: ButtonDetail
): ButtonEntity {
  let spriteSize = Default.spriteSize;
  const map = new THREE.TextureLoader().load(`/${action}Button.png`);
  let material = new THREE.SpriteMaterial({ map: map });
  let sprite = new ButtonEntity({ material, action, detail });
  sprite.scale.set(spriteSize * 1.3, spriteSize, spriteSize * 1.3);
  return sprite;
}

function createPlaceObject(): THREE.Object3D {
  const cube = new THREE.Mesh(
    new THREE.BoxGeometry(
      Default.width / Default.scale,
      Default.height / Default.scale,
      Default.depth / Default.scale
    ),
    new THREE.MeshPhongMaterial({
      color: 0xffad00,
      flatShading: true,
      opacity: 0.1,
      transparent: true,
    })
  );
  cube.visible = true;
  cube.geometry.translate(0, Default.height / Default.scale / 2, 0);
  // cube.scale.copy(new THREE.Vector3(Default.width, Default.height, Default.depth).multiplyScalar(1 / Default.scale));
  return cube;
}

type RackProps = {
  carEntity: CarEntity;
  side: Side;
  scene: objToBeAttachedTo;
  uuid: string;
};

type State = "rack" | "place";
export class Place {
  static globalIdx: { left: number; right: number } = { left: 0, right: 0 };
  private _glbUrl = "default";
  private _width = Default.width;
  private _height = Default.height;
  private _depth = Default.depth;
  private _door = false;
  public shelves: Array<Shelf> = [];
  public view: View<Entity, State | ButtonAction | string>;
  private carEntity: CarEntity;
  private _side: Side;
  private state: State | null = "place";
  private placeIdx: number;
  private viewMode: Mode = "main";
  private alreadyFit = false;
  public id = 0;
  public rotated = false;
  private bbox: THREE.BoxHelper | null = null;
  public isActive = false;
  public kitId = "";
  private notDragableShelvesMap = new Map<NotDragableShelf, Shelf>();
  private outerShelvesMap = new Map<OuterShelf, Shelf>();
  private _uuid: string;
  constructor({ carEntity, side, scene, uuid }: RackProps) {
    this.view = new View();
    this._side = side;
    this.placeIdx = Place.globalIdx[side];
    this.carEntity = carEntity;
    this.view.attachTo(scene);
    this._uuid = uuid;
    Place.globalIdx[side] += 1;
    this.initPlace();
  }

  public get isLastFilledPlaceInSide(): boolean {
    return (
      this.placeIdx === Place.globalIdx[this.side] - 1 && this.state === "rack"
    );
  }

  public get label(): string {
    return this.placeIdx < 3
      ? `${this.side === "left" ? "V" : "H"}${this.placeIdx + 1}`
      : `${this.placeIdx - 2}`;
  }
  public getPlaceIdx(): number {
    return this.placeIdx;
  }
  public setRotated(isRotated: boolean): void {
    const hasPanel = this.shelves
      .map((shelf) => shelf.type)
      .find((s) => s === "panel_l" || s === "panel_r");
    if (hasPanel) {
      throw new Error("Can't rotate place");
    }
    this.rotated = isRotated;
    this.view.group.rotateY(isRotated ? Math.PI : -Math.PI);
    const label = this.view.getEntitiesByType("label")[0];
    const buttons = this.view.getEntitiesByType("button");
    label.object.rotateY(isRotated ? -Math.PI : Math.PI);
    // const tempGroup = new THREE.Group();
    // buttons.forEach((button) => {
    //   tempGroup.attach(button.object);
    // });
    // tempGroup.rotateY(isRotated ? -Math.PI : Math.PI);
    // buttons.forEach((button) => {
    //   this.view.reattachEntity(button);
    // });
  }

  public getShelves(): Array<Shelf> {
    return this.shelves;
  }
  private getMinMaxHeightForView(): ConstraintRackByHeight {
    const rack = this.view.getEntityByName("rack");
    if (!rack) return {};
    const bbox = new THREE.Box3().setFromObject(rack.object);
    return { min: bbox.min.y, max: bbox.max.y };
  }

  private getMinMaxWidthForView(): ConstraintRackByHeight {
    const rack = this.view.getEntityByName("rack");
    if (!rack) return {};
    const bbox = new THREE.Box3().setFromObject(rack.object);
    return { min: bbox.min.x, max: bbox.max.x };
  }
  private getMinMaxWidthForReal(): ConstraintRackByHeight {
    const rack = this.view.getEntityByName("rack");
    if (!rack) return {};
    return { min: 0, max: this._width };
  }

  private getMinMaxHeightForReal(): ConstraintRackByHeight {
    const rack = this.view.getEntityByName("rack");
    if (!rack) return {};
    return { min: 0, max: this._height };
  }

  public translateViewHeightToRealHeight(height: number): number {
    const old = this.getMinMaxHeightForView();
    const new_ = this.getMinMaxHeightForReal();
    if (
      old.min == null ||
      old.max == null ||
      new_.min == null ||
      new_.max == null
    )
      return 0;
    const newHeight =
      ((height - old.min) / (old.max - old.min)) * (new_.max - new_.min) +
      new_.min;
    return newHeight > this._height ? this._height : newHeight;
  }

  private translateRealHeightToViewHeight(height: number): number {
    const new_ = this.getMinMaxHeightForView();
    const old = this.getMinMaxHeightForReal();
    if (
      old.min == null ||
      old.max == null ||
      new_.min == null ||
      new_.max == null
    )
      return 0;
    return (
      ((height - old.min) / (old.max - old.min)) * (new_.max - new_.min) +
      new_.min
    );
  }

  private translateRealWidthToViewWidth(height: number): number {
    const new_ = this.getMinMaxWidthForView();
    const old = this.getMinMaxWidthForReal();
    if (
      old.min == null ||
      old.max == null ||
      new_.min == null ||
      new_.max == null
    )
      return 0;
    return (
      ((height - old.min) / (old.max - old.min)) * (new_.max - new_.min) +
      new_.min
    );
  }

  public getBbox(): THREE.BoxHelper | null {
    return this.bbox;
  }
  public get uuid(): string {
    return this._uuid;
  }
  public initFromComponent(
    { estimatePosition, depth, height, width }: RackComponent,
    resource: GLTF
  ): void {
    this._side = positionToSide(estimatePosition);
    // this._glbUrl = component.glb_model;
    this._depth = depth ?? this._depth;
    this._height = height ?? this._height;
    this._width = width ?? this._width;
    const rackFeatures = new Array<THREE.Mesh>();
    resource.scene.clone().traverse((object) => {
      if (object instanceof THREE.Mesh) {
        object.rotateY(Math.PI);
        rackFeatures.push(object.clone());
      }
    });
    this.fillPlace(rackFeatures);
    this.fitToCar();
  }
  public hasShelf(name: string): boolean {
    return this.shelves.filter((s) => s.name === name).length > 0;
  }
  public getShelf(name: string): Shelf | null {
    return this.shelves.find((s) => s.name === name) ?? null;
  }
  public hasArc() {
    return (
      this.carEntity.arcHeight() > 0 &&
      this.getPlaceIdx() === 0 &&
      this.carEntity.car.params.a > 0
    );
  }
  private isShelfCanPlaced(position: number, shelf: Shelf): boolean {
    const possible = (self: Shelf, other: Shelf) => {
      if (self.name === other.name || isOuterShelf(other.type)) return true;
      const otherSizeTop = other.position + other.height;
      const otherSizeBot = other.position;
      if (position > otherSizeTop || position + self.height < otherSizeBot)
        return true;
      return false;
    };

    return this.shelves.every((s) => possible(shelf, s));
  }
  public initPlace(): void {
    if (this.side === "right") this.view.group.rotateY(Math.PI);
    const { uuid } = this;
    this.view.addEntity({
      name: "place",
      type: "mesh",
      object: createPlaceObject(),
    });
    this.view.addEntity({
      name: "choose",
      type: "button",
      object: createButton("select", { uuid }),
      // object: createText(this.label + 'choose'),
    });
    this.view.addEntity({
      name: "close",
      type: "button",
      object: createButton("remove", { uuid }),
      // object: createText(this.label + 'close'),
    });
    this.view.addEntity({
      name: "edit",
      type: "button",
      object: createButton("configure", { uuid }),
      // object: createText(this.label + 'edit'),
    });
    this.view.addEntity({
      name: "rotate",
      type: "button",
      object: createButton("rotate", { uuid }),
      // object: createText(this.label + 'edit'),
    });
    const label = createText(this.label, 0.08);
    this.view.addEntity({
      name: "label",
      type: "label",
      object: label,
    });
    if (this.side === "right") label.rotateY(Math.PI);
    this.updateButtonPositions();
  }

  public removeShelf(name: string): void {
    const shelf = this.shelves.find((s) => s.name === name);
    if (!shelf) return;
    if (isNotDragableShelf(shelf.type)) {
      this.notDragableShelvesMap.delete(shelf.type);
      this.updateButtonPositions();
      this.updateLines();
    }
    if (shelf.type === "top") {
      const verticalMainLine = createLine({
        lineWidth: this._height / Default.scale,
        direction: "vertical",
        label: `${this._height}`,
        side: "left",
      });
      verticalMainLine.position.x += -0.4 - this._width / Default.scale / 2;
      verticalMainLine.position.z += this._depth / Default.scale / 2;
      this.view.removeEntityByName(`verticalMainLine`);
      this.view.addEntity({
        name: `verticalMainLine`,
        object: verticalMainLine,
        type: "staff",
      });
    }
    this.view.removeEntityByName(`delete_${shelf.name}`);
    this.view.removeEntityByName(shelf.name);
    this.shelves = this.shelves.filter((s) => s.name !== name);
    this.view
      .getEntitiesByType("staff")
      .filter((s) => s.name.includes("verticalShelfLine"))
      .forEach((e) => this.view.removeEntityByName(e.name));
    this.updateLines();
  }

  public updateLines() {
    const sortedShelves = this.shelves.sort((a, b) => b.position - a.position);
    let startHeight = this._height;

    if (!sortedShelves.length) return;

    sortedShelves.forEach((shelf) => {
      if (isOuterShelf(shelf.type) && shelf.type !== "top") return;
      // TODO: костыль с top
      const shelfPos =
        shelf.type === "top" ? this._height + 177 : shelf.position;
      const lineWidth =
        shelf.type === "top"
          ? 177 / Default.scale
          : (startHeight - shelfPos) / Default.scale;
      const label =
        shelf.type === "top"
          ? "177"
          : `${Math.abs(startHeight - shelfPos).toFixed()}`;
      const verticalLine = createLine({
        lineWidth: lineWidth,
        direction: "vertical",
        label: label,
        side: "right",
      });
      verticalLine.position.x += -0.2 - this._width / Default.scale / 2;
      verticalLine.position.z += this._depth / Default.scale / 2;
      verticalLine.position.y =
        shelf.type === "top"
          ? this._height / Default.scale
          : startHeight / Default.scale -
            (startHeight - shelf.position) / Default.scale;
      this.view.removeEntityByName(`verticalShelfLine_${shelf.name}`);
      this.view.addEntity({
        name: `verticalShelfLine_${shelf.name}`,
        object: verticalLine,
        type: "staff",
      });
      startHeight = shelf.position || startHeight;
    });
    const verticalLine = createLine({
      lineWidth: startHeight / Default.scale,
      direction: "vertical",
      label: `${startHeight.toFixed()}`,
      side: "right",
    });
    verticalLine.position.x += -0.2 - this._width / Default.scale / 2;
    verticalLine.position.z += this._depth / Default.scale / 2;
    verticalLine.position.y = 0;
    this.view.removeEntityByName(`verticalShelfLine_end`);
    this.view.addEntity({
      name: `verticalShelfLine_end`,
      object: verticalLine,
      type: "staff",
    });
  }
  public getNotDragablAlreadyInstalledShelves(): ShelfType[] {
    return Array.from(this.notDragableShelvesMap.keys());
  }
  public canAddShelfFromComponent(
    shelfComponent: ShelfComponent
  ): CheckCanAddShelfResult {
    const shelfType = getShelfType(shelfComponent);
    if (shelfType === "top") {
      if (this.notDragableShelvesMap.has(shelfType)) {
        return { result: false, message: "Already has top box" };
      }
      if (this.carEntity.car.params.h <= 117 + this.height) {
        return { result: false, message: "Can fit top box" }; //TODO: костыль
      }
    }
    if (shelfType === "vertical") {
      if (
        this.notDragableShelvesMap.has(shelfType) ||
        this.notDragableShelvesMap.has("locker")
      ) {
        return { result: false, message: "Already has this type of shelf " };
      }
      if (this.hasArc()) {
        return {
          result: false,
          message: " Can not add vertical in position because of wheel arc ",
        };
      }
    }
    if (shelfType === "locker") {
      if (
        this.notDragableShelvesMap.has(shelfType) ||
        this.notDragableShelvesMap.has("vertical")
      ) {
        return { result: false, message: "Already has locker " };
      }
    }
    if (shelfType === "panel_l") {
      if (this.placeIdx === 0 && this.side === "left") {
        return { result: true, message: "" };
      }
      if (
        this.isLastFilledPlaceInSide &&
        this.side === "right" &&
        !this.rotated
      ) {
        return { result: true, message: "" };
      }
      if (
        this.isLastFilledPlaceInSide &&
        this.side === "left" &&
        this.rotated
      ) {
        return { result: true, message: "" };
      } else {
        return { result: false, message: "" };
      }
    }
    if (shelfType === "panel_r") {
      if (this.placeIdx === 0 && this.side === "right") {
        return { result: true, message: "" };
      }
      if (
        this.isLastFilledPlaceInSide &&
        this.side === "left" &&
        !this.rotated
      ) {
        return { result: true, message: "" };
      }
      if (
        this.isLastFilledPlaceInSide &&
        this.side === "right" &&
        this.rotated
      ) {
        return { result: true, message: "" };
      } else {
        return { result: false, message: "" };
      }
    }
    // return { result: this.getAvailableHeight() >= shelfComponent.height, message: '' }; //todo: включить как будут передаваться высоты полок
    return { result: true, message: "" };
  }

  public addShelfFromComponent(
    shelfComponent: ShelfComponent,
    resource: GLTF
  ): Shelf {
    const shelf = new Shelf(shelfComponent, resource);
    const { uuid } = shelf;
    const { uuid: parentUuid } = this;
    if (shelf.type === "top") {
      const mesh = shelf.tempGetMainMesh();
      if (mesh) {
        shelf.object.position.y += this.translateRealHeightToViewHeight(
          -1005 + this.height + 400
        ); // TODO: костыль
        // mesh.rotateY(Math.PI);
      }
      const bbox = new THREE.Box3();
      bbox.setFromObject(shelf.object);
      const height = this.translateViewHeightToRealHeight(
        Math.abs(bbox.min.y - bbox.max.y)
      );
      if (this.carEntity.car.params.h <= height + this.height) {
        throw Error("Can not fit shelf to rack.");
      }
      const verticalMainLine = createLine({
        lineWidth: (this._height + 177) / Default.scale,
        direction: "vertical",
        label: `${this._height + 177}`,
        side: "left",
      });
      verticalMainLine.position.x += -0.4 - this._width / Default.scale / 2;
      verticalMainLine.position.z += this._depth / Default.scale / 2;
      this.view.removeEntityByName(`verticalMainLine`);
      this.view.addEntity({
        name: `verticalMainLine`,
        object: verticalMainLine,
        type: "staff",
      });

      if (this.notDragableShelvesMap.has(shelf.type)) {
        throw Error("Shelf of this type already added.");
      } else {
        this.notDragableShelvesMap.set(shelf.type, shelf);
        this.shelves.push(shelf);
      }

      const deleteButton = createButton("remove", { uuid, parentUuid });
      deleteButton.position
        .copy(shelf.object.position.clone())
        .add(
          new THREE.Vector3(
            this._width / 2 / Default.scale + 0.2,
            600 / Default.scale,
            0
          )
        );
      this.view.addEntity({
        name: `delete_${shelf.name}`,
        type: "button",
        object: deleteButton,
      });
      this.view.addEntity({
        name: shelf.name,
        type: "shelf",
        object: shelf.object,
      });
      this.updateButtonPositions();
      this.updateLines();
      shelf.isPlaced = true;
      return shelf;
    } else if (shelf.type === "locker") {
      const mesh = shelf.tempGetMainMesh();
      if (mesh) {
        const bbox = new THREE.Box3();
        bbox.setFromObject(shelf.object);
        const height = this.translateViewHeightToRealHeight(
          Math.abs(bbox.min.y - bbox.max.y)
        ); //TODO: костыль
        if (height) shelf.tempSetterForHeight(height);
        shelf.object.position.y += 0.04; // TODO: костыль
        shelf.position = 40;
        shelf.isPlaced = true;
        // mesh.rotateY(Math.PI);
      }
    } else if (shelf.type === "vertical") {
      const mesh = shelf.tempGetMainMesh();
      if (mesh) {
        const bbox = new THREE.Box3();
        bbox.setFromObject(shelf.object);
        const height = this.translateViewHeightToRealHeight(
          Math.abs(bbox.min.y - bbox.max.y)
        ); //TODO: костыль
        if (height) shelf.tempSetterForHeight(height);

        // shelf.object.position.y +=
        //     this.translateRealHeightToViewHeight(this._height) - shelf.height / Default.height; // TODO: костыль
        mesh.rotateY(Math.PI);
        shelf.position = 0;
        shelf.isPlaced = true;
      }
    } else if (shelf.type === "cover") {
      const mesh = shelf.tempGetMainMesh();
      if (mesh) {
        const bbox = new THREE.Box3();
        bbox.setFromObject(shelf.object);

        const height = this.translateViewHeightToRealHeight(
          Math.abs(bbox.min.y - bbox.max.y) * 3
        ); //TODO: костыль

        if (height) shelf.tempSetterForHeight(height);

        mesh.position.y +=
          (this.translateRealHeightToViewHeight(height) * 2) / 3; //TODO: костыль паддинг внизу полки
        mesh.rotateY(Math.PI);
      }
    } else if (shelf.type.startsWith("panel")) {
      const mesh = shelf.tempGetMainMesh();
      if (mesh) {
        const bbox = new THREE.Box3();
        bbox.setFromObject(shelf.object);
        const height = this.translateViewHeightToRealHeight(
          Math.abs(bbox.min.x - bbox.max.x)
        ); //TODO: костыль
        if (height) shelf.tempSetterForHeight(height);
        if (shelf.type === "panel_l") {
          shelf.object.position.x += +486 / 1000 / 2 - this.width / 1000 / 2;
        } else {
          shelf.object.position.x += -486 / 1000 / 2 + this.width / 1000 / 2;
        }
        // shelf.object.position.y +=
        //     this.translateRealHeightToViewHeight(this._height) - shelf.height / Default.height; // TODO: костыль
        // mesh.rotateY(Math.PI);
        shelf.position = 0;
        shelf.isPlaced = true;
      }
    } else {
      const bbox = new THREE.Box3();
      bbox.setFromObject(shelf.object);
      // console.log(shelf.object.children);
      const height = this.translateViewHeightToRealHeight(
        Math.abs(bbox.min.y - bbox.max.y)
      ); //TODO: костыль
      if (height) shelf.tempSetterForHeight(height);
    }
    if (isNotDragableShelf(shelf.type)) {
      if (this.notDragableShelvesMap.has(shelf.type)) {
        throw Error("Shelf of this type already added.");
      } else {
        this.notDragableShelvesMap.set(shelf.type, shelf);
        this.shelves.push(shelf);
      }
    } else {
      this.shelves.push(shelf);
    }
    const deleteButton = createButton("remove", { uuid, parentUuid });
    this.view.addEntity({
      name: `delete_${shelf.name}`,
      type: "button",
      object: deleteButton,
    });
    this.view.addEntity({
      name: shelf.name,
      type: "shelf",
      object: shelf.object,
    });
    deleteButton.position
      .copy(shelf.object.position.clone())
      .add(new THREE.Vector3(this._width / 2 / Default.scale + 0.2, 0, 0));
    this.updateLines();
    return shelf;
  }
  public getAvailableHeight() {
    return (
      this.height -
      this.shelves
        .map((shelf) => shelf.height)
        .reduce((sum, height) => height + sum, 0)
    );
  }

  public getAvailableHeightFromBottomFor(name: string) {
    return Math.min(
      ...this.shelves
        .filter((otherShelf) => otherShelf.uuid !== name)
        .map((otherShelf) => otherShelf.position ?? this.height),
      this.height
    );
  }

  public getAvailableHeightFromTopFor(name: string) {
    return Math.max(
      ...this.shelves
        .filter((otherShelf) => otherShelf.uuid !== name)
        .map(
          (otherShelf) => (otherShelf.position ?? 0) + (otherShelf.height ?? 0)
        ),
      0
    );
  }

  public setShelfToPosition(shelf: Shelf, position: number) {
    if (!this.shelves.find((s) => s.name === shelf.name)) return;

    if (shelf.type === "top" || shelf.type === "vertical") {
      shelf.isPlaced = true;
      shelf.object.position.y =
        shelf.type === "top"
          ? this.translateRealHeightToViewHeight(this.height)
          : 0; // TODO: разобраться
      const deleteButton = this.view.getEntityByName(`delete_${shelf.name}`);
      // const box = new THREE.BoxHelper(shelf.object);
      // this.view.getGroup.parent?.add(box);
      if (deleteButton)
        deleteButton.object.position
          .copy(shelf.object.position.clone())
          .add(new THREE.Vector3(this._width / 2 / Default.scale + 0.2, 0, 0));
      this.updateButtonPositions();
      return;
    }

    const step = 19.5555;
    const positions: Array<number> = [];

    const isPlaceBoundedByTopShelf = this.shelves.some(
      (shelf) => shelf.type === "top"
    );

    const maxPosition = isPlaceBoundedByTopShelf
      ? this.height - shelf.height
      : Math.min(
          this.height -
            (shelf.type === "preset"
              ? shelf.height
              : shelf.type === "boxxser" || shelf.type === "carrylite"
              ? 10
              : shelf.height - step),
          this.carEntity.car.params.h - shelf.height
        );

    for (
      let i = this.hasArc()
        ? (Math.floor(this.carEntity?.arcHeight() / 68) + 1) * 68 ?? 68
        : 68;
      i < maxPosition;
      i += step
    ) {
      positions.push(i);
    }
    const closest = positions.reduce((prev, curr) =>
      Math.abs(curr - position) < Math.abs(prev - position) ? curr : prev
    );
    if (this.isShelfCanPlaced(closest, shelf)) {
      shelf.isPlaced = true;
      shelf.position = closest;
      // shelf.object.children.forEach((c) => (c.position.y = place.translateRealHeightToViewHeight(closest)));
      // shelf.object.position.y = this.translateRealHeightToViewHeight(closest);
      // console.log(closest, shelf.object.position.y);
      shelf.object.position.y = closest / 1000 - (shelf.botBoltPosition ?? 0); // TODO: Костыль
      const deleteButton = this.view.getEntityByName(`delete_${shelf.name}`);
      if (deleteButton)
        deleteButton.object.position
          .copy(shelf.object.position.clone())
          .add(new THREE.Vector3(this._width / 2 / Default.scale + 0.2, 0, 0));
      this.updateLines();
    }
    this.updateButtonPositions();
  }

  public get name(): string {
    return `${this._glbUrl}_${this._side}_${this.placeIdx}`;
  }
  public get url(): string {
    return this._glbUrl;
  }
  public get presentName(): string {
    return `${this._side}_${this.placeIdx}`;
  }
  public get glbUrl(): string {
    return this._glbUrl;
  }
  public get side(): Side {
    return this._side;
  }
  public get height(): number {
    return this._height;
  }
  public get width(): number {
    return this._width;
  }
  private updateButtonPositions(): void {
    let topHeight = 0;
    const buttons = this.view.getEntitiesByType("button");
    const label = this.view.getEntityByName("label");
    const spriteSize = Default.spriteSize;
    const position =
      this.view.getEntityByName("rack")?.object.position ??
      this.view.getEntityByName("place")?.object.position;
    const topBox = this.notDragableShelvesMap.get("top");
    if (topBox) {
      //TODO: костыль
      const bbox = new THREE.Box3().setFromObject(topBox.object);
      topHeight = bbox.max.y - bbox.min.y;
    }
    if (position) {
      buttons.forEach((b) => {
        if (b.name.includes("delete")) return;
        b.object.position.copy(position);
        b.object.position.y = this._height / Default.scale + spriteSize;
        b.object.position.y += topHeight;
        b.object.visible = true;
        if (this.state === "rack") {
          if (b.name === "choose") b.object.position.x += 0.0;
          if (b.name === "close") b.object.position.x -= 0.2;
          if (b.name === "edit") b.object.position.x = 0.2;
          if (b.name === "rotate") b.object.position.x = 0.4;
        }
        if (this.state === "place") {
          if (b.name === "choose") b.object.position.x += 0.1;
          if (b.name === "close") b.object.position.x -= 0.1;
        }
      });
      if (label) {
        label.object.position.copy(position);
        label.object.position.y =
          this._height / Default.scale + 2.5 * spriteSize;
        label.object.position.y += topHeight;
        if (this.side === "right") label.object.scale.set(-1, 1, 1);
      }
    }
  }

  private setState(state: State): void {
    this.state = state;
    const rack = this.view.getEntityByName("rack");
    const place = this.view.getEntityByName("place");
    if (rack) rack.object.visible = state === "rack";
    if (place) place.object.visible = state === "place";
  }

  public fillPlace(rackFeatures: Array<THREE.Mesh>): void {
    this.setState("rack");
    const rack = new THREE.Group();
    rackFeatures.forEach((rackMesh) => {
      if (isRackFeatureHuge(rackMesh)) setMaterialForHugeRackFeature(rackMesh);
      rack.add(rackMesh);
      rackMesh.visible = true;
    });
    this.view.addEntity({ name: "rack", object: rack, type: "mesh" });
    const mesh = new THREE.Mesh(
      new THREE.PlaneGeometry(30, 30),
      new THREE.MeshBasicMaterial({ side: THREE.DoubleSide })
    );
    mesh.visible = false;
    mesh.position.z += this._depth / Default.scale / 2;
    this.view.addEntity({ name: "plane", object: mesh, type: "staff" });

    const verticalMainLine = createLine({
      lineWidth: this._height / Default.scale,
      direction: "vertical",
      label: `${this._height}`,
      side: "left",
    });
    verticalMainLine.position.x += -0.4 - this._width / Default.scale / 2;
    verticalMainLine.position.z += this._depth / Default.scale / 2;
    this.view.addEntity({
      name: `verticalMainLine`,
      object: verticalMainLine,
      type: "staff",
    });
    const horisontalLine = createLine({
      lineWidth: this._width / Default.scale,
      direction: "horisontal",
      label: `${this._width}`,
      side: "left",
    });
    horisontalLine.position.z += this._depth / Default.scale / 2;
    horisontalLine.position.x += this._width / 2 / Default.scale;
    horisontalLine.position.y -= 0.15;
    this.view.addEntity({
      name: `horisontalLine`,
      object: horisontalLine,
      type: "staff",
    });

    this.updateButtonPositions();
  }
  public earse(): void {
    this.setState("place");
    this.carEntity.earseSide(this.side);
    this.view.removeEntityByName("rack");
    this.view
      .getEntitiesByType("shelf")
      .forEach((e) => this.removeShelf(e.name));
    this.view
      .getEntitiesByType("staff")
      .forEach((e) => this.view.removeEntityByName(e.name));

    this.shelves.length = 0;
    this._height = Default.height;
    this.alreadyFit = false;
    this.updateButtonPositions();
    // this.updateShelvesPositions();
    this.updateView();
  }
  public get withRack(): boolean {
    return this.state === "rack";
  }
  public remove(): void {
    Place.globalIdx[this.side] =
      Place.globalIdx[this.side] > 0 ? Place.globalIdx[this.side] - 1 : 0;
    this.view
      .getEntitiesNames()
      .forEach((n) => this.view.removeEntityByName(n));
    this.state = null;
  }
  public get isExist(): boolean {
    return this.state !== null;
  }

  public fitToCar(refit = false): void {
    // return;
    switch (this.state) {
      case "rack": {
        if (this.alreadyFit && !refit) return;
        if (
          this.carEntity.isArcPlaceAvailableForRack(this._width, this._side)
        ) {
          const availableWidthBeforeArc =
            this.carEntity.availableArcPlace(this._side) -
            this.carEntity.arcWidth(this._side);
          const filledWidth =
            this._width < this.carEntity.availableArcPlace(this._side)
              ? availableWidthBeforeArc >= this._width
                ? this._width
                : this.carEntity.barrierWidth(this._side) -
                  this.carEntity.filledWidthBySide(this._side)
              : this.carEntity.availableArcPlace(this._side) +
                (this._width - this.carEntity.availableArcPlace(this._side));
          this.carEntity.fillSide(this._side, filledWidth);
        } else {
          this.carEntity.fillSide(this._side, this._width);
        }
        const position = this.carEntity.startCoordBySide(this._side);
        const pos = new THREE.Vector3().copy(position);
        position.z +=
          this._side === "left"
            ? (0.5 * this._depth) / Default.scale
            : -(0.5 * this._depth) / Default.scale;
        position.x +=
          this.carEntity.filledWidthBySide(this._side) / Default.scale -
          (0.5 * this._width) / Default.scale;
        this.view.setPosition(position);
        if (
          position.x - pos.x - (this._width * 0.5) / Default.scale >
          (this.carEntity.car.params.r - this.carEntity.car.params.k) /
            Default.scale
        ) {
          if (this.carEntity.car.params.double_door && this._side === "left") {
            this._door = true;
          }
          if (this._side === "right") {
            this._door = true;
          }
        }
        this.alreadyFit = true;
        // const bbox = new THREE.BoxHelper(this.view.getEntityByName('rack')?.object);
        // this.view.getGroup.parent?.add(bbox);
        break;
      }
      case "place": {
        const position = this.carEntity.startCoordBySide(this._side);
        position.z +=
          ((this._side === "left" ? 1 : -1) * (0.5 * this._depth)) /
          Default.scale;
        position.x +=
          ((this._side === "left" ? 1 : 1) *
            this.carEntity.filledWidthBySide(this._side)) /
            Default.scale +
          ((this._side === "left" ? 1 : 1) * (0.5 * Default.width)) /
            Default.scale;
        this.view.setPosition(position);
        break;
      }
    }
    // this.updateButtonPositions();
  }
  public updateView(mode: Mode | null = null): void {
    if (this.state === null) return;
    const state = this.state;
    this.viewMode = mode ?? this.viewMode;
    const place = this.view.getEntityByName("place");
    const rack = this.view.getEntityByName("rack");
    const buttons = this.view.getEntitiesByType("button");
    const label = this.view.getEntityByName("label");
    const measurments = this.view
      .getEntitiesByType("staff")
      .filter((e) => e.name !== "plane");
    this.updateButtonPositions();
    // this.updateShelvesPositions();
    if (mode === "main") {
      buttons.forEach((b) => (b.object.visible = false));
      measurments.forEach((b) => (b.object.visible = false));
      if (place) place.object.visible = false;
      if (rack) {
        rack.object.visible = true;
        this.shelves.forEach((shelf) => {
          shelf.makeInactive();
        });
      }
      if (label) label.object.visible = false;
    } else if (mode === "interior") {
      buttons.forEach((b) => {
        b.object.visible =
          ActionsPerState[state].includes(b.name) && b.name !== "rotate";
      });
      measurments.forEach((b) => (b.object.visible = false));
      if (place) place.object.visible = this.state === "place";
      if (rack) rack.object.visible = this.state === "rack";
      if (label) label.object.visible = true;
    } else if (mode === "screenshot") {
      measurments.forEach((b) => (b.object.visible = true));
      if (label) label.object.visible = false;
      if (place) {
        place.object.visible = this.state === "place";
        this.shelves.forEach((shelf) => {
          shelf.makeInactive();
        });
      }
      if (rack) rack.object.visible = this.state === "rack";
      buttons.forEach((b) => (b.object.visible = false));
    } else {
      if (label) label.object.visible = false;
      measurments.forEach((b) => (b.object.visible = this.isActive));
      if (place) place.object.visible = this.state === "place";
      if (rack) rack.object.visible = this.state === "rack";
      buttons.forEach((b) => {
        if (b.name.split("_")[0] === "rotate") {
          b.object.visible = mode === "storage" && this._door;
        } else {
          b.object.visible = ActionsPerState[state].includes(
            b.name.split("_")[0]
          );
        }
      });
    }
  }
}
