import React, { useState } from "react";
import {ErrorContext} from "./ErrorContext";

interface IErrorProviderProps{
    children: any
}

const ErrorPopupProvider = ({children}: IErrorProviderProps) => {
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [openError, setOpenError] = useState<boolean>(false);
    
    return(
        <ErrorContext.Provider value = {{
            errorMessage,
            setErrorMessage,
            openError,
            setOpenError
        }}>
            {children}
        </ErrorContext.Provider>
    )
}

export default ErrorPopupProvider;