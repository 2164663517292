import {
    Component,
    ShelfComponent,
    RackComponent,
    InitialComponent,
} from './component';
import { Side } from './general';
import { TotalSumProps } from './preset';
import { Mode } from './scene';

export type Constraints = {
    maxWidth: number;
    minWidth: number;
    maxHeight: number;
    invertMinWidth: number | null;
};

export type ImageEntity = { src: string; label: string; name: string };

export type App3dState = {
    show: boolean;
    presetId: number;
    rackComponents: Record<string, RackComponent | InitialComponent>;
    shelfComponents: Record<string, Array<ShelfComponent>>;
    mode: Mode;
    constraints: Constraints;
    activePlaceUuid: string | null;
    loading: boolean;
    error: string | null;
    presetData: Component[];
    isLastPlaceAvailable: Record<Side, boolean>;
    presetIsLoading: boolean;
    previews: Array<ImageEntity>;
    availableRacksForSide: Record<Side, number>;
    total_sum: TotalSumProps;
    totalWeight: number;
};

export const ApiPath = {
    components: process.env.REACT_APP_HTTP_API_URL + '/api/glb/',
    assets: '/',
    car: '/Cargo.glb',
    hdr: '/mud_road_puresky_1k.hdr',
};
