import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './components/AuthProvider/AuthProvider';
import PopupProvider from './components/PopupProvider/PopupProvider';
import HelpProvider from './components/HelpProvider/helpContextProvider';
import ErrorPopupProvider from './components/ErrorPopup/ErrorContextProvider';
import { Provider } from 'react-redux';
import store from './services/store';
import GlobalStyles from '@mui/material/GlobalStyles';
import { ThemeProvider } from '@mui/material';
import { defaultTheme } from './themes';
import './i18nextinit';
import SuccessPopupProvider from './components/SuccessPopup/SuccessContextProvider';
import App3dContext from './app3d/context';
import { App3d } from './app3d/App3d';
ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <ThemeProvider theme={defaultTheme}>
                <GlobalStyles
                    styles={{
                        body: {
                            backgroundColor: '#FCFDFD',
                            color: '#00365F',
                            fontFamily: 'Roboto',
                            fontSize: '14px',
                        },
                        'html, body, #root': {
                            height: '100%',
                            padding: 0,
                            margin: 0,
                        },
                        button: {
                            fontFamily: 'Roboto',
                            fontSize: '100%',
                        },
                    }}
                />
                <PopupProvider>
                    <AuthProvider>
                        <HelpProvider>
                            <ErrorPopupProvider>
                                <SuccessPopupProvider>
                                    <App3dContext.Provider value={{ app: new App3d() }}>
                                        <App />
                                    </App3dContext.Provider>
                                </SuccessPopupProvider>
                            </ErrorPopupProvider>
                        </HelpProvider>
                    </AuthProvider>
                </PopupProvider>
            </ThemeProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
