import { httpClient } from '../httpClient/httpClient';

export const accessoriesService = {
  getListAccessories() {
    return httpClient.get('/accessories');
  },
  getAccessories(id: string) {
    return httpClient.get('/accessories' + id);
  },
  createAccessories(params: FormData) {
    return httpClient.post('/accessories/create', params);
  },
  updateAccessories(params: FormData) {
    return httpClient.post('/accessories/update', params);
  },
  deleteAccessories(id: FormData) {
    return httpClient.post('/accessories/delete', {id: id});
  },
};
