import React from 'react';
import UnitedKingdom from '../../icons/UnitedKingdomIcon.svg';
import Denmark from '../../icons/DenmarkIcon.svg';
import { Box, MenuItem, Select, styled } from '@mui/material';

import i18next from 'i18next';
import { useHelp } from '../../hooks/useHelp';

const SwitchLanguage = () => {
  const { language, setLanguage } = useHelp();
  const languageItems = [
    { key: 'en', flag: UnitedKingdom, label: 'English' },
    { key: 'dk', flag: Denmark, label: 'Danish' },
  ];
  const handleLanguage = (item: string) => {
    setLanguage(item);
    i18next.changeLanguage(item);
  };
  return (
    <>
      <StyledSelect value={language}>
        {languageItems.map((item, index) => (
          <StyledSelectItem
            key={index}
            role="listitem"
            value={item.key}
            onClick={() => handleLanguage(item.key)}
          >
            <Box
              component="div"
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap="8px"
            >
              <Box component="img" alt="" src={item.flag} />
              {item.label}
            </Box>
          </StyledSelectItem>
        ))}
      </StyledSelect>
    </>
  );
};

const StyledSelect = styled(Select)(({ theme }) => ({
  fontStyle: 'normal',

  fontWeight: '500',
  fontSize: '16px',
  lineHeight: '20px',
  margin: '0px 20px',
  color: '#E9EFF1',
  cursor: 'pointer',
  gap: '4px',
  [theme.breakpoints.down('md')]: {
    margin: '0px 15px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    display: 'none',
  },
  '& .MuiSvgIcon-root': {
    color: '#FCFDFD',
  },
}));

const StyledSelectItem = styled(MenuItem)(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '16px',
  lineHeight: '20px',
  margin: '0px 20px',
  color: theme.palette.primary.main,
  cursor: 'pointer',
  [theme.breakpoints.down('md')]: {
    margin: '0px 15px',
  },
}));

export default SwitchLanguage;
