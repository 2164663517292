import React from "react";
import { Route, Switch } from "react-router-dom";
import { ListUsers } from "./listUsers";


const Users = () => {

  return (
    <Switch>
      <Route exact path='/admin/users' component = {ListUsers} />
    </Switch>
  )
}

export default Users;