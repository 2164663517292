import { Presets } from '../types/preset';

export default function getFilteredPresets(presets: Presets[], text: string) {
    const search = text.trim().toLowerCase();

    if (!search) {
        return presets;
    }

    return presets.filter((item) => {
        const code = item.code.toLowerCase();
        const name = item.name.toLowerCase();
        return code.indexOf(search) > -1 || name.indexOf(search) > -1;
    });
}
