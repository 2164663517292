import React, { useState } from "react";
import {HelpContext} from "./helpContext"
import {TDialogNames} from "../../types/general";

interface IHelpProviderProps{
    children: any;
}

const HelpProvider = ({children}: IHelpProviderProps ) => {
    const [currency, setCurrency] = useState<string>("dkk");
    const [language, setLanguage] = useState<string>("dk");
    const [brand, setBrand] = useState<string>('');
    const [model, setModel] = useState<string>('');
    const [packagesModel, setPackagesModel] = useState<string>('');
    const [packagesId, setPackagesId] = useState<string>('');
    const [weight, setWeight] = useState<string>('0');
    const [totalSum, setTotalSum] = useState<string>('0');
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [dialogName, setDialogName] = useState<TDialogNames>(null);

    return (
        <HelpContext.Provider value ={{
            currency,
            setCurrency,
            language,
            setLanguage,
            brand,
            setBrand,
            model,
            setModel,
            packagesModel,
            setPackagesModel,
            packagesId,
            setPackagesId,
            weight,
            setWeight,
            totalSum,
            setTotalSum,
            openDialog,
            setOpenDialog,
            dialogName,
            setDialogName,
        }}>
            {children}
        </HelpContext.Provider>
    )
}

export default HelpProvider;
