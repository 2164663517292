import React, { FC, useState } from 'react';
import { Redirect } from 'react-router-dom';
import useAppDispatch from '../../hooks/useAppDispatch';
import { authService } from '../../services/api/auth';
import { httpClient } from '../../services/httpClient/httpClient';
import { getMe, setError } from '../../services/store/userSlice';
import { AuthContext, LoginState } from './AuthContext';

interface IAuthProviderProps {
    children: React.ReactChild;
}

export type loginRequestType = {
    access_token: string;
    user: UserType | null;
};

export type UserType = {
    created_at: string;
    email: string;
    email_verified_at: string;
    id: string;
    name: string;
    updated_at: string;
    is_admin: boolean;
};

const AuthProvider: FC<IAuthProviderProps> = ({ children }) => {
    const [authenticated, setAuthenticated] = useState<boolean>(false);
    const [token, setToken] = useState<string>();
    const [loginState, setLoginState] = useState<LoginState>('wait');
    const dispatch = useAppDispatch();

    return (
        <AuthContext.Provider
            value={{
                authenticated,
                setAuthenticated,
                token,
                loginState,
                setToken(token: string) {
                    setToken(token);
                    httpClient.setToken(token);
                },
                setLoginState,
                async login(email: string, password: string) {
                    try {
                        dispatch(setError(null));
                        const response = await authService.login({
                            email,
                            password,
                        });
                        if (response.status === 200) {
                            let data: loginRequestType = response.data;
                            localStorage.setItem('token', data.access_token);
                            httpClient.setToken(data.access_token);
                            setAuthenticated(true);
                            dispatch(getMe());
                        }
                        setLoginState('logged');
                    } catch (err: any) {
                        if (err.response.status === 400) {
                            dispatch(setError(err.response.data.message));
                        }
                        if (err.response.status === 422) {
                            dispatch(setError(err.response.data.email[0]));
                        }
                        setLoginState('not logged');
                        <>
                            <Redirect to="/login" />
                        </>;
                    }
                },
                logout() {
                    authService.logout();
                    httpClient.setToken(null);
                    localStorage.clear();
                    setAuthenticated(false);
                    setLoginState('not logged');
                },
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export { AuthProvider };
