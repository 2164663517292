import * as THREE from "three";

import { Default, CarMinMaxPerTarget } from "../../consts/scene";

import { Constraints } from "../../types/app3d";
import { Car } from "./CarStorage";

export type CarEntityProps = {
  mesh: THREE.Mesh;
  car: Car;
};
function getMinOrZero(name: string) {
  let param = CarMinMaxPerTarget.get(name);
  if (param) return param.min;
  return 0;
}
type Sides = "left" | "right";

type FilledLen = {
  left: Array<number>;
  right: Array<number>;
};

export default class CarEntity {
  private readonly mesh: THREE.Mesh;
  public readonly car: Car;
  private filledWidth: FilledLen = { left: [], right: [] };

  constructor({ mesh, car }: CarEntityProps) {
    this.mesh = mesh;
    this.car = car;
  }

  public get leftStartCoord(): THREE.Vector3 {
    let box = new THREE.Box3();
    box.setFromObject(this.mesh);
    const bDiff = this.car.params.b - getMinOrZero("B_C");
    // const eDiff = (this.car.params.e - getMinOrZero('E'))/2;
    let point = box.min;
    point.x = point.x - bDiff / Default.scale;
    point.z =
      -this.car.params.e / Default.scale / 2 -
      this.car.params.a / Default.scale;
    return point;
  }

  public get rightStartCoord(): THREE.Vector3 {
    return this.leftStartCoord
      .clone()
      .add(
        new THREE.Vector3(
          0,
          0,
          (this.car.params.a + this.car.params.e + this.car.params.d) /
            Default.scale
        )
      );
  }

  public get righEndCoord(): THREE.Vector3 {
    let box = new THREE.Box3();
    box.setFromObject(this.mesh);
    const bDiff = this.car.params.b - getMinOrZero("B_C");
    const eDiff = (this.car.params.e - getMinOrZero("E")) / 2;
    let point = box.min;
    point.x =
      point.x -
      bDiff / Default.scale +
      this.car.params.f / Default.scale +
      this.car.rArcSize;
    point.z =
      point.z - eDiff / Default.scale + this.car.params.e / Default.scale;
    // point.y = box.min.y;
    return box.min;
  }

  public startCoordBySide(side: Sides): THREE.Vector3 {
    return side === "left" ? this.leftStartCoord : this.rightStartCoord;
  }

  private widthBySide(side: Sides): number {
    // return side === 'left'
    //     ? this.car.params.r
    // : this.car.params.r - this.car.params.n;
    return this.car.params.r;
  }

  public filledWidthBySide(side: Sides): number {
    return this.filledWidth[side].reduce(
      (partialSum, current) => partialSum + current,
      0
    );
  }
  public get length(): number {
    return this.car.params.r;
  }
  public get width(): number {
    return this.car.params.a + this.car.params.d + this.car.params.e;
  }

  public fillSide(side: Sides, filledLen: number): void {
    this.filledWidth[side].push(filledLen);
  }
  public earseSide(side: Sides): void {
    this.filledWidth[side].pop();
  }

  private availableWidthBySide(side: Sides): number {
    return this.widthBySide(side) - this.filledWidthBySide(side);
  }

  public barrierWidth(side: Sides): number {
    return this.arcWidth(side) + this.widthBeforeBarrier(side);
  }
  public arcWidth(side: Sides): number {
    return this.car.params.a < 50
      ? 0
      : side === "left"
      ? this.car.lArcSize
      : this.car.rArcSize;
  }
  public arcHeight(): number {
    return this.car.arcHeight;
  }
  private widthBeforeBarrier(side: Sides): number {
    return this.arcWidth(side) === 0
      ? 0
      : side === "left"
      ? this.car.params.b
      : this.car.params.c;
  }

  public getConstraints(side: Sides): Constraints {
    let maxWidth: number;
    let minWidth: number;
    let invertMinWidth: number | null = 0;
    if (this.barrierWidth(side) > this.filledWidthBySide(side)) {
      maxWidth = this.barrierWidth(side);
      minWidth = this.widthBeforeBarrier(side) - this.filledWidthBySide(side);
      invertMinWidth = this.arcWidth(side);
    } else {
      minWidth = 0;
      maxWidth = this.availableWidthBySide(side);
    }
    return { maxWidth, minWidth, invertMinWidth, maxHeight: this.car.params.h };
  }
  private isBarrierWidthFilled(side: Sides): boolean {
    return this.barrierWidth(side) <= this.filledWidthBySide(side);
  }
  public canFitRackInSide(rackWidth: number, side: Sides): boolean {
    return this.availableWidthBySide(side) >= rackWidth;
  }

  private isWidthBeforeArcAvailable(side: Sides): boolean {
    return (
      this.filledWidthBySide(side) <
      (side === "left" ? this.car.params.b : this.car.params.c)
    );
  }
  private canFitRackBeforeArc(rackWidth: number, side: Sides): boolean {
    return (
      rackWidth <= (side === "left" ? this.car.params.b : this.car.params.c)
    );
  }
  public availableArcPlace(side: Sides): number {
    const width = this.barrierWidth(side) - this.filledWidthBySide(side);
    return width > 0 ? width : 0;
  }
  public isArcPlaceAvailableForRack(width: number, side: Sides): boolean {
    return (
      (!this.isBarrierWidthFilled(side) &&
        this.isWidthBeforeArcAvailable(side) &&
        !this.canFitRackBeforeArc(width, side) &&
        width >= this.arcWidth(side)) ||
      width < this.barrierWidth(side) - this.filledWidthBySide(side)
    );
  }
}
