import React, { useEffect, useState } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import StepPanel from '../../../components/StepPanel';
import { Box, styled, Button } from '@mui/material';
import AddIcon from '../../../icons/AddIcon.svg';
import PressetIcon from '../../../icons/PressetIcon.svg';
import Helper from '../../../components/HelperText';
import useAppDispatch from '../../../hooks/useAppDispatch';
import { getPresetsList, selectPresets, getPreset, setPreset } from '../../../services/store/presetSlice';
import useAppSelector from '../../../hooks/useAppSelector';
import { useTranslation } from 'react-i18next';
import { getPackagesParams } from '../../../services/store/packagieSlice';
import { loadPreset, loadCar, setShow } from '../../../services/store/app3dSlice';
import {setAccessoriesListTemp} from "../../../services/store/accessoriesSlice";

const EquipList = () => {
    const { packagesId } = useParams<{
        packagesId: string;
    }>();
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(setShow(false));
        return () => {
            dispatch(setShow(true));
        };
    }, [dispatch]);
    const { t } = useTranslation();
    const history = useHistory();
    const allPresets = useAppSelector(selectPresets);
    const [helpVisible, setHelpVisible] = useState<boolean>(true);

    const handleSelectPreset = async (id: string) => {
        const res = await dispatch(getPreset(id));
        // @ts-ignore
        const preset = res?.payload?.data;
        if (preset) {
            dispatch(loadPreset(preset));
            dispatch(setPreset(preset));
            dispatch(setAccessoriesListTemp([]));
            history.push(
              generatePath('/configurator/equip/:id', {
                  id: preset.id,
              })
            );
        }
    };

    const handleCreateNewPreset = () => {
        dispatch(loadPreset(null));
        dispatch(setPreset(null));
        dispatch(setAccessoriesListTemp([]));
        history.push(generatePath('/configurator/equip-create/:packagesId', { packagesId }));
    };

    useEffect(() => {
        dispatch(getPresetsList(packagesId));
        dispatch(getPackagesParams(packagesId));
    }, [dispatch, packagesId]);

    const { packagesParams } = useAppSelector((state) => state.packagies);

    useEffect(() => {
        dispatch(loadCar(packagesParams));
        dispatch(loadPreset(null));
        dispatch(setAccessoriesListTemp([]));
    }, [packagesParams, dispatch]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setHelpVisible(false);
        }, 10000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <div>
            <StepPanel step={1} label={t('Equip.backButton')} />
            <Box
                component="div"
                sx={{
                    maxHeight: '80vh',
                }}
                style={{ overflow: 'hidden', overflowY: 'scroll' }}
            >
                {helpVisible ? <Helper text={t('Equip.equipHelpText')} /> : <></>}

                <EquipContainer>
                    <CustomEquip onClick={() => handleCreateNewPreset()}>
                        <StyledTitle>{t('Equip.equipCreateButton.equipCreateButtonTitle')}</StyledTitle>
                        <StyledSubTitle>{t('Equip.equipCreateButton.equipCreateButtonText')}</StyledSubTitle>
                        <img src={AddIcon} alt="" />
                    </CustomEquip>
                    {allPresets.length > 0 &&
                        allPresets.map((item, index) => (
                            <StyledEquipContainer key={index} onClick={() => handleSelectPreset(item.id)}>
                                <StyledTitle>{item.name}</StyledTitle>
                                <StyledEquipItem>
                                    <Box component="div" display="flex" flexDirection="column" alignItems="center" 
                                    sx =   {{
                                        height: {
                                            xl: '140px',
                                            lg: '140px',
                                            md: '94px',
                                            sm: '94px',
                                        },
                                        width: {
                                            xl: '360px',
                                            lg: '360px',
                                            md: '140px',
                                            sm: '140px',
                                        },
                                        paddingLeft: '15px'
                                    }}>
                                        <Box
                                            component="img"
                                            alt=""
                                            src={item.folder_image ?? PressetIcon}
                                            width = '360px'
                                        />
                                    </Box>
                                    <Box
                                        component="div"
                                        display="flex"
                                        mt="60px"
                                        flexDirection="column"
                                        alignItems="flex-end"
                                        sx={{
                                            width: {
                                                xl: '398px',
                                                lg: '383px',
                                                md: '288px',
                                                sm: '348px',
                                            },
                                        }}
                                    >
                                        <StyledButton>{t('Equip.chooseButton')}</StyledButton>
                                    </Box>
                                </StyledEquipItem>
                            </StyledEquipContainer>
                        ))}
                </EquipContainer>
            </Box>
        </div>
    );
};

export default EquipList;

const EquipContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '30px',
    padding: '32px 135px 83px 121px',
    [theme.breakpoints.down('xl')]: {
        padding: '32px 121px 27px 121px',
        gap: '48px 24px',
    },
    [theme.breakpoints.down('lg')]: {
        padding: '16px 56px 24px 56px',
        gap: '24px',
    },
    [theme.breakpoints.down('md')]: {
        padding: '16px 24px 24px 24px',
    },
}));

const CustomEquip = styled(Button)(({ theme }) => ({
    background: '#FFFFFF',
    display: 'flex',
    flexDirection: 'column',
    border: '4px dashed #E5EBEF',
    borderRadius: '14px',
    width: '398px',
    maxHeight: '282px',
    padding: '16px 16px 44px 16px',
    textTransform: 'none',
    [theme.breakpoints.down('xl')]: {
        width: '383px',
    },
    [theme.breakpoints.down('lg')]: {
        width: '288px',
        maxHeight: '218px',
        padding: '16px 16px 38px 16px',
    },
    [theme.breakpoints.down('md')]: {
        width: '348px',
    },
}));

const StyledEquipContainer = styled(Button)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    background: '#FFFFFF',
    alignContent: 'space-between',
    alignItems: 'flex-start',
    border: '1px solid rgba(0, 54, 95, 0.01)',
    boxSizing: 'border-box',
    boxShadow: '1px 2px 7px rgba(0, 54, 95, 0.15)',
    borderRadius: '14px',
    padding: '0px',
    minWidth: '398px',
    maxHeight: '282px',
    [theme.breakpoints.down('xl')]: {
        minWidth: '383px',
    },
    [theme.breakpoints.down('lg')]: {
        minWidth: '288px',
        maxHeight: '218px',
    },
    [theme.breakpoints.down('md')]: {
        maxWidth: '348px',
    },
}));

const StyledEquipItem = styled(Box)(({ theme }) => ({
    display: 'flex',
    cursor: 'pointer',
    flexDirection: 'column',
}));

const StyledTitle = styled(Box)(({ theme }) => ({
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '28px',
    textAlign: 'left',
    margin: '16px 0px 8px 16px',
    [theme.breakpoints.down('lg')]: {
        fontSize: '28px',
    },
}));

const StyledSubTitle = styled(Box)(({ theme }) => ({
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '20px',
    opacity: '0.6',
    letterSpacing: '0.5px',
    [theme.breakpoints.down('lg')]: {
        fontSize: '14px',
        letterSpacing: '0.25px',
    },
}));

const StyledButton = styled(Box)(({ theme }) => ({
    position: 'static',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    right: 0,
    padding: '8px 20px',
    borderRadius: '14px 0px',
    background: theme.palette.primary.main,
    fontStyle: 'normal',
    lineHeight: '16px',
    fontSize: '14px',
    color: '#FCFDFD',
}));
