import React, { useEffect, useMemo } from "react";
import { Box, IconButton, List, styled } from "@mui/material";
import UnitIcon from "../../../icons/TestUnitIcon.svg";
import TestKitIcon from "../../../icons/AccessoriesIcon.svg";
import useAppSelector from "../../../hooks/useAppSelector";
import useAppDispatch from "../../../hooks/useAppDispatch";
import Plus from "../../../icons/PlusIcon.svg";
import { useHelp } from "../../../hooks/useHelp";
import { currentName } from "../../../utils/currentName";
import svgAddRack from "../../../icons/addRack.svg";
import svgAddRackPlus from "../../../icons/addRackPlus.svg";

import {
  showAddRackButton,
  setMode,
  addResource,
  loadResources,
  addComponent,
  validateAddComponent,
  setError,
} from "../../../services/store/app3dSlice";
import {
  FilledComponent,
  isRackComponent,
  RackComponent,
  ShelfComponent,
} from "../../../types/component";
import { useError } from "../../../hooks/useError";
import ErrorPopup from "../../../components/ErrorPopup/Index";
import { getShelfType } from "../../../components/Scene/helpers";

import { isNotDragableShelf } from "../../../types/scene";
import { addShelfToBlackList } from "../../../services/store/componentsSlice";
import { useTranslation } from "react-i18next";

const EditEquip = ({ tab }: { tab: number }) => {
  const dispatch = useAppDispatch();
  const { language } = useHelp();
  const { setErrorMessage, setOpenError } = useError();
  const { activePlaceUuid, error, mode, rackComponents } = useAppSelector(
    (state) => state.app3d
  );
  const { t } = useTranslation();
  const racksOriginal = useAppSelector((state) => state.components.racksList);
  const racks = useMemo(() => {
    const filteredRacks: RackComponent[] = [];
    racksOriginal.forEach((k) => {
      if (filteredRacks.findIndex((d) => d.id === k.id) === -1) {
        filteredRacks.push(k);
      }
    });
    return filteredRacks;
  }, [racksOriginal]);

  const showAddButton = useAppSelector(showAddRackButton);
  const blackListShelves = useAppSelector(
    (state) => state.components.blackListShelves
  );
  const shelfs = useAppSelector((state) => state.components.shelfList).filter(
    (s) => !blackListShelves.includes(getShelfType(s))
  );
  // const [filteredShelves, setFilteredShelves] = useState<ShelfComponent[]>([]);
  // useEffect(() => {
  //     setFilteredShelves(
  //         shelfs.filter((s) => !blackListShelves.includes(getShelfType(s)))
  //     );
  // }, [blackListShelves, shelfs]);

  const handleFillPlace = async (component: FilledComponent) => {
    if (activePlaceUuid && isRackComponent(rackComponents[activePlaceUuid])) {
      setErrorMessage("Rack is already selected, to replace first remove");
      setOpenError(true);
      return;
    }
    dispatch(addResource(component));
    await dispatch(loadResources());
    dispatch(addComponent({ component }));
    await dispatch(setMode({ mode }));
  };

  const handleAddShelf = async (component: ShelfComponent) => {
    if (!activePlaceUuid) return;
    dispatch(addResource(component));
    await dispatch(loadResources());
    dispatch(addComponent({ component, parentUuid: activePlaceUuid }));
    dispatch(validateAddComponent({ component, parentUuid: activePlaceUuid }));
    const shelfType = getShelfType(component);
    if (isNotDragableShelf(shelfType)) {
      dispatch(addShelfToBlackList(shelfType));
      if (shelfType === "locker") dispatch(addShelfToBlackList("vertical"));
      if (shelfType === "vertical") dispatch(addShelfToBlackList("locker"));
    }
  };

  const handleAddNewPlace = () => {
    try {
      dispatch(addComponent({}));
      dispatch(setMode({ mode: "interior" }));
    } catch (e) {
      setErrorMessage(`${t("Error.newPlace")}`);
      setOpenError(true);
    }
  };

  useEffect(() => {
    if (error) {
      setErrorMessage(t(error));
      setOpenError(true);
      dispatch(setError(null));
    }
  }, [error, dispatch, setErrorMessage, setOpenError, t]);

  return (
    <Box
      component="div"
      display="flex"
      flexDirection="row"
      justifyContent="center"
    >
      <ErrorPopup />
      {racks ? (
        <Box
          component="div"
          display="flex"
          paddingTop="20px"
          position="absolute"
          right="10px"
          style={{ overflow: "hidden", overflowY: "scroll" }}
        >
          {mode === "interior" || mode === "main" ? (
            <div />
          ) : mode === "storage" ? (
            <StyledUnitList>
              <StyledAddRackCard
                sx={{
                  display: showAddButton ? "block" : "none",
                }}
                onClick={handleAddNewPlace}
              >
                <StyledCardImages>
                  <img src={svgAddRackPlus} alt="+" />
                  <img src={svgAddRack} alt="rack image" />
                </StyledCardImages>
                <StyledUnitItemTitle
                  sx={{
                    maxWidth: "unset",
                  }}
                >
                  Add a rack
                </StyledUnitItemTitle>
              </StyledAddRackCard>

              {racks.map((item, index) => (
                <Box
                  component="div"
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  key={index}
                  role="listitem"
                >
                  <IconButton
                    sx={{
                      height: "35px",
                      width: "35px",
                      display: "none",
                    }}
                    onClick={() => handleFillPlace(item)}
                  >
                    <Box
                      sx={{
                        height: "35px",
                        width: "35px",
                      }}
                      component="img"
                      src={Plus}
                      alt=""
                    />
                  </IconButton>
                  <StyledUnitItemCard>
                    <Box
                      component="img"
                      alt=""
                      src={item.image ? item.image : UnitIcon}
                      sx={{
                        height: "150px",
                      }}
                    />
                    <StyledUnitItemTitle>
                      {currentName(item, language)}
                    </StyledUnitItemTitle>
                    <StyledUnitItemSubTitle>
                      {"W x H, mm: " + item.width + " x " + item.height}
                    </StyledUnitItemSubTitle>
                  </StyledUnitItemCard>
                </Box>
              ))}
            </StyledUnitList>
          ) : (
            <StyledUnitList>
              {shelfs.map((item, index) => (
                <Box
                  component="div"
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  key={index}
                  role="listitem"
                >
                  <IconButton
                    sx={{
                      height: "35px",
                      width: "35px",
                      display: "none",
                    }}
                    onClick={() => handleAddShelf(item)}
                  >
                    <Box
                      sx={{
                        height: "35px",
                        width: "35px",
                      }}
                      component="img"
                      src={Plus}
                      alt=""
                    />
                  </IconButton>
                  <StyledUnitItemCard>
                    <Box
                      component="img"
                      alt=""
                      src={item.image ? item.image : TestKitIcon}
                      sx={{
                        height: "150px",
                      }}
                    />
                    <StyledUnitItemTitle>
                      {currentName(item, language)}
                    </StyledUnitItemTitle>
                    <StyledUnitItemSubTitle>
                      {"W x H, mm: " + item.width + " x " + item.height}
                    </StyledUnitItemSubTitle>
                  </StyledUnitItemCard>
                </Box>
              ))}
            </StyledUnitList>
          )}
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};

const StyledUnitList = styled(List)(({ theme }) => ({
  height: "75vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",

  "> .MuiBox-root": {
    "&:not(selected)": {
      "&:hover": {
        "> button": {
          display: "flex",
        },
      },
    },
  },
}));

const StyledUnitItemCard = styled(Box)(({ theme }) => ({
  width: "332px",
  minHeight: "200px",
  display: "flex",
  flexDirection: "column",

  background: "#FCFDFD",
  boxShadow: "1px 2px 7px rgba(0, 54, 95, 0.15)",
  borderRadius: "14px",
  padding: "16px",
  margin: "16px",
}));

const StyledCardImages = styled(Box)`
  display: flex;
  padding-left: 100px;
  align-items: center;
  gap: 12px;
`;

const StyledAddRackCard = styled(StyledUnitItemCard)`
  min-height: 162px;
  padding-top: 20px;
  background: #e7e568;
  cursor: pointer;
  text-align: center;
  gap: 24px;

  &.MuiBox-root:not(selected):hover {
    margin-right: 16px;
  }
`;

const StyledUnitItemTitle = styled(Box)(({ theme }) => ({
  maxWidth: "300px",
  width: "100%",
  height: "100%",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "18px",
  lineHeight: "20px",
  letterSpacing: "0.1px",
  color: theme.palette.primary.main,
}));

const StyledUnitItemSubTitle = styled(Box)(({ theme }) => ({
  maxWidth: "300px",
  maxHeight: "22px",
  width: "100%",
  height: "100%",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "22px",
  letterSpacing: "0.1px",
  color: theme.palette.secondary.main,
}));

export default EditEquip;
