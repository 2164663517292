import { httpClient } from '../httpClient/httpClient';
import {PresetProps, AttachPresetProps, PresetUpdateProps} from '../../types/preset';

export const presetsService = {
    getListPreset(id: string) {
        return httpClient.get('/kit-presets/package-car-' + id);
    },
    getPreset(id: string) {
        return httpClient.get('/kit-presets/' + id);
    },
    createPreset(params: PresetProps) {
        return httpClient.post('/kit-presets/create', params);
    },
    updatePreset(params: PresetUpdateProps) {
        return httpClient.post('/kit-presets/update', params);
    },
    deletePreset(id: string) {
        return httpClient.post('/kit-presets/delete', { id: id });
    },
    attachPackages(params: AttachPresetProps) {
        return httpClient.post('kit-presets/attach-packages-cars', params);
    },
    presetOverview(id: string) {
        return httpClient.get('/kit-presets/total-sum-' + id);
    },
    allComponentOverview(id: string) {
        return httpClient.get('/kit-presets/' + id + '/components');
    },
    userPresets(id: string) {
        return httpClient.get('/kit-presets/' + id + '/for-user');
    },
};
