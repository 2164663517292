import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';
import { DataTexture } from 'three';
export enum ResourceType {
    GLTF = 'GLTF',
    HDR_TEXTURE = 'HDR_TEXTURE',
    SPRITE = 'SPRITE',
    IMAGE = 'IMAGE',
}

export type RequestParams = { Authorization: string };

export type ResourseLoaderParams = {
    url: string;
    resource: Resource;
    requestParams?: RequestParams;
};

export interface Loader {
    loadResource({ url, resource, requestParams }: ResourseLoaderParams): Promise<void>;
}

export interface Resource {
    type?: ResourceType;
    url: string;
    error?: Error;
    content?: unknown;
    loaded: boolean;
}

export interface GltfResource extends Resource {
    type: ResourceType.GLTF;
    content?: GLTF;
}

export interface HdrTextureResource extends Resource {
    type: ResourceType.HDR_TEXTURE;
    content?: DataTexture;
}
export interface ImageResource extends Resource {
    type: ResourceType.IMAGE;
    content?: string;
}

export function isGltfResource(resource: Resource): resource is GltfResource {
    return (resource as GltfResource).type === ResourceType.GLTF;
}
export function isImageResource(resource: Resource): resource is ImageResource {
    return (resource as ImageResource).type === ResourceType.IMAGE;
}
