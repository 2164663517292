import * as THREE from 'three';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';
import { MeshStandardMaterial } from 'three';
import { ShelfComponent } from '../../types/component';
import { ShelfType } from '../../types/scene';
import { getShelfType } from './helpers';

export interface ISceneEntity {
    entityType: string;
}

export class ShelfEntity extends THREE.Group implements ISceneEntity {
    private _entityType = 'shelf';
    private _label: string;

    constructor(label: string) {
        super();
        this._label = label;
    }
    public get entityType(): string {
        return this._entityType;
    }
    public get label(): string {
        return this._label;
    }
}

export class Shelf {
    private _type: ShelfType = 'none';
    private componentType = '';
    private _nameEng = ''; // TODO: костылище
    private _uuid: string;
    private _height = 0;
    private width = 0;
    private depth = 0;
    public position = 0;
    public isPlaced = false;
    public isActiveRed = false;
    private _object = new THREE.Group();
    private mainMesh: THREE.Mesh | null = null;
    private color = new THREE.Color();
    private material: THREE.MeshStandardMaterial | null = null;
    private _botBoltPosition?: number;
    private _topBoltPosition?: number;
    public get topBoltPosition() {
        return this._topBoltPosition;
    }
    public get botBoltPosition() {
        return this._botBoltPosition;
    }
    public get uuid(): string {
        return this._uuid;
    }
    public get type(): ShelfType {
        return this._type;
    }
    public get object(): THREE.Group {
        return this._object;
    }
    constructor(
        { glb_model, depth, height, width, position, uuid, name_eng }: ShelfComponent,
        resource: GLTF
    ) {
        this.componentType = glb_model;
        this.depth = depth || this.depth;
        this._height = height !== 0 ? height : this._height;
        this.width = width || this.width;
        this.position = position;
        this._uuid = uuid;
        this._nameEng = name_eng;
        this._type = getShelfType({ name_eng, glb_model });
        this.initView(resource);
    }
    public tempSetterForHeight(height: number) {
        this._height = height;
    }
    public get height(): number {
        return this._height;
    }
    public get name(): string {
        return this.uuid;
    }
    public makeActive(): void {
        const material = this.material as THREE.MeshStandardMaterial;
        material.color.set(0x22ff22);
        material.needsUpdate = true;
        this.isActiveRed = false;
        if (this.mainMesh) this.mainMesh.material = material;
    }
    public makeActiveRed(): void {
        const material = this.material as THREE.MeshStandardMaterial;
        material.color.set(0xff2222);
        material.needsUpdate = true;
        this.isActiveRed = true;
        if (this.mainMesh) this.mainMesh.material = material;
    }
    public makeInactive(): void {
        const mainMesh = this.mainMesh;
        this.isActiveRed = false;
        if (!mainMesh) return;
        const material = mainMesh.material as THREE.MeshStandardMaterial;
        material.color.set(this.color ?? new THREE.Color(0, 0, 0));
        material.needsUpdate = true;
        if (this.mainMesh) this.mainMesh.material = material;
    }

    public tempGetMainMesh(): THREE.Mesh | null {
        return this.mainMesh;
    }
    private initView(resource: GLTF) {
        const meshes = new Array<THREE.Mesh>();
        resource.scene.clone().traverse((object) => {
            if (object.name.includes('Empty')) {
                if (object.name.includes('Higher')) {
                    this._topBoltPosition = object.position.y;
                } else {
                    this._botBoltPosition = object.position.y;
                }
            }
            if (object instanceof THREE.Mesh) {
                if (!this.mainMesh) {
                    this.mainMesh = object;
                    this.material =
                        object.material.clone() as THREE.MeshStandardMaterial;
                    this.color = this.material.color.clone();
                }
                object.rotateY(Math.PI);
                meshes.push(object);
            }
        });
        const shelfGroup = new ShelfEntity(this.uuid);

        meshes.forEach((mesh) => {
            if (
                this.type === 'carrylite' &&
                mesh.material instanceof MeshStandardMaterial
            ) {
                mesh.material.transparent = false;
                mesh.material.depthWrite = true;
            }
            if (
                this.type === 'preset' &&
                mesh.material instanceof MeshStandardMaterial &&
                mesh.material.color.getHexString() === 'ffffff'
            ) {
                mesh.material.color = new THREE.Color('rgb(0, 54, 95)');
            }
            if (this.type === 'top') {
                mesh.material = new MeshStandardMaterial({
                    metalness: 1,
                    roughness: 0.3,
                    color: new THREE.Color(
                        0.890855610370636,
                        0.8705766797065735,
                        0.9114075899124146
                    ),
                    flatShading: false,
                    fog: true,
                    envMapIntensity: 0.3,
                });
                mesh.material.needsUpdate = true;
            }
            mesh.castShadow = true;
            shelfGroup.add(mesh);
            mesh.visible = true;
        });
        this._object = shelfGroup;
    }

    public get isDragable(): boolean {
        return !['locker', 'panel_l', 'panel_r', 'vertical', 'top'].includes(this.type);
    }
}
