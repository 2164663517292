export type CarParams = {
    a: number;
    b: number;
    c: number;
    d: number;
    e: number;
    f: number;
    g: number;
    h: number;
    i: number;
    j: number;
    k: number;
    l: number;
    m: number;
    n: number;
    o: number;
    p: number;
    r: number;
    s: number;
    double_door?: boolean;
    name?: string;
};
export class Car {
    private _params: CarParams = {} as CarParams;
    setCarSizes(params: CarParams) {
        Object.entries({...params}).forEach(([key, value]) => {
            if (value) {
                //@ts-ignore
                //TODO: поправить
                if (key.length === 1 || key.startsWith('width') || ['height', 'length'].includes(key)) this._params[key] = +value;
                //@ts-ignore
                else this._params[key] = value;
            }
        });
    }
    public get lArcSize(): number {
        if (!this.params) return 0;
        const { r, f, b } = this.params;
        return r - f - b;
    }
    public get params(): CarParams {
        return this._params;
    }
    public get rArcSize(): number {
        if (!this.params) return 0;
        const { r, g, c } = this.params;
        return r - g - c;
    }
    public get arcHeight(): number {
        return this._params.i;
    }
}
