import { httpClient } from "../httpClient/httpClient";

export type LoginRequestValuesType = {
  email: string;
  password: string;
};
export type LogoutRequestValuesType = {
  autorization: string;
};

export const authService = {
  login(params: LoginRequestValuesType) {
    return httpClient.post("/login", params);
  },
  logout() {
    return httpClient.get("/logout");
  },
};
