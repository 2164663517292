import { RendererState } from '../SceneWrapper';
import * as THREE from 'three';
import { Mode } from '../../../types/scene';

const rendererMain: RendererState = {
    outputEncoding: THREE.sRGBEncoding,
    physicallyCorrectLights: true,
};
const rendererState = new Map<Mode, RendererState>();
rendererState.set('main', rendererMain);
rendererState.set('screenshot', rendererMain);
rendererState.set('shelves', rendererMain);
rendererState.set('storage', rendererMain);
rendererState.set('interior', rendererMain);
export default rendererState;
