import { createSlice } from "@reduxjs/toolkit";

export type Wardrobes = {
    id: string,
    name_dk: number,
    name_eng: number,
    image?: File,
    component_id: string,
    wardrobe_type_id: string,
    quantity: number,
}

type wardrobesSliceInitialState = {
    loading: boolean,
};

const initialState: wardrobesSliceInitialState = {
    loading: false,
}

const wardrobesSlice = createSlice({
    name: 'wardrobes',
    initialState,
    reducers:{
        setLoading: (state, {payload}) => {
            if (payload) {
                console.log('loading wardrobes');
            }
            state.loading = payload;
        },
    }
});

export const {
    setLoading,
} = wardrobesSlice.actions;

export default wardrobesSlice.reducer;
