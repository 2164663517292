import React from 'react';
import { Box, styled } from '@mui/material';
import Arrow from '../../icons/ArrowIcon.svg';
import { useTranslation } from 'react-i18next';
import { useHelp } from '../../hooks/useHelp';

const Footer = () => {
  const { brand, model, packagesModel, weight, totalSum } = useHelp();
  const { t } = useTranslation();
  return (
    <Box
      component="div"
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      style={{
        backgroundColor: '#F2F5F7',
      }}
      sx={{
        height: '4vh',
        padding: {
          xl: '4px 120px 4px 120px',
          lg: '4px 120px 4px 120px',
          md: '2px 56px 2px 56px',
          sm: '4px 24px 4px 24px',
        },
      }}
    >
      <Box
        component="div"
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-start"
        gap="22px"
      >
        <FooterItem>{brand}</FooterItem>
        {!!model && <img src={Arrow} alt="" />}
        <FooterItem>{model}</FooterItem>
        {!!packagesModel && <img src={Arrow} alt="" />}
        <FooterItem>{packagesModel}</FooterItem>
      </Box>
      <Box
        component="div"
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-end"
        gap="48px"
      >
        <FooterItem>
          {t("footer.firstItem") + ": " + weight + " " + t("Common.massUnits")}
        </FooterItem>
        <FooterItem>
          {t('footer.secondItem')+ ': ' + totalSum}
        </FooterItem>
      </Box>
    </Box>
  );
};

const FooterItem = styled(Box)(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '18px',
  lineHeight: '20px',
  color: '#66869f',
  [theme.breakpoints.down('lg')]: {
    fontStyle: '16px',
    lineHeight: '18px',
  },
}));

export default Footer;
