import { createContext } from "react";

export interface ISuccessContext {
    successMessage?: string;
    setSuccessMessage: (message: string) => void;
    openSuccess?: boolean;
    setOpenSuccess: (success: boolean) => void;
}

const SuccessContext = createContext<ISuccessContext>({
    setSuccessMessage(message: string) {},
    setOpenSuccess(success: boolean) {},
});

export {SuccessContext};