import { Box } from '@mui/material';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Footer from '../../components/Footer';
import Headers from '../../components/Headers';
import Vehicle from './vehicle';
import Extras from './extras';
import Overview from './overview';
import { useAuth } from '../../hooks/useAuth';
import NoData from '../../components/NoData';
import Equip from './equip/equip';
import CreateEquip from './equip/create';
import EquipList from './equip/list';
import Canvas from '../../components/Canvas/Canvas';

const Main = () => {
    const { authenticated } = useAuth();
    return authenticated ? (
        <>
            <Box component="div" height="100vh">
                <Headers />
                <Box
                    component="div"
                    height="90vh"
                    style={{
                        background: '#F2F5F7',
                    }}
                >
                  <Canvas />
                    <Switch>
                        <Route exact path="/configurator" component={Vehicle} />
                        <Route path="/configurator/:packagesId/equip-list" component={EquipList} />
                        <Route path="/configurator/equip/:id" component={Equip} />
                        <Route path="/configurator/equip-create/:packagesId" component={CreateEquip} />
                        <Route exact path="/configurator/extras" component={Extras} />
                        <Route path="/configurator/overview/:presetId" component={Overview} />
                    </Switch>
                </Box>
                <Footer />
            </Box>
        </>
    ) : (
        <Box component="div" display="flex" justifyContent="center">
            <NoData />
        </Box>
    );
};

export default Main;
