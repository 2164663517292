import React from 'react';
import {useAuth} from '../../hooks/useAuth';
import Logo from '../../icons/Logo.svg';
import {Box, styled} from '@mui/material';
import useAppSelector from '../../hooks/useAppSelector';
import {Link, useHistory} from 'react-router-dom';
import SwitchCurrency from '../SwitchCurrency';
import SwitchLanguage from '../SwitchLanguage';
import {useTranslation} from 'react-i18next';
import {useHelp} from '../../hooks/useHelp';
import PopupPresets from '../PopupPresets';
import {EDialogs} from "../../types/general";

const Headers = () => {
    const { logout } = useAuth();
    const history = useHistory();
    const { setWeight, setOpenDialog, setDialogName, setTotalSum } = useHelp();
    const me = useAppSelector((state) => state.user.me);
    const onLogout = () => {
        logout();
    };

    const { t } = useTranslation();

    const openPresetDialog = () => {
        setDialogName(EDialogs.PRESETS);
        setOpenDialog(true);
    }

    return (
        <Box
            component="div"
            display="flex"
            flexDirection="row"
            alignItems="center"
            height="5vh"
            sx={{
                gap: {
                    xl: '25px',
                    lg: '25px',
                    md: '20px',
                    sm: '15px',
                },
                position: 'relative',
                zIndex: 2,
            }}
            style={{
                backgroundColor: '#00365F',
            }}
        >
            <Box component="div" display="flex" flexDirection="row" flex="1">
                <NavItem onClick={openPresetDialog}>{t('header.openButton')}</NavItem>
            </Box>
            <Box
                component="div"
                alignItems="center"
                justifyContent="center"
                onClick={() => {
                    setWeight('0');
                    setTotalSum('0');
                }}
            >
                <Link to="/configurator">
                    <img
                        src={Logo}
                        alt=""
                        style={{
                            height: '100%',
                        }}
                    />
                </Link>
            </Box>
            <Box
                component="div"
                display="flex"
                flexDirection="row"
                flex="1"
                alignItems="center"
                justifyContent="flex-end"
            >
                {me?.is_admin && (
                    <NavItem>
                        <Box component="div" onClick={() => history.push('/admin/users')} fontWeight="bold">
                            Admin
                        </Box>
                    </NavItem>
                )}
                <SwitchCurrency />
                <SwitchLanguage />
                <NavItem onClick={() => onLogout()}>Logout</NavItem>
            </Box>
            <PopupPresets />
        </Box>
    );
};

const NavItem = styled(Box)(({ theme }) => ({
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '20px',
    margin: '0px 20px',
    color: '#E9EFF1',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
        margin: '0px 15px',
    },
}));

export default Headers;
