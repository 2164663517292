import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';
import { carsService } from '../api/cars';

export type Brands = {
    id: string;
    name: string;
    image: string;
};

export type BrandsProps = {
    name: string;
    image: File;
};

export type StatusProps = {
    status: string;
    statusText: string;
};

type brandSliceInitialState = {
    loading: boolean;
    status: string;
    brandsAll: Brands[];
    brandInfo: Brands | null;
};

const initialState: brandSliceInitialState = {
    loading: false,
    brandsAll: [],
    brandInfo: null,
    status: '',
};

const brandSlice = createSlice({
    name: 'brand',
    initialState,
    reducers: {
        setLoading: (state, { payload }) => {
            state.loading = payload;
        },
        setStatus: (state, { payload }) => {
            state.status = payload;
        },
        setBrandsAll: (state, { payload }) => {
            state.brandsAll = payload;
        },
        setBrandInfo: (state, { payload }) => {
            state.brandInfo = payload;
        },
    },
});

export const { setLoading, setBrandsAll, setBrandInfo, setStatus } = brandSlice.actions;
//получить полный списко брендов
export const getBrandsAll = createAsyncThunk('/api/brands', async (_, { dispatch }) => {
    dispatch(setLoading(true));
    const response = await carsService.getAllBrandsCar();

    if (response.status === 200) {
        dispatch(setBrandsAll(response.data));
    }
    if (response.data.error) {
        dispatch(setStatus(response.data.error));
    }
    dispatch(setLoading(false));
});
//получить один бренд
export const getBrand = createAsyncThunk(
    '/api/brands',
    async (id: string, { dispatch }) => {
        dispatch(setLoading(true));
        const response = await carsService.getBrand(id);
        if (response.status === 200) {
            dispatch(setBrandInfo(response.data));
        }
        dispatch(setLoading(false));
    }
);
//создать бренд
export const createBrand = createAsyncThunk(
    '/api/brands/create',
    async (params: FormData, { dispatch }) => {
        try {
            dispatch(setLoading(true));
            await carsService.createBrand(params);
            return true;
        } catch (err) {
        } finally {
            dispatch(getBrandsAll());
        }
        return false;
    }
);
//удалить бренд
export const deleteBrand = createAsyncThunk(
    '/api/brands/delete',
    async (id: string, { dispatch }) => {
        try {
            dispatch(setLoading(true));
            await carsService.deleteBrand(id);
            return true;
        } catch (err) {
        } finally {
            dispatch(getBrandsAll());
        }
        return false;
    }
);
//обновить бренд
export const updateBrand = createAsyncThunk(
    '/api/brands/update',
    async (params: FormData, { dispatch }) => {
        try {
            dispatch(setLoading(true));
            await carsService.updateBrands(params);
            return true;
        } catch (err) {
        } finally {
            dispatch(getBrandsAll());
        }
        return false;
    }
);

export const selectLoading = (state: RootState) => state.brand.loading;
export const selectStatus = (state: RootState) => state.brand.status;
export const selectBrandsAll = (state: RootState) => state.brand.brandsAll;
export const selectBrandInfo = (state: RootState) => state.brand.brandInfo;

export default brandSlice.reducer;
