import React, { useEffect, useState } from 'react';
import { Box, styled, Tabs, Tab, Button } from '@mui/material';
import StepPanel from '../../../components/StepPanel';
import Helper from '../../../components/HelperText';
import { generatePath, useHistory } from 'react-router-dom';
import EditIcon from '../../../icons/EditIcon.svg';
import CircleRight from '../../../icons/CIrcleRightIcon.svg';
import useAppDispatch from '../../../hooks/useAppDispatch';
import { useHelp } from "../../../hooks/useHelp";
import EditEquip from './edit';
import CheckIcon from '../../../icons/CheckBoxIcon.svg';
import { useTranslation } from 'react-i18next';
import { setMode, setShow, requestPreviews } from '../../../services/store/app3dSlice';
import useAppSelector from '../../../hooks/useAppSelector';
import { Mode } from '../../../types/scene';
import HomeIcon from '../../../icons/ant-design_home-filled.svg';
import StyledHomeButton from '../../../components/StyledHomeButton';

const СreateEquip = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const history = useHistory();
    const {
      mode: viewMode,
      total_sum,
      totalWeight,
    } = useAppSelector((state) => state.app3d);
    const { currency, setTotalSum, setWeight } = useHelp();
    const [tab, setTab] = useState<number>(0);
    useEffect(() => {
        dispatch(setShow(true));
        return () => {
            dispatch(setShow(false));
        };
    }, [dispatch]);

    const currencyPriceFunction = (item: any) => {
        switch (currency) {
            case 'gbp':
                return item?.price_gbp.toFixed(2) + " GBP";
            case 'dkk':
                return item?.price_dkk.toFixed(2) + " DKK";
            case 'eur':
                return item?.price_eur.toFixed(2) + " EUR";
            case 'nok':
                return item?.price_nok.toFixed(2) + " NOK";
            case 'sek':
                return item?.price_sek.toFixed(2) + " SEK";
            default:
                return item?.price_dkk.toFixed(2) + " DKK";
        }
    };

    const newTotalSum = currencyPriceFunction(total_sum);

    useEffect(() => {
        setTotalSum(newTotalSum);
    }, [newTotalSum, setTotalSum]);

    useEffect(() => {
        const newWeight = totalWeight.toFixed(3);
        setWeight(newWeight);
    }, [totalWeight, setWeight]);

    const handleSetViewMode = (mode: Mode) => {
        if (viewMode === 'interior' || viewMode === 'main') {
            setTab(mode === 'main' ? 0 : 1);
            dispatch(setMode({ mode }));
        }
        if (viewMode === 'shelves' || viewMode === 'storage') {
            setTab(1);
            dispatch(setMode({ mode: 'interior' }));
        }
    };
    const onConfirmClicked = async (infoData: any) => {
        await dispatch(requestPreviews());
        history.push(generatePath('/configurator/extras'), {
            from: history.location.pathname,
        });
    };
    return (
        <Box
            component="div"
            sx={{
                minHeight: '80vh',
            }}
        >
            <StepPanel
                step={1}
                label={t("Equip.backButton")}
                action={() => {
                    setTotalSum("0");
                    setWeight("0");
                }}
            />
            <Box component="div" position="relative">
                <Box
                    component="div"
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    position="absolute"
                    sx={{
                        left: {
                            xl: '41%',
                            lg: '41%',
                            md: '35%',
                            sm: '33%',
                        },
                    }}
                >
                    <StyledTabsList
                        variant="standard"
                        orientation="horizontal"
                        value={tab}
                        sx={{ zIndex: 200 }}
                    >
                        <StyledTab
                            label={t('Equip.presetTabs.firstTab')}
                            value={0}
                            disabled={viewMode === 'shelves' || viewMode === 'storage'}
                            onClick={() => handleSetViewMode('main')}
                        />
                        <StyledTab
                            label={t('Equip.presetTabs.secondTab')}
                            value={1}
                            disabled={viewMode === 'shelves' || viewMode === 'storage'}
                            onClick={() => handleSetViewMode('interior')}
                        />
                    </StyledTabsList>
                </Box>
            </Box>
            {tab === 0 ? (
                <Box component="div" display="flex" flexDirection="column">
                    <Box
                        component="div"
                        style={{
                            position: 'absolute',
                        }}
                    >
                        <Helper text={t('Equip.presetHelpText')} />
                    </Box>
                    <Box
                        component="div"
                        justifyContent="center"
                        display="flex"
                        alignItems="center"
                    ></Box>
                    <Box
                        position="absolute"
                        top="90vh"
                        sx={{
                            width: '100%',
                        }}
                    >
                        <StyledHomeButton
                            onClick={() => {
                                dispatch(setMode({ mode: 'main' }));
                            }}
                        >
                            <img src={HomeIcon} alt="home" />
                        </StyledHomeButton>
                        <Box
                            component="div"
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            gap="16px"
                            sx={{
                                margin: '0 auto',
                                width: 'min-content',
                            }}
                        >
                            <StyledButton
                                onClick={() => {
                                    handleSetViewMode('interior');
                                }}
                            >
                                <Box
                                    component="div"
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    justifyContent="center"
                                    gap="8px"
                                >
                                    {t('Equip.presetButton.editButton')}
                                    <img src={EditIcon} alt="" />
                                </Box>
                            </StyledButton>
                            <ConfirmButton onClick={() => onConfirmClicked('click')}>
                                <Box
                                    component="div"
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    justifyContent="center"
                                    gap="8px"
                                >
                                    {t('Equip.presetButton.confButton')}
                                    <img src={CircleRight} alt="" />
                                </Box>
                            </ConfirmButton>
                        </Box>
                    </Box>
                </Box>
            ) : (
                <Box component="div" display="flex" flexDirection="column">
                    <Box component="div" height="80vh">
                        {/* <Canvas/> */}
                        <EditEquip tab={tab} />
                    </Box>
                    <Box
                        component="div"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        position="absolute"
                        top="90vh"
                        sx={{
                            left: {
                                xl: '45%',
                                lg: '45%',
                                md: '40%',
                                sm: '40%',
                            },
                        }}
                    >
                        <StyledButton
                            onClick={() => {
                                handleSetViewMode('main');
                            }}
                        >
                            <Box
                                component="div"
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                justifyContent="center"
                                gap="8px"
                            >
                                Ok
                                <img src={CheckIcon} alt="" />
                            </Box>
                        </StyledButton>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

const StyledTabsList = styled(Tabs)(({ theme }) => ({
    background: '#E6EBEF',
    width: '340px',
    borderLeft: '100px transparent',
    borderRight: '100px transparent',
    borderRadius: '0 0 15px 15px / 0 0 100% 100% ',
    boxShadow: '0px 2px 2px rgba(0, 54, 95, 0.15)',
    '& .MuiTabs-indicator': {
        height: '0',
    },
    linearGradient: '(45deg, transparent 15px, #655 0) left',
}));

const StyledTab = styled(Tab)(({ theme }) => ({
    display: 'flex',
    height: '50px',
    width: '170px',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '18px',
    lineHeight: '18px',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    color: theme.palette.secondary.main,
    '&.Mui-selected': {
        background: '#F2F5F7',
        boxShadow: 'inset -4px 6px 3px rgba(0, 54, 95, 0.15)',
    },
}));

const ConfirmButton = styled(Button)(({ theme }) => ({
    borderRadius: '14px',
    background: '#00365f',
    width: '162px',
    maxHeight: '56px',
    height: '100%',
    color: '#FCFDFD',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '18px',
    [theme.breakpoints.down('lg')]: {
        maxHeight: '48px',
        width: '146px',
        fontSize: '16px',
    },
}));

const StyledButton = styled(Button)(({ theme }) => ({
    borderRadius: '14px',
    background: '#FCFDFD',
    width: '162px',
    maxHeight: '56px',
    height: '100%',
    color: theme.palette.primary.main,
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '18px',
    [theme.breakpoints.down('lg')]: {
        maxHeight: '48px',
        width: '146px',
        fontSize: '16px',
    },
}));

export default СreateEquip;
