import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';
import { componentsService } from '../api/components';
import { Constraints } from '../../types/app3d';
import { Component, RackComponent, ShelfComponent } from '../../types/component';
import { ShelfType } from '../../types/scene';
import { getShelfType } from '../../components/Scene/helpers';
import { app3dService } from '../api/app3d';
type componentSliceInitialState = {
    blackListShelves: ShelfType[];
    loading: boolean;
    racksList: RackComponent[];
    racks: RackComponent | null;
    shelfList: ShelfComponent[];
    fullShelfList: ShelfComponent[];
    shelf: ShelfComponent | null;
    components: Component[];
    requestStack: number;
};

const initialState: componentSliceInitialState = {
    blackListShelves: [],
    fullShelfList: [],
    loading: false,
    racksList: [],
    racks: null,
    shelfList: [],
    shelf: null,
    components: [],
    requestStack: 0,
};

const componentsSlice = createSlice({
    name: 'components',
    initialState,
    reducers: {
        setLoading: (state, { payload }) => {
            state.loading = payload;
        },
        setRaks: (state, { payload }) => {
            state.racksList = payload;
        },
        setShelf: (state, { payload }: { payload: ShelfComponent[] }) => {
            const availableShelves = payload.filter(
                (shelf) => !state.blackListShelves.includes(getShelfType(shelf))
            );
            state.fullShelfList = payload;
            state.shelfList = availableShelves;
        },
        setComponents: (state, { payload }) => {
            state.loading = payload;
        },
        setBlackListShelves: (state, { payload }) => {
            state.blackListShelves = payload;
        },
        addShelfToBlackList: (state, { payload }) => {
            state.blackListShelves.push(payload);
        },
        removeShelfFromBlackList: (state, { payload }) => {
            state.blackListShelves = state.blackListShelves.filter(
                (t) => t !== payload
            );
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getRacks.pending, (state) => {
                state.loading = true;
                state.requestStack += 1;
            })
            .addCase(getRacks.fulfilled, (state, action) => {
                state.requestStack -= 1;
                if (state.requestStack === 0) {
                    state.loading = false;
                    state.racksList = action.payload;
                }
            })
            .addCase(getRacks.rejected, (state, action) => {
                // state.error = action.error as ErrorProps;
                // console.log(state.error);
            });
    },
});

export const {
    setLoading,
    setRaks,
    setShelf,
    setComponents,
    setBlackListShelves,
    addShelfToBlackList,
    removeShelfFromBlackList,
} = componentsSlice.actions;

export const getRacks = createAsyncThunk(
    '/components/racks',
    async ({ minWidth, maxWidth, maxHeight, invertMinWidth }: Constraints) => {
        let racks: Array<RackComponent> = [];
        if (invertMinWidth) {
            const responces = await Promise.all([
                componentsService.getRacks(minWidth, maxWidth, maxHeight, true),
                componentsService.getRacks(invertMinWidth, undefined, maxHeight, false),
            ]);
            responces.forEach((r) => {
                r.data.forEach((item: RackComponent) => {
                    if (item.glb_model) racks.push(item);
                });
            });
            return racks
                .filter((v, i, a) => a.indexOf(v) === i)
                .sort((a: { id: number }, b: { id: number }) => a.id - b.id);
        } else {
            const response = await componentsService.getRacks(
                minWidth,
                maxWidth,
                maxHeight,
                false
            );
            racks = response.data.filter(
                (item: RackComponent) => item.glb_model != null
            );
            return racks
                .filter((v, i, a) => a.indexOf(v) === i)
                .sort((a: { id: number }, b: { id: number }) => a.id - b.id);
        }
    }
);

export const getShelves = createAsyncThunk(
    '/components/shelf',
    async (id: number, { dispatch }) => {
        dispatch(setLoading(true));
        const response = await componentsService.getComponentsList(id);
        if (response.status === 200) {
            let data = response.data.filter(
                (item: ShelfComponent) => item.glb_model != null
            );
            const blackList = await app3dService.requestBlackList(data);
            dispatch(setBlackListShelves(blackList));
            dispatch(setShelf(data));
        }
        dispatch(setLoading(false));
    }
);

export const selectRacks = (state: RootState) => state.components.racksList;
export const selectShelfs = (state: RootState) => state.components.shelfList;

export default componentsSlice.reducer;
