import { Box, styled, IconButton } from '@mui/material';
import React, { useEffect } from 'react';
import { useSuccess } from '../../hooks/useSuccess';
import CloseIcon from '../../icons/Close.svg';

const SuccessPopup = () => {
  const { 
    successMessage, 
    setSuccessMessage, 
    setOpenSuccess, 
    openSuccess 
  } = useSuccess();

  useEffect(() => {
    const timer = setTimeout(() => {
      setSuccessMessage('');
      setOpenSuccess(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, [successMessage, setSuccessMessage, setOpenSuccess]);

  return (
    <>
      {openSuccess ? (
        <SuccessBox>
          <Box
            component="div"
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <IconButton
              style={{ height: '12px', width: '12px' }}
              onClick={() => setOpenSuccess(false)}
            >
              <Box
                component="img"
                src={CloseIcon}
                alt=""
                style={{ height: '12px', width: '12px' }}
              />
            </IconButton>
          </Box>
          <SuccessText>{successMessage}</SuccessText>
        </SuccessBox>
      ) : (
        <></>
      )}
    </>
  );
};

export default SuccessPopup;

const SuccessBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  minWidth: '400px',
  background: '#188038',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  boxShadow:
    '0px 8px 16px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(0, 0, 0, 0.08)',
  borderRadius: '12px',
  left: '24px',
  top: '163px',
  padding: '16px 12px 16px 12px',
}));

const SuccessText = styled(Box)(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '16px',
  letterSpacing: '0.1px',
  color: '#FCFDFD',
}));
