import React from "react";
import { Route, Switch } from "react-router-dom";
import {ListBrandCars} from './listBrandCars';
import { ListModels } from "./listModels";
import {ListPackagies} from "./listPackagies";

const Cars = () => {
  return(
    <Switch>
      <Route exact path='/admin/brands' component={ListBrandCars}/>
      <Route exact path='/admin/brands/models/:id' component={ListModels}/>
      <Route exact path='/admin/brands/models/packagies/:id' component={ListPackagies}/>
    </Switch>
  )
}

export default Cars;