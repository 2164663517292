import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import * as THREE from 'three';

export type SceneParams = {
    controls?: ControlsState;
    renderer?: RendererState;
    camera?: CameraState;
    lights?: Array<LightParams>;
    screenshotCamera?: ScreenshotCameraState;
};
export type ControlsState = {
    target?: THREE.Vector3;
    minDistance?: number;
    maxDistance?: number;
    minAzimuthAngle?: number;
    maxAzimuthAngle?: number;
    minPolarAngle?: number;
    enablePan?: boolean;
    enableZoom?: boolean;
    enabled?: boolean;
};

export type RendererState = {
    physicallyCorrectLights?: boolean;
    outputEncoding?: THREE.TextureEncoding;
    size?: { w: number; h: number };
};

export type CameraState = {
    position?: THREE.Vector3;
    fov?: number;
    near?: number;
    far?: number;
    aspect?: number;
};

export type ScreenshotCameraState = {
    position?: THREE.Vector3;
    target?: THREE.Vector3;
    size?: number;
};

export type LightParams = {
    type: 'directional' | 'ambient';
    color: THREE.Color | number;
    intensity: number;
    position?: THREE.Vector3;
};

type CameraType = {
    perspective?: THREE.PerspectiveCamera;
    orthogonal?: THREE.OrthographicCamera;
};

export default class SceneWrapper {
    private readonly cameras: CameraType = {};
    private readonly threeRenderers: Array<THREE.WebGLRenderer> = [];
    private readonly threeControlses: Array<OrbitControls> = [];
    private readonly scene = new THREE.Scene();
    private readonly lights: Array<THREE.DirectionalLight | THREE.AmbientLight> = [];
    private readonly raycaster = new THREE.Raycaster();
    private pmremGenerator: THREE.PMREMGenerator;
    private renderer: THREE.WebGLRenderer;
    private controls: OrbitControls;
    constructor() {
        this.cameras.perspective = this.makeCamera('perspective');
        this.cameras.orthogonal = this.makeCamera('orthogonal');

        this.renderer = this.makeRenderer();
        this.pmremGenerator = new THREE.PMREMGenerator(this.renderer);
        this.controls = this.makeControls();
        this.runRenderCycle();
    }

    private get camera(): THREE.PerspectiveCamera {
        if (!this.cameras.perspective) throw Error('Camera is undefined');
        return this.cameras.perspective;
    }
    private get screeenshotCamera(): THREE.OrthographicCamera {
        if (!this.cameras.orthogonal) throw Error('Camera is undefined');
        return this.cameras.orthogonal;
    }
    public add(object: THREE.Object3D): void {
        this.scene.add(object);
    }
    public attach(object: THREE.Object3D): void {
        this.scene.attach(object);
    }
    public hide(): void {
        this.scene.visible = false;
    }
    public show(): void {
        this.scene.visible = true;
    }
    public setParams({
        camera,
        controls,
        lights,
        renderer,
        screenshotCamera,
    }: SceneParams): void {
        if (lights) {
            if (this.lights.length === 0) lights.forEach((l) => this.appendLight(l));
        }
        if (camera) this.setCameraState(camera);
        if (renderer) this.setRendererState(renderer);
        if (controls) this.setControlsState(controls);
        if (screenshotCamera) this.setScreenShotCameraState(screenshotCamera);
    }
    public setEnvironment(dataTexture: THREE.DataTexture | null): void {
        if (dataTexture) {
            const texture =
                this.pmremGenerator.fromEquirectangular(dataTexture).texture;
            texture.mapping = THREE.EquirectangularRefractionMapping;
            texture.needsUpdate = true;
            this.scene.environment = texture;
        }
    }
    public updateRaycast(event: MouseEvent): void {
        var rect = this.renderer.domElement.getBoundingClientRect();
        const x = ((event.clientX - rect.left) / (rect.width - rect.left)) * 2 - 1;
        const y = -((event.clientY - rect.top) / (rect.bottom - rect.top)) * 2 + 1;
        this.raycaster.setFromCamera({ x, y }, this.camera);
    }
    public findIntersect(
        obj?: THREE.Object3D
    ): Array<THREE.Intersection<THREE.Object3D>> {
        if (obj) return this.raycaster.intersectObjects([obj]);
        else return this.raycaster.intersectObject(this.scene, true);
    }
    public setContainer(container: HTMLElement | null): void {
        if (!container) return;
        container.style.width = '100%';
        container.style.height = '100%';
        this.renderer.domElement.className = 'asdasdasd';
        this.renderer.domElement.style.height = '100%';
        this.renderer.domElement.style.width = '100%';
        // this.renderer.domElement.remove();
        container.appendChild(this.renderer.domElement);
        // this.controls = this.makeControls();
        this.runRenderCycle();
    }

    public setContainerTo(idx: number): boolean {
        if (this.threeRenderers[idx]) {
            this.renderer = this.threeRenderers[idx];
            this.controls = this.threeControlses[idx];
            return true;
        }
        return false;
    }
    public runRenderCycle(): void {
        // const geometry = new THREE.BoxGeometry(1, 1, 1);
        // const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
        // const cube = new THREE.Mesh(geometry, material);
        // this.scene.add(cube);
        this.renderer.setAnimationLoop(() => {
            this.syncRendererSize();
            const canvas = this.renderer.domElement;
            this.camera.aspect = canvas.clientWidth / canvas.clientHeight;
            this.camera.updateProjectionMatrix();
            this.controls.update();
            this.render();
        });
    }

    public syncRendererSize(): void {
        const { parentElement } = this.renderer.domElement;

        if (!parentElement) return;

        const parentSize = new THREE.Vector2(
            parentElement.offsetWidth,
            parentElement.offsetHeight
        );
        const rendererSize = this.renderer.getSize(new THREE.Vector2());

        if (parentSize.equals(rendererSize)) return;

        this.renderer.setSize(parentElement.offsetWidth, parentElement.offsetHeight);
        this.camera.aspect = parentElement.offsetWidth / parentElement.offsetHeight;
        this.camera.updateProjectionMatrix();
    }

    public resize(): void {
        const { innerHeight, innerWidth } = window;
        this.renderer.setSize(innerWidth, innerHeight);
        const canvas = this.renderer.domElement;
        this.camera.aspect = canvas.width / canvas.height;
        this.camera.updateProjectionMatrix();
    }

    public render(camera: keyof CameraType = 'perspective'): void {
        this.renderer?.render(this.scene, this.cameras[camera]!);
    }

    public getShot(camera: keyof CameraType = 'perspective'): string {
        this.render(camera);
        return this.renderer.domElement.toDataURL();
    }
    public destroy(): void {
        this.renderer?.setAnimationLoop(null);
        this.renderer?.dispose();
        if (this.renderer?.domElement.parentElement) this.renderer?.domElement.remove();
    }
    private makeCamera(type: 'perspective'): THREE.PerspectiveCamera;
    private makeCamera(type: 'orthogonal'): THREE.OrthographicCamera;
    private makeCamera(type: keyof CameraType): CameraType[keyof CameraType] {
        if (type === 'perspective') {
            const camera = new THREE.PerspectiveCamera(50, 1, 0.1, 500);
            this.scene.add(camera);
            return camera;
        } else {
            const camera = new THREE.OrthographicCamera();
            camera.far = 5000;
            camera.updateProjectionMatrix();
            this.scene.add(camera);
            return camera;
        }
    }

    private makeControls(): OrbitControls {
        const controls = new OrbitControls(this.camera, this.renderer.domElement);
        controls.minZoom = 0.5;
        controls.maxZoom = 1.5;
        controls.dampingFactor = 0.05;
        controls.screenSpacePanning = false;
        controls.minDistance = 1;
        controls.maxDistance = 10;
        controls.maxPolarAngle = Math.PI / 2;
        controls.enablePan = true;
        return controls;
    }

    private makeRenderer(): THREE.WebGLRenderer {
        const renderer = new THREE.WebGLRenderer({
            preserveDrawingBuffer: true,
            antialias: true,
        });
        renderer.setSize(window.innerWidth, window.innerHeight);
        renderer.setClearColor(0xffffff, 0);
        renderer.outputEncoding = THREE.sRGBEncoding;
        renderer.physicallyCorrectLights = true;
        return renderer;
    }

    private setControlsState(state: ControlsState): void {
        const { target: position, ...other } = state;
        if (position) this.controls.target.copy(position);
        Object.assign(
            this.controls,
            Object.fromEntries(Object.entries(other).filter(([_, v]) => v != null))
        );
        this.controls.update();
    }

    private setCameraState(state: CameraState): void {
        const { position, ...other } = state;
        if (position) this.camera.position.copy(position);
        Object.assign(
            this.camera,
            Object.fromEntries(Object.entries(other).filter(([_, v]) => v != null))
        );
        this.camera.updateProjectionMatrix();
    }

    private setScreenShotCameraState(state: ScreenshotCameraState): void {
        const { position, target, size } = state;
        if (position) this.screeenshotCamera.position.copy(position);
        if (size) {
            this.screeenshotCamera.left = -size;
            this.screeenshotCamera.right = size;
            this.screeenshotCamera.bottom = -size;
            this.screeenshotCamera.top = size;
        }
        if (target) this.screeenshotCamera.lookAt(target);
        this.screeenshotCamera.updateProjectionMatrix();
    }
    private setRendererState(state: RendererState): void {
        if (state.size) this.renderer.setSize(state.size.w, state.size.h);
        Object.assign(
            this.renderer,
            Object.fromEntries(Object.entries(state).filter(([_, v]) => v != null))
        );
    }

    private appendLight(params: LightParams): void {
        const light =
            params.type === 'ambient'
                ? new THREE.AmbientLight()
                : new THREE.DirectionalLight();
        this.scene.add(light);
        this.lights.push(light);
        if (params.position) light.position.copy(params.position);
        light.color = new THREE.Color(params.color);
        light.intensity = params.intensity;
    }
    private earseLights(): void {
        this.lights.forEach((light) => light.parent?.remove(light));
        this.lights.length = 0;
    }
}
