import React, { useState } from "react";
import { SuccessContext } from "./SuccesContext";

interface ISuccessProviderProps{
    children: any
}

const SuccessPopupProvider = ({children}: ISuccessProviderProps) => {
    const [successMessage, setSuccessMessage] = useState('');
    const [openSuccess, setOpenSuccess] = useState(false);
    return(
        <SuccessContext.Provider value = {{
            successMessage,
            setSuccessMessage,
            openSuccess,
            setOpenSuccess
        }}>
            {children}
        </SuccessContext.Provider>
    )
}

export default SuccessPopupProvider;