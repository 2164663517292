import React, {useEffect, useMemo} from 'react';
import { useParams } from 'react-router-dom';
import useAppDispatch from '../../../hooks/useAppDispatch';
import useAppSelector from '../../../hooks/useAppSelector';
import { CarsDataGrid, DialogEditFields } from '../../../components/DataGrid/DataGrid';
import { Box } from '@mui/material';
import { getBrandModels, createModel, updateModel, deleteModel } from '../../../services/store/modelSlice';
import {getBrand} from '../../../services/store/brandSlice';
import NoData from '../../../components/NoData';
import {useError} from "../../../hooks/useError";
import ErrorPopup from "../../../components/ErrorPopup/Index";

const dialogEditFields: DialogEditFields[] = [
    {
        field: 'name',
        name: 'name',
        type: 'text',
        label: 'Model name',
        disabled: false,
    },
    {
        field: 'image',
        name: 'image',
        type: 'text',
        label: 'Image name',
        disabled: true,
    },
    {
        field: 'inputImage',
        name: 'imageFile',
        type: 'file',
        label: 'file',
        disabled: false,
    },
    {
        field: 'brand_car_id',
        name: 'parent_id',
        type: 'text',
        label: 'Brand id',
        disabled: true,
        hide: true,
    },
];

const ListModels = () => {
    const { id } = useParams<{ id: string }>();
    const dispatch = useAppDispatch();
    const { setErrorMessage, setOpenError } = useError();

    const brand = useAppSelector((state) => state.brand.brandInfo);
    const models = useAppSelector((state) => state.model.brandModels);

    useEffect(() => {
        dispatch(getBrand(id));
        dispatch(getBrandModels(id));
    }, [id]);

    const columns = useMemo(() => [
        { field: 'name', flex: 1 },
        {
            field: 'image',
            flex: 1,
            renderCell: (params: any) => {
                return !!params.value ? (
                    <Box
                        style={{
                            maxHeight: '50px',
                            maxWidth: '50px',
                        }}
                        component="img"
                        src={params.value}
                    />
                ) : (
                    <>-</>
                );
            },
        },
    ], []);

    const onCreate = (val: FormData) => {
        const name = val.get('name') as string;
        if (!name?.trim()) {
            setErrorMessage('Model name is required');
            setOpenError(true);
            return;
        }
        return createModel(val);
    }

    const onUpdate = (val: FormData) => {
        const name = val.get('name') as string;
        if (!name?.trim()) {
            setErrorMessage('Model name is required');
            setOpenError(true);
            return;
        }
        return updateModel(val);
    }

    return brand
      ? <>
          <CarsDataGrid
            dataSource={models}
            gridColumns={columns}
            module={'models'}
            moduleTitle={'Models'}
            dialogEditFields={dialogEditFields}
            pathForInternalModule={'brands/models/packagies'}
            parentId={id}
            createRow={onCreate}
            updateRow={onUpdate}
            deleteRow={deleteModel}
          />
          <ErrorPopup />
      </>
     : <Box component="div" display="flex" justifyContent="center">
        <NoData />
    </Box>;
};

export { ListModels };
