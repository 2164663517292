import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    IconButton,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {generatePath, useHistory} from 'react-router-dom';
import StepPanel from '../../components/StepPanel';
import Minus from '../../icons/MinusIcon.svg';
import MDisabled from '../../icons/MinusDisabledIcon.svg';
import Plus from '../../icons/PlusIcon.svg';
import AccessoriesIcon from '../../icons/AccessoriesIcon.svg';
import useAppDispatch from '../../hooks/useAppDispatch';
import {
  getAccessoriesList,
  selectAccessories, selectAccessoriesTemp, setAccessoriesListTemp,
  setAccessoriesListTempTotalSum,
} from '../../services/store/accessoriesSlice';
import {
  createPreset,
  presetsUser,
  updatePreset
} from '../../services/store/presetSlice';
import useAppSelector from '../../hooks/useAppSelector';
import NoData from '../../components/NoData';
import { useTranslation } from 'react-i18next';
import { useHelp } from '../../hooks/useHelp';
import { useError } from '../../hooks/useError';
import ErrorPopup from '../../components/ErrorPopup/Index';
import { useSuccess } from '../../hooks/useSuccess';
import SuccessPopup from '../../components/SuccessPopup';
import { currencyPrice } from '../../utils/currencyPrice';
import { currentName } from '../../utils/currentName';
import { setShow, currentPreset } from '../../services/store/app3dSlice';
import { ImageEntity } from '../../types/app3d';
import {Accessories} from "../../types/accessories";

const Extras = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const firstRender = useRef(true);

  useEffect(() => {
    dispatch(setShow(false));
    return () => {
      dispatch(setShow(true));
    };
  }, [dispatch]);

  const [checked, setChecked] = useState<boolean>(false);
  const { currency, language, packagesId, setWeight, setTotalSum } = useHelp();
  const [save, setSave] = useState(false);
  const [presetName, setPresetName] = useState('');
  const accessoriesList: Accessories[] = useAppSelector(selectAccessories);
  const accessories: Accessories[] = useAppSelector(selectAccessoriesTemp);
  const preset = useAppSelector((state) => state.presets.preset);
  const loading = useAppSelector((state) => state.presets.loading);
  const [editAccessories, setEditAccessories] = useState<boolean>(false);
  const unit_presets = useAppSelector(currentPreset);
  const me = useAppSelector((state) => state.user.me);
  const error = useAppSelector((state) => state.presets.error);
  const { setOpenError, setErrorMessage } = useError();
  const success = useAppSelector((state) => state.presets.success);
  const { setOpenSuccess, setSuccessMessage } = useSuccess();
  const { previews, total_sum, totalWeight } = useAppSelector(
    (state) => state.app3d
  );

  useEffect(() => {
    if (!firstRender.current) {
      return;
    }

    if (accessories.length) {
      return;
    }

    if (!accessoriesList.length) {
      dispatch(getAccessoriesList());
      return;
    }

    if (preset?.id) {
      const presetAccessoriesIds = preset.accessories?.map((r) => r.id) || [];
      const temp = accessoriesList.map((item) => {
        if (presetAccessoriesIds.includes(item.id)) {
          const found = preset.accessories.find((d) => d.id === item.id);
          if (found) {
            const fixed = (currencyPrice(found, currency) * found.quantity).toFixed(2);
            return {
              ...found,
              total_price: Number(fixed),
            };
          } else {
            return item;
          }
        }
        return item;
      });
      dispatch(setAccessoriesListTemp(temp));
      firstRender.current = false;
    } else {
      dispatch(setAccessoriesListTemp(accessoriesList));
      firstRender.current = false;
    }
  }, [accessoriesList, accessories, preset]);

  useEffect(() => {
    if (error) {
      setOpenError(true);
      setErrorMessage(error);
      setSave(false);
    }
  }, [error]);

  useEffect(() => {
    if (success) {
      setOpenSuccess(true);
      setSuccessMessage(success);
    }
  }, [success]);

  const filteredRows = checked ? accessories.filter((row) => row.quantity > 0) : accessories;

  const sum = () => {
    switch (currency) {
      case 'gbp': {
        return accessories.reduce(
          (total_price, item) =>
            total_price + Number(item.price_gbp * item.quantity),
          0
        );
      }
      case 'dkk': {
        return accessories.reduce(
          (total_price, item) =>
            total_price + Number(item.price_dkk * item.quantity),
          0
        );
      }
      case 'eur': {
        return accessories.reduce(
          (total_price, item) =>
            total_price + Number(item.price_eur * item.quantity),
          0
        );
      }
      case 'nok': {
        return accessories.reduce(
          (total_price, item) =>
            total_price + Number(item.price_nok * item.quantity),
          0
        );
      }
      case 'sek': {
        return accessories.reduce(
          (total_price, item) =>
            total_price + Number(item.price_sek * item.quantity),
          0
        );
      }
      default: {
        return accessories.reduce(
          (total_price, item) =>
            total_price + Number(item.price_dkk * item.quantity),
          0
        );
      }
    }
  };

  const invoiceSum = sum().toFixed(2);

  useEffect(() => {
    if (invoiceSum === '0.00') {
      setEditAccessories(false);
    } else {
      setEditAccessories(true);
    }
  }, [invoiceSum, editAccessories]);

  const currencyPriceFunction = (item: any, accessories_sum: number) => {
    switch (currency) {
      case 'gbp':
        return (item?.price_gbp + accessories_sum).toFixed(2) + ' GBP';
      case 'dkk':
        return (item?.price_dkk + accessories_sum).toFixed(2) + ' DKK';
      case 'eur':
        return (item?.price_eur + accessories_sum).toFixed(2) + ' EUR';
      case 'nok':
        return (item?.price_nok + accessories_sum).toFixed(2) + ' NOK';
      case 'sek':
        return (item?.price_sek + accessories_sum).toFixed(2) + ' SEK';
      default:
        return (item?.price_dkk + accessories_sum).toFixed(2) + ' DKK';
    }
  };

  const newTotalSumOnlyAccessories = currencyPriceFunction(
    { price_gbp: 0, price_dkk: 0, price_eur: 0, price_nok: 0, price_sek: 0 },
    sum()
  );
  const newTotalSumWithAccessories = currencyPriceFunction(total_sum, sum());

  const [newTotalWeightOnlyAccessories, newTotalWeightWithAccessories] =
    useMemo(() => {
      const totalWeightAccessories = accessories.reduce(
        (totalWeight, item) =>
          totalWeight + Number(item.net_weight * item.quantity),
        0
      );

      return [totalWeightAccessories, totalWeight + totalWeightAccessories].map(
        (item) => item.toFixed(3)
      );
    }, [totalWeight, accessories]);

  useEffect(() => {
    setTotalSum(newTotalSumWithAccessories);
  }, [newTotalSumWithAccessories, setTotalSum]);

  useEffect(() => {
    setWeight(newTotalWeightWithAccessories);
  }, [newTotalWeightWithAccessories, setWeight]);

  const handleChangeTextField = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPresetName(event.target.value);
  };

  const createPresets = async () => {
    const result = await dispatch(
      createPreset({
        data: {
          name: presetName,
          unit_presets,
          accessories: accessories.filter((item) => item.quantity > 0),
          folder_image: (previews.find(p=>p.label === 'overview') as ImageEntity).src
        },
        packagesid: [packagesId],
      })
    );

    if (result?.payload) {
      await dispatch(presetsUser(me?.id!));
      goToOverview(result.payload);
    }
  };

  const updatePresets = async (id: string) => {
    const result = await dispatch(
      updatePreset({
        data: {
          id,
          unit_presets,
          accessories: accessories.filter((item) => item.quantity > 0),
          folder_image: (previews.find(p=>p.label === 'overview') as ImageEntity).src,
        },
      })
    );

    if (result?.payload) {
      goToOverview(result.meta.arg.data.id);
    }
  };

  const goToOverview = (id?: string) => {
    history.push(
      generatePath('/configurator/overview/:presetId', {
        presetId: preset?.id || id || '',
      })
    );
    setOpenError(false);
    setOpenSuccess(false);
  };

  const savePreset = async () => {
    if (!presetName?.trim()) {
      setErrorMessage('Preset name is required');
      setOpenError(true);
      return;
    }
    createPresets();
  };

  const handleCloseSave = () => {
    setSave(false);
  };

  const handleSave = () => {
    const id = preset?.id;
    if (id) {
      updatePresets(id);
    } else {
      setSave(true);
    }
  };

  return accessories.length > 0 ? (
    <Box
      component="div"
      sx={{
        minHeight: '80vh',
      }}
    >
      <StepPanel step={2} label={t('Extras.backButton')} />
      <Box
        component="div"
        sx={{
          padding: {
            xl: '0px 170px 0px 170px',
            lg: '0px 90px 0px 90px',
            md: '0px 50px 0px 50px',
            sm: '0px 24px 0px 24px',
          },
        }}
      >
        <TableContainer
          sx={{
            maxHeight: {
              xl: '436px',
              lg: '436px',
              md: '284px',
              sm: '284px',
            },
            marginTop: {
              xl: '82px',
              lg: '82px',
              md: '30px',
              sm: '30px',
            },
            position: 'relative',
          }}
        >
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <StyledTableHeadCell></StyledTableHeadCell>
                <StyledTableHeadCell>{t('Extras.tableColumn.columnName')}</StyledTableHeadCell>
                <StyledTableHeadCell>{t('Extras.tableColumn.columnMeasure')}</StyledTableHeadCell>
                <StyledTableHeadCell>{t('Extras.tableColumn.columnNumber')}</StyledTableHeadCell>
                <StyledTableHeadCell>{t('Extras.tableColumn.columnUPrice')}</StyledTableHeadCell>
                <StyledTableHeadCell>{t('Extras.tableColumn.columnQ')}</StyledTableHeadCell>
                <StyledTableHeadCell>{t('Extras.tableColumn.columnTPrice')}</StyledTableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell
                    sx={{
                      width: {
                        xl: '100px',
                        md: '70px',
                      },
                      border: 'none',
                      padding: '0px',
                    }}
                  >
                    <Box
                      component="img"
                      alt=""
                      src={row.image instanceof File ? URL.createObjectURL(row.image) : row.image ?? AccessoriesIcon}
                      sx={{
                        height: {
                          xl: '64px',
                          lg: '64px',
                          md: '48px',
                          sm: '48px',
                        },
                        width: {
                          xl: '96px',
                          lg: '96px',
                          md: '72px',
                          sm: '48px',
                        },
                      }}
                    />
                  </TableCell>
                  <StyledTableRowCell>{currentName(row, language)}</StyledTableRowCell>
                  <StyledTableRowCell>{row.net_weight}</StyledTableRowCell>
                  <StyledTableRowCell>{row.item_number}</StyledTableRowCell>
                  <StyledTableRowCell>
                    {currencyPrice(row, currency) + ' ' + currency?.toUpperCase()}
                  </StyledTableRowCell>
                  <StyledTableRowCell>
                    <Box component="div" display="flex" flexDirection="row">
                      <IconButton
                        onClick={() => {
                          dispatch(setAccessoriesListTempTotalSum({
                            id: row.id,
                            currency,
                            operator: '-',
                          }));
                        }}
                        disabled={row.quantity === 0}
                      >
                        {row.quantity === 0 ? (
                          <img src={MDisabled} alt="" />
                        ) : (
                          <img src={Minus} alt="" />
                        )}
                      </IconButton>
                      <StlyledTextField>{row.quantity}</StlyledTextField>
                      <IconButton
                        onClick={() => {
                          dispatch(setAccessoriesListTempTotalSum({
                            id: row.id,
                            currency,
                            operator: '+',
                          }));
                        }}
                      >
                        <img src={Plus} alt="" />
                      </IconButton>
                    </Box>
                  </StyledTableRowCell>
                  <StyledTableRowCell>
                    <Box component="div">{row.total_price + ' ' + currency?.toUpperCase()}</Box>
                  </StyledTableRowCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box component="div" display="flex" justifyContent="flex-end">
          <Box component="div">
            <FormGroup
              sx={{
                marginBottom: {
                  xl: '30px',
                  lg: '30px',
                  md: '10px',
                  sm: '10px',
                },
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={(event) => {
                      setChecked(event.target.checked);
                    }}
                  />
                }
                label={t('Extras.tableChecked')}
              ></FormControlLabel>
            </FormGroup>
            <Box
              component="div"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              sx={{
                marginBottom: {
                  xl: '30px',
                  lg: '30px',
                  md: '15px',
                  sm: '15px',
                },
              }}
            >
              <CustomLabel>{t('Extras.tableInfo.totalW')}</CustomLabel>
              <ValueStyled>
                {newTotalWeightOnlyAccessories + " " + t("Common.massUnits")}
              </ValueStyled>
            </Box>
            <Box
              component="div"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              sx={{
                marginBottom: {
                  xl: '30px',
                  lg: '30px',
                  md: '15px',
                  sm: '15px',
                },
              }}
            >
              <CustomLabel>{t('Extras.tableInfo.sum')}</CustomLabel>
              <ValueStyled>{newTotalSumWithAccessories} </ValueStyled>
            </Box>
            <Box component="div" display="flex" flexDirection="row" justifyContent="space-between">
              <CustomLabel>{t('Extras.tableInfo.totalPrice')}</CustomLabel>
              <ValueStyled>{newTotalSumOnlyAccessories}</ValueStyled>
            </Box>
          </Box>
        </Box>
        <Box component="div" display="flex" flexDirection="row" justifyContent="center" gap="12px">
          <StyledButton
            onClick={handleSave}
            disabled={loading}
          >
            {t('Extras.button.save')}
          </StyledButton>
        </Box>
        <Dialog open={save} onClose={handleCloseSave}>
          <DialogTitle>{t('Extras.dialog.title')}</DialogTitle>
          <DialogContent>
            <TextField
              helperText={t('Extras.dialog.helpText')}
              onChange={handleChangeTextField}
              value={presetName}
            ></TextField>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => savePreset()}
              disabled={loading}
            >
              {t('Extras.button.save')}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      <ErrorPopup />
      <SuccessPopup />
    </Box>
  ) : (
    <Box component="div" display="flex" justifyContent="center">
      <NoData />
    </Box>
  );
};

const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
  background: '#F2F5F7',
  border: 'none',
  color: theme.palette.primary.main,
  fontWeight: '600',
  fontSize: '18px',
  lineHeight: '20px',
  textAlign: 'left',
  padding: '0px',
  [theme.breakpoints.down('lg')]: {
    fontSize: '16px',
    lineHeight: '18px',
  },
}));

const StyledTableRowCell = styled(TableCell)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '20px',
  color: theme.palette.primary.main,
  textAlign: 'left',
  border: 'none',
  padding: '0px',
  [theme.breakpoints.down('lg')]: {
    fontSize: '14px',
    lineHeight: '16px',
  },
}));

const StlyledTextField = styled(Box)(({ theme }) => ({
  background: '#FCFDFD',
  border: '1px solid #FCFDFD',
  boxShadow: 'inset 0px 1px 4px rgba(0, 54, 95, 0.15)',
  borderRadius: '13px',
  outline: 'none',
  position: 'inherit',
  textAlign: 'center',
  width: '48px',
  height: '37px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  userSelect: 'none',
}));

const CustomLabel = styled(Box)(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '18px',
  lineHeight: '20px',
  textAlign: 'right',
  [theme.breakpoints.down('lg')]: {
    fontSize: '16px',
  },
}));

const ValueStyled = styled(Box)(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '18px',
  lineHeight: '20px',
  [theme.breakpoints.down('lg')]: {
    fontSize: '16px',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '14px',
  background: theme.palette.primary.main,
  fontWeight: '700',
  minHeight: '50px',
  fontSize: '18px',
  lineHeight: '18px',
  color: '#FCFDFD',
  textTransform: 'none',
  [theme.breakpoints.down('lg')]: {
    fontSize: '16px',
  },
}));

export default Extras;
