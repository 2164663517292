import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { RequireAuth } from './components/AuthProvider/RequireAuth';
import { useAuth } from './hooks/useAuth';
import Login from './pages/login/index';
import Users from './pages/admin/users';
import Main from './pages/main';
import Admin from './pages/admin';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Spinner from './components/Spinner/Spinner';
import useAppDispatch from './hooks/useAppDispatch';
import {getMe, setError} from './services/store/userSlice';
import useAppSelector from "./hooks/useAppSelector";

function App() {
    const {
        setToken,
        loginState,
        setLoginState,
        setAuthenticated,
        authenticated,
        logout,
        token
    } = useAuth();

    const me = useAppSelector((state) => state.user.me);
    const error = useAppSelector((state) => state.user.error);
    const [open, setOpen] = useState<boolean>(false);

    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        if (error) {
            setOpen(true);
            setLoginState('not logged');
            setAuthenticated(false);
        }
    }, [error]);

    useEffect(() => {
        if (me) {
            setLoginState('logged');
            setAuthenticated(true);
            dispatch(setError(null));
        }
    }, [me]);

    useEffect(() => {
        if (token) {
            dispatch(getMe());
        } else {
            setLoginState('not logged');
        }
    },[token]);

    useEffect(() => {
        let token = localStorage.getItem('token');
        if (token) {
            setToken(token);
        }
    }, []);

    const relogin = () => {
        setOpen(false);
        logout();
        <Redirect to="/login" />;
    };

    return (
        <>
            <Spinner />
            {loginState !== 'wait' && (
                <Router>
                    <Switch>
                        <Route exact path="/">
                            {authenticated ? <Redirect to="/configurator" /> : <Login />}
                        </Route>
                        <Route exact path="/login" component={Login} />
                        <Route path="/admin">
                            <RequireAuth>
                                <Admin />
                            </RequireAuth>
                        </Route>
                        <Route path="/configurator">
                            <RequireAuth>
                                <Main />
                            </RequireAuth>
                        </Route>
                        <Route path="/users">
                            <RequireAuth>
                                <Users />
                            </RequireAuth>
                        </Route>
                        <Route exact path="*"></Route>
                    </Switch>
                </Router>
            )}
            <Dialog open={open}>
                <DialogTitle
                    style={{
                        color: '#00365F',
                    }}
                >
                    {t('timeOutDialog.title')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>{t('timeOutDialog.text')}</DialogContentText>
                </DialogContent>
                <DialogActions
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <Button
                        style={{
                            background: '#00365f',
                            color: '#FCFDFD',
                            borderRadius: '14px',
                            width: '162px',
                            height: '100%',
                        }}
                        onClick={relogin}
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default App;
