import { SIDES } from '../consts/scene';

export type Side = typeof SIDES[number];

export enum EDialogs {
  PRESETS = 'presets',
  DELETE_PRESET = 'deletePreset',
}

export type TDialogNames = EDialogs | null;
