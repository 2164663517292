import { httpClient } from '../httpClient/httpClient';

export const componentsService = {
    getListComponents() {
        return httpClient.get('/components');
    },
    getComponents(id: string) {
        return httpClient.get('/components' + id);
    },
    getComponentsList(id: number) {
        return httpClient.get(`components/show-by-racks/${id}`);
    },
    createComponents(params: FormData) {
        return httpClient.post('/components/create', params);
    },
    updateComponents(params: FormData) {
        return httpClient.post('/components/update', params);
    },
    deleteComponents(id: string) {
        return httpClient.post('/components/delete', { id });
    },
    getRacks(minWidth?: number, maxWidth?: number, maxHeight?: number, isWidthInvert?: boolean) {
        return httpClient.get('/components/racks', {
            'min-width': minWidth ?? 0,
            'max-width': maxWidth ?? 999999999,
            'max-height': maxHeight ?? 300,
            'invert-width': isWidthInvert ?? false,
        });
    },
    getShelfs() {
        return httpClient.get('components/shelfs-and-extensions');
    },
};
