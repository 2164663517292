import { baseURLconfig } from '../services/httpClient/httpClient';

export const ASSETS_PATH = '/';
export const MODELS_API_PATH = baseURLconfig + '/glb/';
export const AOMAPS_API_PATH = baseURLconfig + '/jpg/';
export const CAR_BASE_OBJECT_NAME = 'Carge_Base_Mesh';
export const RACK_MATERIAL_BASE_NAME = 'metall_ao_';
export const MIN_DISTANCE_BETWEEN_SIDES_FOR_INTERIOR_VIEW = 900;
export const SIDES = ['left', 'right'] as const;
export const CarMeshName = {
    base: 'Carge_Base_Mesh_1',
    floor: 'Carge_Base_Mesh_2',
    general: 'Carge_Base_Mesh',
};

const MorphTargetsNames = ['A_D', 'F_G', 'R', 'H', 'N', 'K', 'L_O', 'M_P', 'E', 'B_C', 'I_J'] as const;

export type MorphTargetsName = typeof MorphTargetsNames[number];

export const DEFAULT_SCALE = 1000;
export const TOP_SHELF_METRIC_HEIGHT = 0.23;
export const SHELF_POSITION_TOLERANCE = 0.01;
export const START_POSITION = 62 / DEFAULT_SCALE;
export const CENTER_DISTANCE = 19.56 / DEFAULT_SCALE;
export const RACK_BASE_OFFSET = 200;
export const BOX_DEFAULT_WIDTH = 450;
export const BOX_DEFAULT_HEIGHT = 1000;
export const SHELF_DEFAULT_DEPTH = 380;
export const DEPTH_METRIC_SIZE = SHELF_DEFAULT_DEPTH / DEFAULT_SCALE;

export const Default = {
    scale: 1000,
    width: 450,
    height: 1000,
    depth: 380,
    spriteSize: 0.13,
};
export class CarMinMax {
    min: number;
    max: number;

    constructor(min_: number, max_: number) {
        this.min = min_;
        this.max = max_;
    }
}


export const CarMinMaxPerTarget = new Map<string, CarMinMax>([
    ['A_D', new CarMinMax(0, 1500)],
    ['F_G', new CarMinMax(1300, 7000)],
    ['R', new CarMinMax(2000, 7000)],
    ['H', new CarMinMax(1100, 2100)],
    ['K', new CarMinMax(800, 7000)],
    ['N', new CarMinMax(800, 7000)],
    ['L_O', new CarMinMax(0, 400)],
    ['M_P', new CarMinMax(0, 500)],
    ['E', new CarMinMax(900 + 50 + 50, 1600)],  // in fact it's 900 but there are min of A_D also in morphing
    ['B_C', new CarMinMax(100, 1500)],
    ['I_J', new CarMinMax(100, 500)],
]);
export function isMorphTarget(name: string) {
    return MorphTargetsNames.includes(name.split('_').slice(0, -1).join('_') as MorphTargetsName);
}
