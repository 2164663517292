import { httpClient } from '../httpClient/httpClient';

export const glbModelService = {
  getGlbModelList() {
    return httpClient.get('/glb');
  },
  getGlbModel(params: string) {
    return httpClient.get('/glb/' + params);
  },
};
