import React, { useState, useEffect } from 'react';
import { Box, styled } from '@mui/material';
import useAppSelector from '../../hooks/useAppSelector';
import SpinnerAnimated from "./SpinnerAnimated";

const Wrapper = styled(Box)`
    @keyframes lds-spinner {
        0% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }

    position: fixed;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    background: rgba(255, 255, 255, 0.8);
    user-select: none;
`;

const Spinner = () => {
    const accessories = useAppSelector((state) => state.accessories.loading);
    const brand = useAppSelector((state) => state.brand.loading);
    const components = useAppSelector((state) => state.components.loading);
    const glb = useAppSelector((state) => state.glb.loading);
    const image = useAppSelector((state) => state.image.loading);
    const model = useAppSelector((state) => state.model.loading);
    const packages = useAppSelector((state) => state.packagies.loading);
    const presets = useAppSelector((state) => state.presets.loading);
    const user = useAppSelector((state) => state.user.loading);
    const wardrobes = useAppSelector((state) => state.wardrobes.loading);
    const scene = useAppSelector((state) => state.app3d.loading);
    const anyIsLoading = accessories || brand || components || glb || image || model || packages || presets || user || wardrobes || scene;
    const [loading, setLoading] = useState(false);
    // const [progress, setProgress] = useState<number>(100);
    useEffect(() => {
        if (anyIsLoading) setLoading(true);
        else setLoading(false);
    }, [anyIsLoading]);

    // const preloaderProgress = (e : any) => {
    //   setProgress(e.detail.value);
    // }
    // useEffect(() => {
    //   console.log(progress);
    // }, [progress])

    // useEffect(() => {
    //     document.addEventListener('preloaderProgress', preloaderProgress);
    // }, []);

    if (loading) {
        return (
            <Wrapper>
                <SpinnerAnimated />
            </Wrapper>
        );
    }
    return null;
};

export default Spinner;
