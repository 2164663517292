import { createContext } from 'react';

export type LoginState = 'wait' | 'logged' | 'not logged';
export interface IAuthContext {
  authenticated: boolean;
  setAuthenticated: (authenticated: boolean) => void;
  loginState: LoginState;
  token?: string;
  setToken: (token: string) => void;
  setLoginState: (loginState: LoginState) => void;
  login: Function;
  logout: Function;
}

const AuthContext = createContext<IAuthContext>({
  authenticated: false,
  setAuthenticated: (authenticated: boolean) => {},
  loginState: 'wait',
  setLoginState(loginState: LoginState) {},
  setToken(token: string) {},
  login(email: string, password: string) {},
  logout() {},
});

export { AuthContext };
