import React, {useEffect, useMemo, useReducer, useState} from 'react';
import useAppDispatch from '../../../hooks/useAppDispatch';
import useAppSelector from '../../../hooks/useAppSelector';
import { TextField, Box, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import AddBoxIcon from '@mui/icons-material/AddBox';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { getUsers, createUser, updateUser, deleteUser } from '../../../services/store/userSlice';
import NoData from '../../../components/NoData';
import ErrorPopup from "../../../components/ErrorPopup/Index";
import {useError} from "../../../hooks/useError";
import formatErrorMessage from "../../../utils/formatErrorMessage";

const validate = (fields: {
    name: string;
    password: string;
    email: string;
}): string[] => {
    const result: string[] = [];

    if (!fields.name?.trim()) {
        result.push('Name is required. ');
    }

    if (!fields.email?.trim()) {
        result.push('Email is required. ');
    }

    if (!fields.password?.trim()) {
        result.push('Password is required. ');
    } else if (fields.password?.trim().length < 8) {
        result.push('The password must be at least 8 characters. ');
    }

    return result;
}

const ListUsers = () => {
    const dispatch = useAppDispatch();

    //берем список пользователей
    const users = useAppSelector((state) => state.user.users);

    //открытие окна добавления/редактирования
    const [open, setOpen] = useState(false);

    //определяем добавляется или редактируется запись
    const [edit, setEdit] = useState(false);

    //берем/подставляем текущие/новые зачения
    const [fields, setAddFields] = useReducer((curFields: any, newFields: any) => Object.assign(curFields, newFields), {
        name: '',
        id: '',
        email: '',
        password: '',
    });

    const { setErrorMessage, setOpenError } = useError();

    //загружаем список пользователей
    useEffect(() => {
        dispatch(getUsers());
    }, []);

    //столбцы для таблицы
    const columns = useMemo(() => [
        { field: 'name', flex: 1 },
        { field: 'email', flex: 1 },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: (id: any) => {
                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={() => handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={() => handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ], []);

    //открываем/закрываем добавление/редактирование
    const handleOpen = () => {
        setAddFields({ id: null, name: '', email: '' });
        setOpen(true);
        setEdit(false);
    };

    const handleEditClick = (event: any) => {
        handleOpen();
        setEdit(true);
        setAddFields(event.row);
    };

    const handleClose = () => {
        setOpen(false);
    };

    //получаем значения полей
    const handleInput = (event: any) => {
        const name = event.target.name;
        const value = event.target.value;
        setAddFields({ [name]: value });
    };

    //отправляем данные на сервер
    const handleSubmit = (event: any) => {
        event.preventDefault();

        const validateResult = validate(fields);

        if (validateResult.length) {
            setErrorMessage(formatErrorMessage(validateResult));
            setOpenError(true);
            return;
        }

        if (!edit) {
            let createValue = {
                name: fields.name,
                email: fields.email,
                password: fields.password,
            };
            dispatch(createUser(createValue));
        } else {
            dispatch(updateUser(fields));
        }
        handleClose();
    };

    const handleDeleteClick = (id: any) => {
        dispatch(deleteUser(id));
    };

    return users.length > 0 ? (
        <Box p="10px" component="div">
            <Box component="div" style={{ height: 400, width: '100%' }} p="10px">
                <IconButton color="primary" aria-label="upload picture" component="span" onClick={handleOpen}>
                    <AddBoxIcon />
                </IconButton>
                <DataGrid rows={users} columns={columns} editMode="row" />
            </Box>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>User</DialogTitle>
                <form onSubmit={handleSubmit}>
                    <DialogContent
                        style={{
                            gap: '10px',
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            justifyContent: 'flex-start',
                            padding: '20px 85px',
                        }}
                    >
                        <TextField
                            label="User name"
                            name="name"
                            type="text"
                            defaultValue={fields.name}
                            onChange={handleInput}
                        />
                        <TextField
                            label="Email"
                            type="email"
                            name="email"
                            defaultValue={fields.email}
                            onChange={handleInput}
                        />
                        <TextField
                            label="Password"
                            type="password"
                            name="password"
                            defaultValue={fields.password}
                            onChange={handleInput}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type="submit">Send</Button>
                    </DialogActions>
                </form>
            </Dialog>

            <ErrorPopup />
        </Box>
    ) : (
        <Box component="div" display="flex" justifyContent="center">
            <NoData />
        </Box>
    );
};

export { ListUsers };
