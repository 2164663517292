import { createTheme } from "@mui/material";

const defaultTheme = createTheme({
  palette: {
    primary:{
      main: '#00365F',
    },
    secondary:{
      main: '#66869F'
    },
  },

  breakpoints:{
    values:{
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1440,
      xl: 1920,
    }
  }
});
export {
  defaultTheme
}