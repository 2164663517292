import React, { SyntheticEvent, useEffect, useReducer, useState } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import useAppDispatch from '../../../hooks/useAppDispatch';
import useAppSelector from '../../../hooks/useAppSelector';
import { RootState } from '../../../services/store';
import {
    getModelPackagies,
    createPackagies,
    updatePackagies,
    deletePackagies,
} from '../../../services/store/packagieSlice';
import { getModel } from '../../../services/store/modelSlice';
import IconButton from '@mui/material/IconButton';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    TextField,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    styled,
    Checkbox,
} from '@mui/material';
import NumberFormat from 'react-number-format';
import PackagesImg from './Packages.png';
import { useError } from '../../../hooks/useError';
import ErrorPopup from '../../../components/ErrorPopup/Index';

type TFieldName = 'name' | 'a' | 'b' | 'c' | 'd' | 'e' | 'f' | 'g' | 'h' | 'i' | 'j' | 'k' | 'l' | 'm' | 'n' | 'o' | 'p' | 'r';

const validateDefault = {
    name: false,
    a: false,
    b: false,
    c: false,
    d: false,
    e: false,
    f: false,
    g: false,
    h: false,
    i: false,
    j: false,
    k: false,
    l: false,
    m: false,
    n: false,
    o: false,
    p: false,
    r: false,
};

type TValidate = {
    [key in TFieldName]: boolean;
};

type TPartialValidate = Partial<TValidate>;

const ListPackagies = () => {
    const { id } = useParams<{
        id: string;
    }>();
    const dispatch = useAppDispatch();
    const history = useHistory();
    const model = useAppSelector((state) => state.model.modelnfo);
    const packagies = useAppSelector((state: RootState) => state.packagies.modelPackagies);
    const error = useAppSelector((state) => state.packagies.error);
    const { setOpenError, setErrorMessage } = useError();
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [dialogOpen, changeDialogOpen] = useState(false);
    const [validate, setValidate] = useReducer(
      (curFields: TValidate, newFields: TPartialValidate) => ({ ...curFields, ...newFields }),
      validateDefault
    );
    const [dialogFields, changeDialogFields] = useReducer(
        (curFields: any, newFields: any) => ({ ...curFields, ...newFields }),
        {}
    );
    const [previewImage, setPreviewImage] = useState('');
    const clearObject = () => {
        Object.keys(dialogFields).forEach((key) => {
            delete dialogFields[key];
        });
        changeDialogFields(dialogFields);
    };

    useEffect(() => {
        dispatch(getModel(id));
        dispatch(getModelPackagies(id));
    }, [id]);

    useEffect(() => {
        if (error) {
            setOpenError(true);
            setErrorMessage('Check value limits');
        }
    }, [error]);

    let columns = [
        { field: 'name', flex: 2 },
        {
            field: 'image',
            name: 'image',
            flex: 1,
            renderCell: (params: any) => {
                return !!params.value ? (
                    <>
                        <Box
                            style={{
                                maxHeight: '50px',
                                maxWidth: '50px',
                            }}
                            component="img"
                            src={params.value}
                        />
                    </>
                ) : (
                    <>-</>
                );
            },
        },
        {
            field: 'double_door',
            name: 'double_door',
            flex: 2,
            renderCell: (params: any) => {
                return (
                    <>
                        <Checkbox name="double_door" checked={params.value} disabled={true} />
                    </>
                );
            },
        },
        { field: 'a', flex: 1 },
        { field: 'b', flex: 1 },
        { field: 'c', flex: 1 },
        { field: 'd', flex: 1 },
        { field: 'e', flex: 1 },
        { field: 'f', flex: 1 },
        { field: 'g', flex: 1 },
        { field: 'h', flex: 1 },
        { field: 'i', flex: 1 },
        { field: 'j', flex: 1 },
        { field: 'k', flex: 1 },
        { field: 'l', flex: 1 },
        { field: 'm', flex: 1 },
        { field: 'n', flex: 1 },
        { field: 'o', flex: 1 },
        { field: 'p', flex: 1 },
        { field: 'r', flex: 1 },
        { field: 's', flex: 1 },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: (id: any) => {
                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={() => {
                            handleEditClick(id);
                        }}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={() => {
                            handleDeleteClick(id);
                        }}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    const handleAddClick = (event: any) => {
        changeDialogFields({ model_car_id: id });
        setSubmitDisabled(true);
        buttonDisabled.bind(this);
        changeDialogOpen(true);
    };

    const handleEditClick = (event: any) => {
        changeDialogFields(event.row);
        setSubmitDisabled(false);
        changeDialogOpen(true);
    };

    const handleDeleteClick = (event: any) => {
        if (window.confirm('Delete ' + event.row.name + '?')) {
            dispatch(deletePackagies(event.row));
        }
    };

    const handleInput = (event?: any) => {
        const validateObj: TPartialValidate = {};
        const name: TFieldName = event.target.name;

        if (event.target.name === 'double_door') {
            changeDialogFields({ [event.target.name]: event.target.checked });
        } else {
            if (name === 'name') {
                const value = event.target.value?.trim();
                validateObj[name] = !value;
            }
            if (name === 'a') {
                validateObj[name] = event.target.value < 0 || event.target.value > 1500;
            }
            if (name === 'b') {
                validateObj[name] = event.target.value < 100 || event.target.value > 1500;
            }
            if (name === 'c') {
                validateObj[name] = event.target.value < 100 || event.target.value > 1500;
            }
            if (name === 'd') {
                validateObj[name] = event.target.value < 0 || event.target.value > 1500;
            }
            if (name === 'e') {
                validateObj[name] = event.target.value < 900 || event.target.value > 1600;
            }
            if (name === 'f') {
                validateObj[name] = event.target.value < 500 || event.target.value > 6900;
            }
            if (name === 'g') {
                validateObj[name] = event.target.value < 500 || event.target.value > 6900;
            }
            if (name === 'h') {
                validateObj[name] = event.target.value < 1100 || event.target.value > 2100;
            }
            if (name === 'i') {
                validateObj[name] = event.target.value < 100 || event.target.value > 500;
            }
            if (name === 'j') {
                validateObj[name] = event.target.value < 100 || event.target.value > 500;
            }
            if (name === 'k') {
                validateObj[name] = event.target.value < 500 || event.target.value > 6900;
            }
            if (name === 'l') {
                validateObj[name] = event.target.value > 400;
            }
            if (name === 'm') {
                validateObj[name] = event.target.value > 500;
            }
            if (name === 'n') {
                validateObj[name] = event.target.value < 500 || event.target.value > 6900;
            }
            if (name === 'o') {
                validateObj[name] = event.target.value > 400;
            }
            if (name === 'p') {
                validateObj[name] = event.target.value > 500;
            }
            if (name === 'r') {
                validateObj[name] = event.target.value < 2000 || event.target.value > 7000;
            }
            changeDialogFields({ [name]: event.target.value });
        }

        setValidate(validateObj);
    };

    useEffect(() => {
        buttonDisabled(validate);
    }, [validate]);

    const onFileChange = (event: any) => {
        setPreviewImage(URL.createObjectURL(event.target.files[0]));
        changeDialogFields({
            imageFile: event.target.files[0],
            image: event.target.files[0]?.name,
        });
    };

    const calculate = () => {
        dialogFields['d'] = dialogFields['a'];
        dialogFields['g'] = dialogFields['f'];
        dialogFields['j'] = dialogFields['i'];
        if (Number(dialogFields['r']) - Number(dialogFields['f']) < Number(dialogFields['b'])) {
            dialogFields['b'] = Number(dialogFields['r']) - Number(dialogFields['f']);
            dialogFields['c'] = dialogFields['b'];
            dialogFields['f'] = Number(dialogFields['r']) - Number(dialogFields['b']);
            dialogFields['g'] = dialogFields['f'];
        }
        dialogFields['c'] = dialogFields['b'];
        if (Number(dialogFields['k']) + Number(dialogFields['l']) > Number(dialogFields['f'])) {
            dialogFields['k'] = (Number(dialogFields['f']) - Number(dialogFields['l'])).toString();
        }
        if (Number(dialogFields['k']) + Number(dialogFields['l']) > Number(dialogFields['f'])) {
            dialogFields['l'] = (Number(dialogFields['f']) - Number(dialogFields['k'])).toString();
        }
        dialogFields['o'] = dialogFields['l'];
        if (Number(dialogFields['n']) + Number(dialogFields['o']) > Number(dialogFields['g'])) {
            dialogFields['n'] = (Number(dialogFields['g']) - Number(dialogFields['o'])).toString();
        }
        dialogFields['p'] = dialogFields['m'];
        if (Number(dialogFields['r']) - Number(dialogFields['g']) < Number(dialogFields['c'])) {
            dialogFields['r'] = (Number(dialogFields['c']) + Number(dialogFields['g'])).toString();
        }
        if (Number(dialogFields['r']) - Number(dialogFields['f']) < Number(dialogFields['b'])) {
            dialogFields['r'] = (Number(dialogFields['b']) + Number(dialogFields['f'])).toString();
        }
        dialogFields['s'] = (
            Number(dialogFields['r']) -
            Number(dialogFields['k']) -
            Number(dialogFields['l'])
        ).toString();

        changeDialogFields(dialogFields);
        setSubmitDisabled(false);
    };

    const buttonDisabled = (validateObj: TValidate) => {
        let isSubmitDisabled = false;

        for (const name in validateObj) {
            if (validateObj[name as TFieldName]) {
                isSubmitDisabled = true;
            }
        }
        setSubmitDisabled(isSubmitDisabled);
    };

    const handleSubmit = (event: SyntheticEvent) => {
        event.preventDefault();
        if (!!dialogFields.id) {
            dispatch(updatePackagies({ dialogFields, id }));
        } else {
            dispatch(createPackagies({ dialogFields }));
        }
        handleCloseDialog();
    };

    const handleCloseDialog = (event?: any) => {
        clearObject();
        setValidate(validateDefault);
        changeDialogOpen(false);
    };

    return (
        model && (
            <Box p="10px">
                <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    onClick={() =>
                        history.push(
                            generatePath('/admin/brands/models/:id', {
                                id: model.brand_car_id,
                            })
                        )
                    }
                >
                    <ArrowBackIcon />
                </IconButton>
                <Box m="10px">{'Model: ' + model.name}</Box>
                <Box style={{ height: 400, width: '100%' }}>
                    <IconButton color="primary" aria-label="upload picture" component="span" onClick={handleAddClick}>
                        <AddBoxIcon />
                    </IconButton>
                    <DataGrid rows={packagies} columns={columns} editMode="row" />
                </Box>
                <Dialog open={dialogOpen} onClose={handleCloseDialog} fullScreen>
                    <DialogTitle>{'Packages'}</DialogTitle>
                    <form onSubmit={handleSubmit}>
                        <StyledDialogContent>
                            <Box>
                                <Box component="img" src={PackagesImg} />
                            </Box>
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Box
                                    component="div"
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        flexWrap: 'wrap',
                                        gap: '10px',
                                    }}
                                >
                                    <TextField
                                        label="Name"
                                        name="name"
                                        type="text"
                                        defaultValue={dialogFields.name}
                                        onChange={handleInput}
                                        error={validate.name}
                                    />
                                    <NumberFormat
                                        customInput={TextField}
                                        value={dialogFields['a']}
                                        decimalScale={3}
                                        thousandSeparator={false}
                                        fixedDecimalScale={false}
                                        name="a"
                                        label="A"
                                        onChange={handleInput}
                                        error={validate.a}
                                        helperText={'min: 0, max: 1500'}
                                    />
                                    <NumberFormat
                                        customInput={TextField}
                                        value={dialogFields.b}
                                        decimalScale={3}
                                        thousandSeparator={false}
                                        fixedDecimalScale={false}
                                        name="b"
                                        label="B"
                                        onChange={handleInput}
                                        error={validate.b}
                                        helperText={'min: 100, max: 1500'}
                                    />
                                    <NumberFormat
                                        customInput={TextField}
                                        value={dialogFields.e}
                                        decimalScale={3}
                                        thousandSeparator={false}
                                        fixedDecimalScale={false}
                                        name="e"
                                        label="E"
                                        onChange={handleInput}
                                        error={validate.e}
                                        helperText={'min: 900, max: 1600'}
                                    />
                                    <NumberFormat
                                        customInput={TextField}
                                        value={dialogFields.f}
                                        decimalScale={3}
                                        thousandSeparator={false}
                                        fixedDecimalScale={false}
                                        name="f"
                                        label="F"
                                        onChange={handleInput}
                                        error={validate.f}
                                        helperText={'min: 500, max: 6900'}
                                    />
                                    <NumberFormat
                                        customInput={TextField}
                                        value={dialogFields.h}
                                        decimalScale={3}
                                        thousandSeparator={false}
                                        fixedDecimalScale={false}
                                        name="h"
                                        label="H"
                                        onChange={handleInput}
                                        error={validate.h}
                                        helperText={'min: 1100, max: 2100'}
                                    />
                                    <NumberFormat
                                        customInput={TextField}
                                        value={dialogFields.i}
                                        decimalScale={3}
                                        thousandSeparator={false}
                                        fixedDecimalScale={false}
                                        name="i"
                                        label="I"
                                        onChange={handleInput}
                                        error={validate.i}
                                        helperText={'min: 100, max: 500'}
                                    />
                                    <NumberFormat
                                        customInput={TextField}
                                        value={dialogFields.k}
                                        decimalScale={3}
                                        thousandSeparator={false}
                                        fixedDecimalScale={false}
                                        name="k"
                                        label="K"
                                        onChange={handleInput}
                                        error={validate.k}
                                        helperText={'min: 500, max: 6900'}
                                    />
                                    <NumberFormat
                                        customInput={TextField}
                                        value={dialogFields.l}
                                        decimalScale={3}
                                        thousandSeparator={false}
                                        fixedDecimalScale={false}
                                        name="l"
                                        label="L"
                                        onChange={handleInput}
                                        error={validate.l}
                                        helperText={'min: 0, max: 400'}
                                    />
                                    <NumberFormat
                                        customInput={TextField}
                                        value={dialogFields.m}
                                        decimalScale={3}
                                        thousandSeparator={false}
                                        fixedDecimalScale={false}
                                        name="m"
                                        label="M"
                                        onChange={handleInput}
                                        error={validate.m}
                                        helperText={'min: 0, max: 500'}
                                    />
                                    <NumberFormat
                                        customInput={TextField}
                                        value={dialogFields.n}
                                        decimalScale={3}
                                        thousandSeparator={false}
                                        fixedDecimalScale={false}
                                        name="n"
                                        label="N"
                                        onChange={handleInput}
                                        error={validate.n}
                                        helperText={'min: 500, max: 6900'}
                                    />
                                    <NumberFormat
                                        customInput={TextField}
                                        value={dialogFields.r}
                                        decimalScale={3}
                                        thousandSeparator={false}
                                        fixedDecimalScale={false}
                                        name="r"
                                        label="R"
                                        onChange={handleInput}
                                        error={validate.r}
                                        helperText={'min: 2000, max: 7000'}
                                    />
                                    <Box component="div" display="flex" flexDirection="column">
                                        Double door
                                        <Checkbox
                                            name="double_door"
                                            value={dialogFields.double_door}
                                            onChange={handleInput}
                                            checked={dialogFields.double_door || false}
                                        />
                                    </Box>
                                </Box>
                                Calculate params
                                <Box
                                    component="div"
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        flexWrap: 'wrap',
                                        gap: '10px',
                                    }}
                                >
                                    <NumberFormat
                                        customInput={TextField}
                                        value={dialogFields.c}
                                        decimalScale={3}
                                        thousandSeparator={false}
                                        fixedDecimalScale={false}
                                        name="c"
                                        label="C"
                                        onChange={handleInput}
                                        error={validate.c}
                                        helperText={'min: 100, max: 1500'}
                                        disabled={true}
                                    />
                                    <NumberFormat
                                        customInput={TextField}
                                        value={dialogFields.d}
                                        decimalScale={3}
                                        thousandSeparator={false}
                                        fixedDecimalScale={false}
                                        name="d"
                                        label="D"
                                        onChange={handleInput}
                                        error={validate.d}
                                        helperText={'min: 0, max: 1500'}
                                        disabled={true}
                                    />

                                    <NumberFormat
                                        customInput={TextField}
                                        value={dialogFields.g}
                                        decimalScale={3}
                                        thousandSeparator={false}
                                        fixedDecimalScale={false}
                                        name="g"
                                        label="G"
                                        onChange={handleInput}
                                        error={validate.g}
                                        helperText={'min: 500, max: 6900'}
                                        disabled={true}
                                    />

                                    <NumberFormat
                                        customInput={TextField}
                                        value={dialogFields.j}
                                        decimalScale={3}
                                        thousandSeparator={false}
                                        fixedDecimalScale={false}
                                        name="j"
                                        label="J"
                                        onChange={handleInput}
                                        error={validate.j}
                                        helperText={'min: 100, max: 500'}
                                        disabled={true}
                                    />

                                    <NumberFormat
                                        customInput={TextField}
                                        value={dialogFields.o}
                                        decimalScale={3}
                                        thousandSeparator={false}
                                        fixedDecimalScale={false}
                                        name="o"
                                        label="O"
                                        onChange={handleInput}
                                        error={validate.o}
                                        helperText={'min: 0, max: 400'}
                                        disabled={true}
                                    />
                                    <NumberFormat
                                        customInput={TextField}
                                        value={dialogFields.p}
                                        decimalScale={3}
                                        thousandSeparator={false}
                                        fixedDecimalScale={false}
                                        name="p"
                                        label="P"
                                        onChange={handleInput}
                                        error={validate.p}
                                        helperText={'min: 0, max: 500'}
                                        disabled={true}
                                    />

                                    <NumberFormat
                                        customInput={TextField}
                                        value={dialogFields.s}
                                        decimalScale={3}
                                        thousandSeparator={false}
                                        fixedDecimalScale={false}
                                        name="s"
                                        label="S"
                                        onChange={handleInput}
                                        disabled={true}
                                    />
                                </Box>
                                <img className="previewImage" src={previewImage} alt="" />
                            </Box>
                        </StyledDialogContent>

                        <StyledDialogActions>
                            <Button variant="contained" component="label">
                                Upload File
                                <input type="file" hidden onChange={onFileChange} />
                            </Button>
                            <div>
                                <Button onClick={handleCloseDialog}>Cancel</Button>
                                <Button onClick={calculate}> Calculate</Button>
                                <Button type="submit" disabled={submitDisabled}>
                                    Send
                                </Button>
                            </div>
                        </StyledDialogActions>
                    </form>
                </Dialog>
                <ErrorPopup />
            </Box>
        )
    );
};

export { ListPackagies };

const StyledDialogActions = styled(DialogActions)`
    justify-content: space-between;
`;

const StyledDialogContent = styled(DialogContent)`
    display: flex;
    flex-direction: row;

    justify-content: center;
`;
