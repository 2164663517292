import React, { useEffect, useState } from 'react';
import { List, ListItem, styled, Box, Collapse } from '@mui/material';
import StepPanel from '../../components/StepPanel';
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';
import { getBrandsAll, selectStatus } from '../../services/store/brandSlice';
import { getBrandModels, selectBrandModels } from '../../services/store/modelSlice';
import { getModelPackagies, selectModelPackagies } from '../../services/store/packagieSlice';
import CarIcon from '../../icons/CarIcon.svg';
import PackagesIcon from '../../icons/PackagesIcon.svg';
import { generatePath, useHistory } from 'react-router-dom';
import NoData from '../../components/NoData';
import { useTranslation } from 'react-i18next';
import { useHelp } from '../../hooks/useHelp';
import { setShow } from '../../services/store/app3dSlice';

const Vehicle = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    useEffect(() => {
        dispatch(setShow(false));
        return () => {
            dispatch(setShow(true));
        };
    }, [dispatch]);
    const brands = useAppSelector((state) => state.brand.brandsAll);
    const filteredBrands = brands.length > 0 ? brands.filter((i) => i.image != null) : [];
    const models = useAppSelector(selectBrandModels);
    const packages = useAppSelector(selectModelPackagies);
    const status = useAppSelector(selectStatus);
    const [activeBrand, setActiveBrand] = useState<number>();
    const { setBrand, setModel, setPackagesModel, setPackagesId } = useHelp();
    const [activeModel, setActiveModel] = useState<number>();
    const [open, setOpen] = useState(false);
    const [visible, setVisible] = useState(false);
    const history = useHistory();

    useEffect(() => {
        dispatch(getBrandsAll());
        setBrand('');
        setModel('');
        setPackagesModel('');
    }, [dispatch, setBrand, setModel, setPackagesModel]);

    useEffect(() => {
        if (models.length === 0) {
            setOpen(false);
            setModel('');
            setPackagesModel('');
        }
    }, [models, setModel, setPackagesModel]);

    const selectedBrand = (id: string, name: string, index: number) => {
        setActiveBrand(index);
        setBrand(name);
        dispatch(getBrandModels(id));
        setOpen(true);
        setModel('');
        setPackagesModel('');
        setVisible(false);
    };

    const selectedModel = (id: string, name: string, index: number) => {
        setActiveModel(index);
        setModel(name);
        if (models.length > 0) {
            dispatch(getModelPackagies(id));
            setVisible(true);
        }
    };

    const selectedPackages = (id: string, name: string, index: number) => {
        setPackagesModel(name);
        setPackagesId(id);
        history.push(
            generatePath('/configurator/:packagesId/equip-list', {
                packagesId: id,
            })
        );
    };

    const witdhRearDoor = (item: any) => {
        return Number(item.a) + Number(item.e) + Number(item.d);
    };

    return brands.length > 0 ? (
        <Box component="div">
            <StepPanel step={0} />
            <Box
                component="div"
                display="flex"
                flexDirection="row"
                margin="0px"
                sx={{
                    maxHeight: {
                        xl: '81vh',
                        lg: '81vh',
                        md: '85vh',
                        sm: '85vh',
                    },
                }}
                style={{
                    background: '#F2F5F7',
                }}
            >
                <Box component="div" display="flex" flexDirection="row" flex={1}>
                    <BrandList>
                        {filteredBrands.map((item, index) => (
                            <ListItem
                                key={index}
                                role="listitem"
                                className={activeBrand === index ? 'selected item' : 'item'}
                                button
                                onClick={() => selectedBrand(item.id, item.name, index)}
                            >
                                <Box
                                    component="img"
                                    alt=""
                                    src={item.image}
                                    sx={{
                                        height: {
                                            xl: '70px',
                                            lg: '70px',
                                            md: '40px',
                                            sm: '40px',
                                        },
                                        width: {
                                            xl: '70px',
                                            lg: '70px',
                                            md: '40px',
                                            sm: '40px',
                                        },
                                    }}
                                />
                            </ListItem>
                        ))}
                    </BrandList>
                    {models.length > 0 ? (
                        <Box component="div" position="relative" left="-20px">
                            <StyledCollapse
                                in={open}
                                unmountOnExit
                                orientation="horizontal"
                                sx={{
                                    minHeight: {
                                        xl: '80vh',
                                        lg: '80vh',
                                        md: '84vh',
                                        sm: '85vh',
                                    },
                                    width: {
                                        xl: '710px',
                                        lg: '509px',
                                        mg: '370px',
                                        sm: '200px',
                                    },
                                }}
                            >
                                <Box
                                    component="div"
                                    sx={{
                                        padding: {
                                            xl: '24px 53px 24px 66px',
                                            lg: '24px 53px 24px 66px',
                                            md: '24px 33px 24px 37px',
                                            sm: '24px 33px 24px 33px',
                                        },
                                    }}
                                >
                                    <StyledTitle>{t('Vehicle.titleModel')}</StyledTitle>
                                    <ModelList>
                                        {models.map((item, index) => (
                                            <Model
                                                key={index}
                                                role="listitem"
                                                className={activeModel === index ? 'selected item' : 'item'}
                                                onClick={() => selectedModel(item.id, item.name, index)}
                                            >
                                                <Box
                                                    component="img"
                                                    alt=""
                                                    src={item.image ? item.image : CarIcon}
                                                    sx={{
                                                        height: {
                                                            xl: '100px',
                                                            lg: '100px',
                                                            md: '60px',
                                                            sm: '60px',
                                                        },
                                                        width: {
                                                            xl: '143px',
                                                            lg: '143px',
                                                            md: '86px',
                                                            sm: '86px',
                                                        },
                                                    }}
                                                />
                                                <StyledModelItemTitle>{item.name}</StyledModelItemTitle>
                                            </Model>
                                        ))}
                                    </ModelList>
                                </Box>
                            </StyledCollapse>
                        </Box>
                    ) : (
                        <></>
                    )}
                </Box>
                {models.length > 0 && visible && packages.length > 0 ? (
                    <Box
                        component="div"
                        width="100%"
                        sx={{
                            padding: {
                                xl: '24px 40px 0px 40px',
                                md: '24px 56px 0px 24px',
                                sm: '24px 24px 0px 24px',
                            },
                        }}
                        style={{ overflow: 'hidden', overflowY: 'scroll' }}
                    >
                        <StyledTitle>{t('Vehicle.titlePackage')}</StyledTitle>
                        <Box
                            component="div"
                            display="flex"
                            flexDirection="row"
                            flexWrap="wrap"
                            gap="24px"
                            sx={{
                                maxHeight: {
                                    xl: '81vh',
                                    lg: '81vh',
                                    md: '85vh',
                                    sm: '85vh',
                                },
                            }}
                        >
                            {packages.map((item, index) => (
                                <StyledPackageItemCard
                                    key={index}
                                    onClick={() => selectedPackages(item.id, item.name, index)}
                                >
                                    <Box
                                        component="img"
                                        alt=""
                                        src={item.image ? item.image : PackagesIcon}
                                        sx={{
                                            height: {
                                                xl: '80px',
                                                lg: '80px',
                                                md: '80px',
                                                sm: '109px',
                                            },
                                            width: {
                                                xl: '144px',
                                                lg: '144px',
                                                md: '177px',
                                                sm: '177px',
                                            },
                                        }}
                                    />
                                    <Box component="div" ml="8px">
                                        <StyledPackageItemTitle>{item.name}</StyledPackageItemTitle>
                                        <Box component="div">
                                            <StyledPackageText>
                                                {t('Vehicle.cardPackage.cardPackageSubTitle')}
                                            </StyledPackageText>
                                            <StyledPackageItemText>
                                                {t('Vehicle.cardPackage.cardPackageLength') + ': ' + item.r}
                                            </StyledPackageItemText>
                                            <StyledPackageItemText>
                                                {t('Vehicle.cardPackage.cardPackageHeight') + ': ' + item.h}
                                            </StyledPackageItemText>
                                            <StyledPackageItemText>
                                                {t('Vehicle.cardPackage.cardPackageWRD') + ': ' + witdhRearDoor(item)}
                                            </StyledPackageItemText>
                                            <StyledPackageItemText>
                                                {t('Vehicle.cardPackage.cardPackageWSD') + ': ' + item.n}
                                            </StyledPackageItemText>
                                        </Box>
                                    </Box>
                                </StyledPackageItemCard>
                            ))}
                        </Box>
                    </Box>
                ) : (
                    <Box component="div" flex={1} alignItems="center" display="flex" justifyItems="center">
                        {!open ? (
                            <StyledText>{t('Vehicle.helpBrand')}</StyledText>
                        ) : (
                            <StyledText>{t('Vehicle.helpModel')}</StyledText>
                        )}
                    </Box>
                )}
            </Box>
        </Box>
    ) : (
        <Box component="div" display="flex" justifyContent="center">
            <NoData item={status} />
        </Box>
    );
};

const BrandList = styled(List)(({ theme }) => ({
    width: '120px',
    background: '#FFFFFF',
    minHeight: '80vh',
    zIndex: '3',
    boxShadow: '5px 3px 10px -5px rgba(0, 54, 95, 0.15)',
    borderRadius: '0px 30px 30px 0px',
    display: 'flex',
    padding: '10px 0 0 0',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: '10px',
    '.item': {
        flexDirection: 'column',
        padding: '0px',
        opacity: '0.5',
        '&:not(selected)': {
            '&:hover': {
                transition: '0,5s',
                transfrom: 'scale(1.1)',
            },
        },
    },
    '.selected': {
        '& > img': {
            width: '100px',
            height: '100px',
        },
        opacity: '1',
    },
    [theme.breakpoints.down('lg')]: {
        width: '80px',
        minHeight: '84vh',
        '.selected': {
            '& > img': {
                width: '64px',
                height: '64px',
            },
        },
    },
    [theme.breakpoints.down('md')]: {
        minHeight: '85vh',
    },
}));

const StyledText = styled(Box)(({ theme }) => ({
    height: '56px',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '48px',
    lineHeight: '56px',
    textTransform: 'uppercase',
    color: 'rgba(102, 134, 159, 0.5)',
    [theme.breakpoints.down('lg')]: {
        fontSize: '32px',
        lineHeight: '38px',
    },
}));

const StyledCollapse = styled(Collapse)(({ theme }) => ({
    background: '#FFFFFF',
    boxShadow: '5px 3px 10px -5px rgba(0, 54, 95, 0.15)',
    borderRadius: '0px 30px 30px 0px',
}));

const ModelList = styled(List)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '32px',
    padding: '0px',
}));

const Model = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '0px',
    width: '140px',
    [theme.breakpoints.down('lg')]: {
        width: '120px',
    },
}));

const StyledTitle = styled(Box)(({ theme }) => ({
    marginBottom: '32px',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '36px',
    lineHeight: '40px',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('lg')]: {
        fontSize: '30px',
        lineHeight: '32px',
    },
}));

const StyledModelItemTitle = styled(Box)(({ theme }) => ({
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '21px',
    textAlign: 'center',
    color: theme.palette.primary.main,
}));

const StyledPackageItemCard = styled(Box)(({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    padding: '16px',
    width: '435px',
    border: '1px solid rgba(0, 54, 95, 0.01)',
    boxSizing: 'border-box',
    boxShadow: '1px 2px 7px rgba(0, 54, 95, 0.15)',
    borderRadius: '14px',
    background: '#FFFFFF',
    [theme.breakpoints.down('xl')]: {
        flexDirection: 'column',
        width: '325px',
    },
    [theme.breakpoints.down('lg')]: {
        flexDirection: 'row',
        width: '100%',
        justifyItems: 'flex-start',
        gap: '48px',
    },
    [theme.breakpoints.down('md')]: {
        width: '100%',
        gap: '24px',
        paddingLeft: '44px',
    },
}));

const StyledPackageItemTitle = styled(Box)(({ theme }) => ({
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '28px',
    marginBottom: '8px',
    color: theme.palette.primary.main,
}));

const StyledPackageText = styled(Box)(({ theme }) => ({
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#17262e',
    opacity: '0.6',
}));

const StyledPackageItemText = styled(Box)(({ theme }) => ({
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#b3c3cf',
}));

export default Vehicle;
