import { createContext } from "react";

export interface IErrorContext {
    errorMessage?: string;
    setErrorMessage: (message: string) => void;
    openError?: boolean;
    setOpenError: (error: boolean) => void;
}

const ErrorContext = createContext<IErrorContext>({
    setErrorMessage(message: string) {},
    setOpenError(error: boolean) {},
 });

 export {ErrorContext};