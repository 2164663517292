import React from 'react';
import { Box, Button, Card, Container, FormControl, styled, TextField } from '@mui/material';
import { Redirect } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { SubmitHandler, useForm } from 'react-hook-form';
import Logo from '../../icons/Logo.svg';
import { useTranslation } from 'react-i18next';
import useAppSelector from '../../hooks/useAppSelector';

type FormValues = {
    email: string;
    password: string;
};

const Login = () => {
    const { authenticated, login } = useAuth();
    const { t } = useTranslation();
    const { register, handleSubmit } = useForm<FormValues>();
    const error = useAppSelector((state) => state.user.error);
    const loading = useAppSelector((state) => state.user.loading);
    //проверка авторизованности пользователя
    if (authenticated) {
        return <Redirect to="/configurator" />;
    }
    if (loading) {
        return null;
    }
    //отправляем данные с формы логина
    const onSubmit: SubmitHandler<FormValues> = (data) => {
        login(data.email, data.password);
    };

    return (
        <>
            <Box
                component="div"
                sx={{
                    height: '100%',
                }}
            >
                <Container>
                    <Box
                        component="div"
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        marginTop="60px"
                    >
                        <Box component="div" marginBottom="40px">
                            <img src={Logo} alt="" />
                        </Box>
                        <Box component="div" fontSize="64px">
                            Raaco VanConfig
                        </Box>
                        <Box
                            component="div"
                            m="40px auto"
                            boxShadow="0px 4px 48px rgba(0, 0, 0, 0.1)"
                            borderRadius="36px"
                            style={{
                                minWidth: '500px',
                            }}
                        >
                            <Card
                                style={{
                                    borderRadius: '14px',
                                    padding: '30px',
                                }}
                            >
                                <Box component="div">
                                    <StyledTitle>{t('login.title')}</StyledTitle>
                                    <StyledSubTitle>{t('login.subTitle')}</StyledSubTitle>
                                </Box>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <FormControl
                                        fullWidth
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '10px',
                                            marginBottom: '30px',
                                        }}
                                    >
                                        <TextField
                                            label={t('login.emailInput.nameInput')}
                                            placeholder={t('login.emailInput.placaholderInput')}
                                            type="text"
                                            {...register('email')}
                                        />
                                        <TextField
                                            label={t('login.passwordInput.nameInput')}
                                            placeholder={t('login.passwordInput.placeholderInput')}
                                            type="password"
                                            {...register('password')}
                                        />
                                        {error ? <ErrorText>{error}</ErrorText> : <></>}
                                    </FormControl>
                                    <Box
                                        component="div"
                                        mt="10px"
                                        display="flex"
                                        flexDirection="column"
                                        alignItems="center"
                                    >
                                        <StyledButtom type="submit">Log in</StyledButtom>
                                        <StyledLink>{t('login.footerText')}</StyledLink>
                                    </Box>
                                </form>
                            </Card>
                        </Box>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

const StyledTitle = styled(Box)`
    margin-bottom: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 24px;
    color: ${(props) => props.theme.palette.primary.main};
    align-items: center;
    letter-spacing: 0.5px;
`;

const StyledSubTitle = styled(Box)`
    margin-bottom: 40px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${(props) => props.theme.palette.primary.main};
    align-items: center;
    letter-spacing: 0.5px;
    opacity: 0.6;
`;

const StyledButtom = styled(Button)`
    background-color: ${(props) => props.theme.palette.primary.main};
    color: #ffffff;
    border-radius: 14px;
    width: 100%;
    margin-bottom: 10px;
`;

const StyledLink = styled(Box)`
    cursor: pointer;
    color: ${(props) => props.theme.palette.primary.main};
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.5px;
    opacity: 0.6;
`;

const ErrorText = styled(Box)(({ theme }) => ({
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    color: '#dd2b0e',
}));
export default Login;
