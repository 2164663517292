import * as THREE from "three";
import { Place, View, Entity, ButtonEntity } from "../components/Scene/Place";
import SceneWrapper from "./Scene/SceneWrapper";
import { ResourceManager } from "../components/Scene/ResourceManager/ResourceManager";
import { Car, CarParams } from "../components/Scene/CarStorage";
import {
  CarMeshName,
  isMorphTarget,
  CarMinMaxPerTarget,
  Default,
} from "../consts/scene";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import EventEmitter from "eventemitter3";
import {
  Component,
  isRackComponent,
  isShelfComponent,
  ShelfComponent,
} from "../types/component";
import { RequestParams, ResourceType } from "../types/resources";
import defaultCarParams from "./car_config.json";
import { Packagies } from "../services/store/packagieSlice";
import controlsState from "./Scene/SceneStates/ControlsStates";
import cameraState from "./Scene/SceneStates/CameraStates";
import rendererState from "./Scene/SceneStates/RendererStates";
import directionalLight from "./Scene/LightParams/directionalLight";
import ambientLight from "./Scene/LightParams/ambientLight";
import { Shelf, ShelfEntity } from "../components/Scene/Shelf";
import CarEntity from "../components/Scene/CarEntity";
import { EventProvider, SceneEventsMap } from "../services/api/app3d";
import { positionToSide } from "../utils/component";
import { ApiPath, ImageEntity } from "../types/app3d";
import { App3dEvent, isNotDragableShelf } from "../types/scene";
import { Side } from "../types/general";
import { Mode } from "../types/scene";
import { findDeltaXOfOptimalCameraPosition } from "./helpers/optimalCameraDistance";
import { getShelfType } from "../components/Scene/helpers";
const { car: defaultParams } = defaultCarParams;
function dumbCarInitializer(resource: GLTF, carParams: Packagies): CarEntity {
  const carStorage = new Car();
  carStorage.setCarSizes(defaultParams as CarParams);
  carStorage.setCarSizes(carParams as CarParams);
  const { params } = carStorage;
  function getMinOrZero(name: string) {
    let param = CarMinMaxPerTarget.get(name);
    if (param) return param.min;
    return 0;
  }
  function setCarParams() {
    let carParamsStrings = [];
    let carParamsValues = [];

    const bDiff = params.b - getMinOrZero("B_C");
    const rDiff = params.r - bDiff - getMinOrZero("R");
    carParamsStrings.push("A_D");
    carParamsValues.push(params.a - getMinOrZero("A_D"));
    carParamsStrings.push("B_C");
    carParamsValues.push(bDiff);
    carParamsStrings.push("I_J");
    carParamsValues.push(params.i - getMinOrZero("I_J"));
    carParamsStrings.push("E");
    carParamsValues.push((params.e - getMinOrZero("E")) / 2 + params.a);
    carParamsStrings.push("H");
    carParamsValues.push(params.h - getMinOrZero("H"));
    carParamsStrings.push("R");
    carParamsValues.push(rDiff);
    carParamsStrings.push("N");
    carParamsValues.push(-(getMinOrZero("N") + rDiff - params.n));
    carParamsStrings.push("K");
    carParamsValues.push(-(getMinOrZero("K") + rDiff - params.k));
    carParamsStrings.push("F_G");
    carParamsValues.push(-(getMinOrZero("F_G") + rDiff - params.g));

    for (let i = 0; i < carModels.length; i++) {
      let model = carModels[i];
      if (model) {
        let modelLastPartName = model.name.at(-1);
        if (!model.morphTargetInfluences) {
          continue;
        }
        for (let i = 0; i < carParamsStrings.length; i++) {
          let key = carParamsStrings[i];
          let value = carParamsValues[i];
          let index =
            model.userData.mapMorphParamNameToAttributeIndex[
              key + "_" + modelLastPartName
            ];
          if (Number.isInteger(index)) {
            model.morphTargetInfluences[index] = value;
          }
        }
      }
    }
  }
  function setCarMaterials(object: any) {
    let materials = [
      new THREE.MeshBasicMaterial({ transparent: true, opacity: 0 }),
      new THREE.MeshStandardMaterial({
        roughness: 0.6,
        metalness: 0.6,
        envMapIntensity: 0.2,
      }),
    ];
    object.geometry.clearGroups();
    object.geometry.addGroup(0, 4 * 3, 1);
    object.geometry.addGroup(4 * 3, 6, 0);
    object.geometry.addGroup(6 * 3, Infinity, 1);
    object.material = materials;
  }
  const meshes = new Array<THREE.Mesh>();
  resource.scene.traverse(function (object: any) {
    if (object instanceof THREE.Mesh) {
      meshes.push(object);
    }
  });
  const carModels: Array<THREE.Mesh> = [];
  meshes.forEach((mesh) => {
    if (mesh.name === CarMeshName.base || mesh.name === CarMeshName.floor) {
      mesh.material = new THREE.MeshStandardMaterial({
        roughness: 0.6,
        metalness: 0.6,
        envMapIntensity: 0.2,
      });
      if (carStorage.params.double_door) {
        if (mesh.name === CarMeshName.floor) {
          setCarMaterials(mesh);
        }
      }
      mesh.castShadow = true;
      mesh.userData.mapMorphParamNameToAttributeIndex = {};
      if (mesh.name.includes(CarMeshName.general)) carModels.push(mesh);
    } else {
      mesh.visible = false;
    }
  });
  const carEntity = new CarEntity({
    mesh: carModels[0],
    car: carStorage,
  });
  meshes.forEach((mesh) => {
    if (isMorphTarget(mesh.name)) {
      let meshPositionStr = mesh.name.split("_").at(-1);
      let meshPosition = 0;
      if (meshPositionStr) {
        meshPosition = parseInt(meshPositionStr) - 1;
      }
      let carObject = carModels[meshPosition];
      let objPos = new THREE.Vector3();
      let carObjPos = new THREE.Vector3();
      carObject.getWorldPosition(carObjPos);
      mesh.getWorldPosition(objPos);
      mesh.position.set(
        mesh.position.x - objPos.x + carObjPos.x,
        mesh.position.y + objPos.z - carObjPos.z,
        mesh.position.z
      );
      const positionAttribute = mesh.geometry.attributes.position;
      const newPositions = [];
      for (let i = 0; i < positionAttribute.count; i++) {
        const x = positionAttribute.getX(i);
        const y = positionAttribute.getY(i);
        const z = positionAttribute.getZ(i);
        newPositions.push(x, y, z);
      }
      if (!carObject.geometry.morphAttributes.position) {
        carObject.geometry.morphAttributes.position = [];
      }
      let index = carObject.geometry.morphAttributes.position.length;
      carObject.geometry.morphAttributes.position[index] =
        new THREE.Float32BufferAttribute(newPositions, 3);
      carObject.userData.mapMorphParamNameToAttributeIndex[mesh.name] = index;
      carObject.updateMorphTargets();
    }
  });
  setCarParams();
  return carEntity;
}

// const eee = new EventEmitter<App3dEventTypes>();

export class App3d extends EventEmitter<App3dEvent> {
  private readonly sceneWrapper = new SceneWrapper();
  private readonly view: View<Entity, "car"> = new View();
  private readonly placeMap: Map<string, Place> = new Map();
  private readonly resourceManager = new ResourceManager();

  //TODO: вынести
  private mode: Mode = "main";
  private carEntity?: CarEntity;
  private rightGroup = new THREE.Group();
  private leftGroup = new THREE.Group();
  private isRightPlacesRotated = false;
  private currentPlace: Place | null = null;
  private activeShelf: Shelf | null = null;
  private sceneEventProvider = new EventProvider<SceneEventsMap>();
  private uiEventProvider = new EventProvider<WindowEventMap>();

  constructor() {
    super();
    this.view.attachTo(this.sceneWrapper);
    this.resourceManager.appendResource(ApiPath.car, ResourceType.GLTF);
    this.resourceManager.appendResource(ApiPath.hdr, ResourceType.HDR_TEXTURE);
    this.sceneEventProvider
      .on("injectResource", ({ url, resourceType }) => {
        this.resourceManager.appendResource(url, resourceType);
      })
      .on("injectComponent", ({ component, parentUuid }) => {
        this.addComponent(component, parentUuid);
      })
      //@ts-ignore ##TODO: поправить
      .on("validateInjectComponent", (e) => {
        return this.validateAddComponent(e.component, e.parentUuid);
      })
      .on("removeComponent", ({ component, parentUuid }) => {
        this.removeComponent(component, parentUuid);
      })
      .on(
        "loadResources",
        async () =>
          await this.loadResources({
            Authorization: "Bearer" + localStorage.getItem("token"),
          })
      )
      .on("loadCar", async ({ preset }) => await this.loadCar(preset)) // TODO: вынести
      .on("setMode", ({ mode }) => this.setMode(mode))
      //@ts-ignore ##TODO: поправить
      .on("requestConstraints", ({ side }) => {
        return this.provideConstraints(side);
      })
      .on("resetState", () => {
        this.resetState();
      })
      .on("setShow", () => {
        this.sceneWrapper.show();
      })
      .on("requestShelfPosition", (e) => this.fitNewShelfFromTop(e))
      .on("requestPreviews", async () => this.shotAllStorages())
      //@ts-ignore ##TODO: поправить
      .on("requestBlackList", (shelves) => this.getBlackList(shelves));
    this.uiEventProvider
      .on("mousedown", (e) => this.handleMouseDown(e))
      .on("mousemove", (e) => this.handleMouseMove(e))
      .on("mouseup", (e) => this.handleMouseUp(e))
      .on("resize", () => this.handleResize());
    this.sceneWrapper.hide();
  }

  public async shotAllStorages(): Promise<void> {
    if (!this.carEntity) return;
    //TODO: рефактор
    const { car } = this.carEntity;

    const images = new Array<ImageEntity>();
    if (this.mode !== "main") {
      return Promise.resolve();
    }
    this.setVisibleCar(false);
    this.placeMap.forEach((p) => p.view.hide());
    this.placeMap.forEach((place) => {
      if (!place.withRack) return;
      place.view.show();
      place.updateView("screenshot");
      const target = place.view.position
        .clone()
        .add(new THREE.Vector3(0, place.height / Default.scale / 2, 0));
      const dist =
        (place.side === "left" ? 1 : 1) *
        (target.y / Math.tan((Math.PI * 50) / 360));
      // const position = target.clone().add(new THREE.Vector3(0, 0, 5 * dist));
      const position = target
        .clone()
        .add(new THREE.Vector3(0, 0, place.height / 60));
      const bbox = new THREE.Box3().setFromObject(
        place.view.group.children[6].children[0]
      );

      //   const width = Math.abs(bbox.min.x - bbox.max.x);
      //   const height = Math.abs(bbox.min.y - bbox.max.y);
      //   const depth = Math.abs(bbox.min.z - bbox.max.z);
      const { width, height } = place;
      //   console.log({ place: place.name, width, height, depth });
      //   console.log({ place: { width: place.width, height: place.height } });
      // const size =
      //     (place.width > place.height ? 1.7 * place.width : 1.7 * place.height) /
      //     Default.scale;
      let size = 1.1;

      if (width === 486) {
        size = 1.2;
      }

      if (width === 630) {
        size = 1.3;
      }
      if (width === 930) {
        size = 1.4;
      }
      if (width === 1070) {
        size = 1.45;
      }
      if (width === 1210) {
        size = 1.6;
      }
      if (width === 1515) {
        size = 1.9;
      }

      console.log({ width, height });
      this.sceneWrapper.setParams({
        screenshotCamera: { position, target, size },
        renderer: { size: { w: 2000, h: 2000 } },
      });
      if (place.side === "right" && !place.rotated)
        place.view.group.rotateY(Math.PI);
      if (place.side === "left" && place.rotated)
        place.view.group.rotateY(Math.PI);
      const image = this.sceneWrapper.getShot("orthogonal");
      this.resourceManager.appendResource(
        image,
        ResourceType.IMAGE,
        place.uuid
      );
      if (place.side === "right" && !place.rotated)
        place.view.group.rotateY(Math.PI);
      if (place.side === "left" && place.rotated)
        place.view.group.rotateY(Math.PI);
      place.view.hide();
    });
    this.setVisibleCar(true);
    this.placeMap.forEach((p) => p.view.show());
    this.setMode("screenshot");
    // this.isRightPlacesRotated = false;
    this.placeMap.forEach((p) => p.view.show());
    this.placeMap.forEach((p) => p.updateView("main"));
    // this.rotateRightPlaces('interior');
    this.resourceManager.appendResource(
      this.sceneWrapper.getShot(),
      ResourceType.IMAGE,
      "overview",
      true
    );
    await this.resourceManager.loadResources();
    this.placeMap.forEach((place) => {
      if (!place.withRack) return;
      images.push({
        src: this.resourceManager.getImageByLabel(place.uuid),
        label: place.uuid,
        name: place.label,
      });
    });
    images.push({
      src: this.resourceManager.getImageByLabel("overview"),
      label: "overview",
      name: "overview",
    });
    this.emit("providePreviews", images);
    // this.rotateRightPlaces('main');
    this.setMode("main");
    return Promise.resolve();
  }

  private getBlackList(shelves: ShelfComponent[]) {
    const place = this.currentPlace;
    if (!place) return [];
    shelves.forEach((shelf) => {
      // console.log(getShelfType(shelf));
      // console.log('can add: ', place.canAddShelfFromComponent(shelf).result);
    });
    return shelves
      .filter((shelf) => !place.canAddShelfFromComponent(shelf).result)
      .map((shelf) => getShelfType(shelf));
  }

  private shotCamera(rackName: any) {
    this.sceneWrapper.setParams({
      renderer: {
        size: { w: 1000, h: 800 },
      },
      camera: { aspect: 1000 / 800 },
    });
    let image = this.sceneWrapper
      .getShot("perspective")
      .replace("image/png", "image/octet-stream");
    let link = document.createElement("a");
    link.setAttribute("download", rackName.replace(".glb", ".png"));
    link.href = image;
    link.click();
  }

  private addComponent(component: Component, parentUuid?: string): void {
    if (!this.carEntity) throw Error(`Car not loaded.`);
    if (!parentUuid) parentUuid = component.uuid;
    if (isRackComponent(component)) {
      const place = this.placeMap.get(parentUuid);
      if (!place) throw Error(`Can't find place with this uuid`);
      if (place.withRack) throw Error(`There are one rack already`);
      const resource = this.resourceManager.getGltfByUrl(
        ApiPath.components + component.glb_model
      );
      place.initFromComponent(component, resource);
      this.placeMap.forEach((p) => p.fitToCar());
      this.setMode("storage");
    } else if (isShelfComponent(component)) {
      const place = this.placeMap.get(parentUuid);
      if (!place) throw Error("Place with this uuid not found.");
      if (!place.withRack) throw Error("Place is not filled.");
      const resource = this.resourceManager.getGltfByUrl(
        ApiPath.components + component.glb_model
      );
      const canAdd = place.canAddShelfFromComponent(component);
      if (!canAdd.result) {
        this.emit("provideErrorMessage", canAdd.message);
        return;
      }
      const shelf = place.addShelfFromComponent(component, resource);
      // place.view.getEntitiesNames().forEach(name=>place.view.getEntityByName(name)!.object.visible = false);
      // shelf.object.visible = true;
      // this.shotCamera(place.glbUrl);
      this.setShelfToPositionByGrid(place, shelf, shelf.position);
    } else {
      this.placeMap.set(
        parentUuid,
        new Place({
          carEntity: this.carEntity,
          scene: this.sceneWrapper,
          side: positionToSide(component.estimatePosition),
          uuid: parentUuid,
        })
      );
      this.placeMap.forEach((p) => p.fitToCar());
    }
  }

  private validateAddComponent(
    component: Component,
    parentUuid: string
  ): { isValid: boolean; message: string } {
    const place = this.placeMap.get(parentUuid);
    if (!place) return { isValid: false, message: "" };

    const lastShelf = place.getShelf(component.uuid);

    if (!lastShelf)
      return {
        isValid: false,
        message: "Error.invalidAddComponent.default",
      };

    if (isShelfComponent(component)) {
      switch (lastShelf.type) {
        case "vertical": {
          const availableHeight = place.getAvailableHeightFromBottomFor(
            lastShelf.uuid
          );
          if (availableHeight < lastShelf.height) {
            place.removeShelf(lastShelf.uuid);
            return {
              isValid: false,
              message: "Error.invalidAddComponent.vertical",
            };
          }
          break;
        }
        case "top": {
          const availableHeight = place.getAvailableHeightFromTopFor(
            lastShelf.uuid
          );
          if (availableHeight > place.height) {
            place.removeShelf(lastShelf.uuid);
            return {
              isValid: false,
              message: "Error.invalidAddComponent.top",
            };
          }
          break;
        }
        default:
          break;
      }
    }

    return { isValid: true, message: "" };
  }

  private provideConstraints(side: Side) {
    if (!this.carEntity) throw Error(`Car not found`);
    // this.emit('provideConstraints', this.carEntity.getConstraints(side));
    return this.carEntity.getConstraints(side);
  }
  private setCurrentPlace(uuid: string | null): void {
    if (!uuid) {
      this.currentPlace = null;
      return;
    }
    this.currentPlace = this.placeMap.get(uuid) ?? null;
    this.placeMap.forEach(
      (p) => (p.isActive = p.name === this.currentPlace?.name)
    );
    // this.emit('provideActiveComponentUuid', this.currentPlace?.uuid || null);
  }

  private getShift() {
    if (!this.carEntity) return 0;
    return (
      -400 +
      this.carEntity.length -
      Math.max(
        this.carEntity.filledWidthBySide("left"),
        this.carEntity.filledWidthBySide("right")
      )
    );
  }
  private updateShiftBetweenSides(mode: Mode): void {
    if (!this.carEntity) return;
    const shift = this.getShift() / Default.scale;
    if (mode === "interior" || mode === "screenshot") {
      this.leftGroup.position.x += shift;
      this.rightGroup.position.z -= shift;
    } else if (mode) {
      this.leftGroup.position.x -= shift;
      this.rightGroup.position.z += shift;
    }
  }
  private rotateRightPlaces(mode: Mode): void {
    if (!this.carEntity) return;
    const { car } = this.carEntity;

    if (!car) return;

    const leftPlaces = Array.from(this.placeMap.values()).filter(
      (r) => r.side === "left"
    );
    const rightPlaces = Array.from(this.placeMap.values()).filter(
      (r) => r.side === "right"
    );
    if (
      (mode === "interior" || mode === "screenshot") &&
      !this.isRightPlacesRotated
    ) {
      this.rightGroup = new THREE.Group();
      this.leftGroup = new THREE.Group();
      this.sceneWrapper.add(this.rightGroup);
      this.sceneWrapper.add(this.leftGroup);
      const group = new THREE.Group();
      this.rightGroup.add(group);

      rightPlaces.forEach((r) => {
        group.attach(r.view.group);
      });
      leftPlaces.forEach((r) => {
        this.leftGroup.attach(r.view.group);
      });

      group.position.x -= car.params.r / Default.scale;
      this.rightGroup.position.x += car.params.r / Default.scale;

      this.rightGroup.rotateY(Math.PI / 2);

      this.rightGroup.position.add(
        new THREE.Vector3(
          -this.carEntity.width,
          0,
          -(this.carEntity.width + Default.depth / 2)
        ).multiplyScalar(1 / Default.scale)
      );
      this.isRightPlacesRotated = true;
      this.updateShiftBetweenSides(mode);
    }
    if (
      mode !== "interior" &&
      mode !== "screenshot" &&
      this.isRightPlacesRotated
    ) {
      this.updateShiftBetweenSides(mode);
      this.sceneWrapper.render();
      this.rightGroup.position.add(
        new THREE.Vector3(
          this.carEntity.width,
          0,
          this.carEntity.width + Default.depth / 2
        ).multiplyScalar(1 / Default.scale)
      );
      this.rightGroup.rotateY(-Math.PI / 2);
      rightPlaces.forEach((r) => {
        this.sceneWrapper.attach(r.view.group);
      });
      this.isRightPlacesRotated = false;
    }
  }

  private setVisibleCar(visible: boolean): void {
    const car = this.view.getEntityByName("car");
    if (car) car.object.visible = visible;
  }
  private handleMouseMove(event: MouseEvent): void {
    this.sceneWrapper.updateRaycast(event);
    if (this.mode === "shelves") this.dragShelf();
  }
  private handleMouseUp(e: MouseEvent): void {
    e.preventDefault();
    if (!this.activeShelf) {
      const intersectsObjects = this.sceneWrapper.findIntersect();
      const button = intersectsObjects
        .filter((b) => b.object.visible)
        .sort((a, b) => a.distance - b.distance)
        .filter((a) => a.object instanceof ButtonEntity)[0]
        ?.object as ButtonEntity;
      if (button && button.parent?.visible) {
        if (!button.detail.parentUuid) {
          this.emit("provideActiveComponentUuid", button.detail.uuid);
          this.setCurrentPlace(button.detail.uuid);
        }
        this.emitAction(button);
      }
    }

    const activeShelf = this.activeShelf;
    if (!activeShelf) return;
    activeShelf.makeInactive();
    this.activeShelf = null;
    this.emit("provideIsDrag", false);
    this.sceneWrapper.setParams({ controls: { enabled: true } });
  }
  private handleResize(): void {
    this.sceneWrapper.resize();
  }

  public removeComponent(component: Component, parentUuid?: string): void {
    if (!parentUuid) parentUuid = component.uuid;
    if (isRackComponent(component)) this.placeMap.get(parentUuid)?.earse();
    else if (isShelfComponent(component)) {
      const place =
        Array.from(this.placeMap.values()).find((p) => p.uuid === parentUuid) ??
        null;
      if (!place) throw Error(`Can't finr place`);
      place.removeShelf(component.uuid);
    } else {
      this.placeMap.get(parentUuid)?.remove();
      this.placeMap.delete(parentUuid);
    }
  }

  public rotateCurrentPlace(): void {
    this.currentPlace?.setRotated(!this.currentPlace?.rotated);
    this.updateView(this.mode);
  }

  public setMode(mode: Mode) {
    switch (mode) {
      case "storage": {
        if (!this.currentPlace || !this.carEntity) return;
        this.currentPlace.updateView(mode);
        this.placeMap.forEach((place) =>
          place.name === this.currentPlace?.name
            ? place.view.show()
            : place.view.hide()
        );
        break;
      }
      case "shelves": {
        if (!this.currentPlace || !this.carEntity) return;
        this.currentPlace.updateView(mode);
        this.placeMap.forEach((place) =>
          place.name === this.currentPlace?.name
            ? place.view.show()
            : place.view.hide()
        );
        break;
      }
    }
    this.updateView(mode);
  }

  private emitAction(button: ButtonEntity) {
    if (button.action === "configure") {
      const blackList =
        this.currentPlace?.getNotDragablAlreadyInstalledShelves();
      this.emit("provideMode", {
        mode: "shelves",
        blackList,
      });
    }
    if (button.action === "select") {
      this.emit("provideMode", { mode: "storage" });
    }
    if (button.action === "rotate") {
      try {
        this.rotateCurrentPlace();
        this.emit("provideRotate", this.currentPlace?.rotated || false);
      } catch (e) {
        this.emit("provideErrorMessage", "Can't rotate place");
      }
    }
    if (button.action === "remove") {
      const { uuid, parentUuid } = button.detail;
      if (!parentUuid) {
        this.emit("provideEntityIdToRemove", { uuid, parentUuid: uuid });
      } else {
        this.emit("provideEntityIdToRemove", { uuid, parentUuid });
        const blackList =
          this.currentPlace?.getNotDragablAlreadyInstalledShelves();
        this.emit("provideMode", {
          mode: "shelves",
          blackList: blackList,
        });
      }
      this.setMode(this.mode);
    }
  }

  private handleMouseDown(event: MouseEvent): void {
    this.sceneWrapper.updateRaycast(event);
    const intersectsObjects = this.sceneWrapper.findIntersect();
    const intersectsShelfView = intersectsObjects
      .sort((a, b) => a.distance - b.distance)
      .filter(
        (a) =>
          a.object.parent instanceof ShelfEntity &&
          a.object.parent?.parent?.visible === true
      )[0]?.object.parent as ShelfEntity;
    if (this.mode === "shelves") {
      if (intersectsShelfView) {
        const name = intersectsShelfView.label;
        const place = Array.from(this.placeMap.values()).find((p) =>
          p.hasShelf(name)
        );
        if (!place) return;
        const shelf = place.getShelf(name);
        if (!shelf) return;
        if (shelf && !isNotDragableShelf(shelf.type)) {
          this.sceneWrapper.setParams({ controls: { enabled: false } });
          this.activeShelf = shelf;
          shelf.makeActive();
          this.emit("provideIsDrag", true);
        }
      }
    }
  }
  private dragShelf(): void {
    if (!this.currentPlace || !this.activeShelf) {
      return;
    }
    const plane = this.currentPlace.view.getEntityByName("plane");
    if (!plane) return;
    const found = this.sceneWrapper.findIntersect(plane.object);
    if (found.length !== 0) {
      const coord = this.currentPlace.translateViewHeightToRealHeight(
        found[0].point.y
      );
      this.setShelfToPositionByGrid(this.currentPlace, this.activeShelf, coord);
      this.emit("provideShelfPosition", {
        position: coord,
        parentUuid: this.currentPlace.uuid,
        uuid: this.activeShelf.uuid,
      });
    }
  }

  private setShelfToPositionByGrid(
    place: Place,
    shelf: Shelf,
    position: number
  ) {
    if (isNotDragableShelf(shelf.type)) return;
    const startHeight = place.hasArc()
      ? (Math.floor((this.carEntity?.arcHeight() ?? 0) / 68) + 1) * 68 ?? 68
      : 68;
    if (place.hasArc()) {
      if (startHeight > position) {
        shelf.makeActiveRed();
      } else if (shelf.isActiveRed) {
        shelf.makeActive();
      }
    }
    place.setShelfToPosition(shelf, position);
  }

  private updateView(mode: Mode): void {
    if (!this.carEntity) return;
    let viewMode: Mode | undefined;
    if (mode === "shelves") {
      viewMode =
        (this.currentPlace?.rotated && this.currentPlace?.side === "right") ||
        (!this.currentPlace?.rotated && this.currentPlace?.side === "left")
          ? "shelvesReverse"
          : "shelves";
    } else if (mode === "storage") {
      viewMode =
        (this.currentPlace?.rotated && this.currentPlace?.side === "right") ||
        (!this.currentPlace?.rotated && this.currentPlace?.side === "left")
          ? "storageReverse"
          : "storage";
    } else {
      viewMode = mode;
    }
    this.mode = mode;
    this.rotateRightPlaces(mode);
    this.setVisibleCar(mode === "main");
    this.placeMap.forEach((place) => place.view.show());
    this.placeMap.forEach((place) => place.updateView(mode));
    const controls = controlsState.get(viewMode);
    const camera = cameraState.get(mode);
    const renderer = rendererState.get(mode);
    if (!controls || !camera || !renderer) return;
    const { car } = this.carEntity;
    if (!car) return;
    switch (mode) {
      case "main":
        if (!this.carEntity) break;
        controls.target = new THREE.Vector3(
          0,
          (car.params.h ?? 0) / (3 * Default.scale),
          0
        );
        break;
      case "interior":
        if (!this.carEntity) break;
        const leftPlace = Array.from(this.placeMap.values()).find(
          (p: Place) => p.side === "left"
        );
        const rightPlace = Array.from(this.placeMap.values()).find(
          (p: Place) => p.side === "right"
        );
        if (!leftPlace || !rightPlace) return;
        const cameraPosition = leftPlace.view.worldPosition
          .add(rightPlace.view.worldPosition)
          .multiplyScalar(0.5);
        controls.target = cameraPosition.clone();
        controls.target.y += 1;
        camera.position = cameraPosition;
        camera.position.x -= 3.5;
        camera.position.y += 2;
        camera.position.z += 3.5;
        break;
      case "screenshot": {
        if (!this.carEntity) break;
        const leftPlace = Array.from(this.placeMap.values()).find(
          (p: Place) => p.side === "left"
        );
        const rightPlace = Array.from(this.placeMap.values()).find(
          (p: Place) => p.side === "right"
        );
        if (!leftPlace || !rightPlace) return;
        const cameraPosition = leftPlace.view.worldPosition
          .add(rightPlace.view.worldPosition)
          .multiplyScalar(0.5);
        cameraPosition.y = 0;
        controls.target = cameraPosition.clone();
        controls.target.y += 0.6;
        camera.position = cameraPosition;
        const x = Math.abs(
          leftPlace.view.worldPosition.x - rightPlace.view.worldPosition.x
        );
        const z = Math.abs(
          leftPlace.view.worldPosition.z - rightPlace.view.worldPosition.z
        );
        const xd = findDeltaXOfOptimalCameraPosition(
          x,
          z,
          Math.cos(((camera.fov || 50) / 180) * Math.PI)
        );
        camera.position.x -= xd + 1.5; // 1.5 addition for finest borders
        camera.position.z += ((xd + 1.5) * z) / x;

        renderer.size = { w: 768, h: 410 };
        camera.aspect = 768 / 410;
        break;
      }
      case "shelves":
      case "storage": {
        if (!this.currentPlace) return;
        const currentPlace = this.currentPlace;
        if (!currentPlace) return;
        this.placeMap.forEach((place) =>
          currentPlace.name === place.name
            ? place.view.show()
            : place.view.hide()
        );
        const entity =
          currentPlace.view.getEntityByName("rack") ??
          currentPlace.view.getEntityByName("place");
        const cameraPosition = new THREE.Vector3();
        const targetPosition = new THREE.Vector3();
        if (entity) {
          entity.object.getWorldPosition(cameraPosition);
          entity.object.getWorldPosition(targetPosition);
          cameraPosition.y += currentPlace.height / 1.5 / Default.scale;
          targetPosition.y += this.currentPlace.height / 1.5 / Default.scale;
        }
        camera.position = cameraPosition;
        camera.position.z +=
          (currentPlace.rotated ? -1 : 1) *
          (currentPlace.side === "left" ? 1 : -1) *
          3;
        controls.target = targetPosition;
        break;
      }
    }
    this.sceneWrapper.setParams({
      camera,
      controls,
      renderer,
      lights: [directionalLight, ambientLight],
    });
  }

  public appendResource({
    url,
    type,
  }: {
    url: string;
    type: ResourceType;
  }): void {
    this.resourceManager.appendResource(url, type);
  }

  public async loadResources(requestParams?: RequestParams): Promise<void> {
    return this.resourceManager.loadResources(requestParams);
  }

  resetState() {
    this.sceneWrapper.hide();
    Place.globalIdx.left = 0;
    Place.globalIdx.right = 0;
    this.placeMap.forEach((p) => p.remove());
    this.placeMap.clear();
    this.currentPlace = null;
    this.activeShelf = null;
  }

  public async loadCar(carParams: Packagies): Promise<void> {
    await this.loadResources();
    const resource = this.resourceManager.getGltfByUrl(ApiPath.car);
    const texture = this.resourceManager.getHdrTextureUrl(ApiPath.hdr);
    this.sceneWrapper.setEnvironment(texture);
    if (!this.view.getEntityByName("car"))
      this.view.addEntity({
        name: "car",
        type: "mesh",
        object: resource.scene,
      });
    this.carEntity = dumbCarInitializer(resource, carParams);
    return Promise.resolve();
  }

  private fitNewShelfFromTop({
    uuid,
    parentUuid,
  }: {
    uuid: string;
    parentUuid: string;
  }): void {
    const place = this.placeMap.get(parentUuid);
    const shelf = place?.getShelf(uuid);
    if (!place || !shelf) throw Error(`Can't find place or shelf`);
    const step = 20;
    const positions: Array<number> = [];
    for (
      let i =
        (shelf.type === "boxxser" ||
        shelf.type === "carrylite" ||
        shelf.type === "preset"
          ? place.height + shelf.height - step
          : place.height) - shelf.height;
      i > 0;
      i -= step
    ) {
      positions.push(i);
    }
    for (const position of positions) {
      if (shelf.isPlaced) {
        this.emit("provideShelfPosition", {
          uuid,
          position: shelf.position,
          parentUuid: place.uuid,
        });
        return;
      }
      this.setShelfToPositionByGrid(place, shelf, position);
    }
    place.removeShelf(shelf.name);
    // this.dispatchEvent({
    //     type: 'addComponentError',
    //     value: "Can't add shelf to rack",
    // });
  }

  public update(): void {
    this.sceneWrapper.render();
  }

  public setContainer(containter: HTMLElement | null): void {
    if (!containter) return;
    this.sceneWrapper.setContainer(containter);
    this.sceneWrapper.runRenderCycle();
  }
}
