import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import brandReducer from './brandSlice';
import modelReducer from './modelSlice';
import packagiesReducer from './packagieSlice';
import userReducer from './userSlice';
import imageReducer from './imageSlice';
import presetsReducer from './presetSlice';
import accessoriesReducer from './accessoriesSlice';
import wardrobesReducer from './wardrobesSlice';
import glbReducer from './glbSlice';
import componentReducer from './componentsSlice';
import app3dReducer from './app3dSlice';

const store = configureStore({
    reducer: {
        brand: brandReducer,
        model: modelReducer,
        packagies: packagiesReducer,
        user: userReducer,
        image: imageReducer,
        presets: presetsReducer,
        accessories: accessoriesReducer,
        wardrobes: wardrobesReducer,
        glb: glbReducer,
        components: componentReducer,
        app3d: app3dReducer,
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export default store;
