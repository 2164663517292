import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { generateUUID } from "three/src/math/MathUtils";

import { app3dService } from "../api/app3d";

import {
  getRackComponentFromPreset,
  isEmpty,
  positionToSide,
} from "../../utils/component";

import { Side } from "../../types/general";
import {
  Component,
  isRackComponent,
  isShelfComponent,
  InitialComponent,
  FilledComponent,
  ShelfComponent,
} from "../../types/component";
import { ResourceType } from "../../types/resources";
import {
  Constraints,
  App3dState,
  ApiPath,
  ImageEntity,
} from "../../types/app3d";
import { Packagies } from "../../types/packagies";
import { Preset, TotalSumProps, UnitPreset } from "../../types/preset";

import { SIDES } from "../../consts/scene";

import { RootState, AppDispatch } from "./index";
import { getRacks, getShelves, setBlackListShelves } from "./componentsSlice";
import { Mode, ShelfType } from "../../types/scene";
import { RackComponent } from "../../types/component";

const decreaseTotalSum = (
  prevTotalSum: TotalSumProps,
  decrement: TotalSumProps
): TotalSumProps => {
  const nextTotalSum = { ...prevTotalSum };

  const prices = Object.keys(prevTotalSum) as Array<keyof typeof prevTotalSum>;

  prices.forEach((price) => {
    nextTotalSum[price] -= decrement[price];
    nextTotalSum[price] = Math.max(nextTotalSum[price], 0);
  });

  return nextTotalSum;
};

const increaseTotalSum = (
  prevTotalSum: TotalSumProps,
  increment: TotalSumProps
): TotalSumProps => {
  const nextTotalSum = { ...prevTotalSum };

  const prices = Object.keys(prevTotalSum) as Array<keyof typeof prevTotalSum>;

  prices.forEach((price) => {
    nextTotalSum[price] += increment[price];
  });

  return nextTotalSum;
};

const initialState: App3dState = {
  show: false,
  presetId: -1,
  activePlaceUuid: null,
  rackComponents: {},
  shelfComponents: {},
  mode: "main",
  constraints: {
    maxHeight: 999,
    maxWidth: 999,
    minWidth: 0,
    invertMinWidth: null,
  },
  presetData: [],
  loading: false,
  error: null,
  isLastPlaceAvailable: { left: true, right: true },
  presetIsLoading: false,
  previews: [],
  availableRacksForSide: { left: 999, right: 999 },
  total_sum: {
    price_dkk: 0,
    price_eur: 0,
    price_gbp: 0,
    price_nok: 0,
    price_sek: 0,
  },
  totalWeight: 0,
};

const app3dSlice = createSlice({
  name: "app3d",
  initialState,
  reducers: {
    _resetState: (state) => {
      state.rackComponents = {};
      state.shelfComponents = {};

      state.total_sum = {
        price_dkk: 0,
        price_eur: 0,
        price_gbp: 0,
        price_nok: 0,
        price_sek: 0,
      };

      state.totalWeight = 0;
    },
    _setPresetIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.presetIsLoading = payload;
      state.loading = payload;
    },
    _setMode: (state, { payload }: PayloadAction<Mode>) => {
      state.mode = payload;
      if (payload === "main" || payload === "interior")
        state.activePlaceUuid = null;
      app3dService.setMode({ mode: payload });
    },
    setShow: (state, { payload }: PayloadAction<boolean>) => {
      state.show = payload;
    },
    setConstraints: (state, { payload }: PayloadAction<Constraints>) => {
      state.constraints = payload;
    },
    setActivePlaceUuid: (state, { payload }: PayloadAction<string | null>) => {
      state.activePlaceUuid = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      if (state.presetIsLoading && payload === false) return;
      state.loading = payload;
    },
    addComponent: (
      state,
      {
        payload: { component, parentUuid },
      }: PayloadAction<{ component?: Component; parentUuid?: string }>
    ) => {
      const {
        rackComponents,
        shelfComponents,
        isLastPlaceAvailable,
        activePlaceUuid,
      } = state;
      if (!component) {
        if (!activePlaceUuid) throw Error(`Active component not found.`);
        const side = positionToSide(
          rackComponents[activePlaceUuid].estimatePosition
        );
        const components = Object.values(rackComponents)
          .filter(isRackComponent)
          .filter(
            (c) =>
              c.estimatePosition ===
              rackComponents[activePlaceUuid].estimatePosition
          )
          .map((c) => c.uuid);
        components.forEach((uuid) => {
          const shelves = shelfComponents[uuid];
          shelves?.forEach((shelf) => {
            if (
              (shelf.glb_model.includes("panel_L") && side === "right") ||
              (shelf.glb_model.includes("panel_R") && side === "left")
            ) {
              throw Error(`Can't add new place.`);
            }
          });
        });
        component = {
          uuid: generateUUID(),
          estimatePosition: side,
        };
      }
      if (!parentUuid) parentUuid = component.uuid;
      if (!parentUuid) {
        if (!activePlaceUuid) throw Error(`Active component not found.`);
        component = {
          ...component,
          uuid: activePlaceUuid,
          estimatePosition: rackComponents[activePlaceUuid].estimatePosition,
        };
      }
      if (isRackComponent(component)) {
        const side = positionToSide(component.estimatePosition);
        if (!component.uuid) {
          component = {
            ...component,
            uuid: parentUuid,
          };
        }

        rackComponents[component.uuid] = component;
        isLastPlaceAvailable[side] = false;

        if (isRackComponent(component)) {
          state.total_sum = increaseTotalSum(state.total_sum, {
            price_dkk: parseFloat(component.price_dkk),
            price_eur: parseFloat(component.price_eur),
            price_gbp: parseFloat(component.price_gbp),
            price_nok: parseFloat(component.price_nok),
            price_sek: parseFloat(component.price_sek),
          });

          state.totalWeight += Number(component.net_weight);
        }
      } else if (isShelfComponent(component)) {
        if (!component.uuid)
          component = { ...component, ...{ uuid: generateUUID() } };
        if (!shelfComponents[parentUuid]) shelfComponents[parentUuid] = [];
        shelfComponents[parentUuid].push(component as ShelfComponent);

        if (isShelfComponent(component)) {
          state.total_sum = increaseTotalSum(state.total_sum, {
            price_dkk: parseFloat(component.price_dkk),
            price_eur: parseFloat(component.price_eur),
            price_gbp: parseFloat(component.price_gbp),
            price_nok: parseFloat(component.price_nok),
            price_sek: parseFloat(component.price_sek),
          });
          state.totalWeight += Number(component.net_weight);
        }
      } else {
        const side = positionToSide(component.estimatePosition);
        const lastIndexInSide = Object.values(rackComponents).filter(
          (rack) => positionToSide(rack.estimatePosition) === side
        ).length;
        component = {
          ...component,
          label:
            lastIndexInSide < 3
              ? `${side === "left" ? "V" : "H"}${lastIndexInSide + 1}`
              : `${lastIndexInSide - 2}`,
        } as InitialComponent;
        rackComponents[component.uuid] = component;
        isLastPlaceAvailable[side] = true;
      }
      app3dService.injectComponent({ component, parentUuid });
      if (isShelfComponent(component))
        app3dService.requestShelfPosition({ uuid: component.uuid, parentUuid });
    },
    _removeComponent: (
      state,
      {
        payload: { uuid, parentUuid },
      }: PayloadAction<{ uuid: string; parentUuid?: string }>
    ) => {
      if (!parentUuid) parentUuid = uuid;
      const component = state.rackComponents[parentUuid];

      if (uuid !== parentUuid) {
        const shelfComponent = state.shelfComponents[parentUuid].find(
          (c) => c.uuid === uuid
        );
        if (!shelfComponent || !isShelfComponent(shelfComponent))
          throw Error("Unrecognizable component");
        const shelfIndex = state.shelfComponents[parentUuid].findIndex(
          (s) => s.uuid === uuid
        );
        if (shelfIndex > -1) {
          state.shelfComponents[parentUuid].splice(shelfIndex, 1);
          app3dService.removeComponent({
            component: shelfComponent,
            parentUuid,
          });

          state.total_sum = decreaseTotalSum(state.total_sum, {
            price_dkk: parseFloat(shelfComponent.price_dkk),
            price_eur: parseFloat(shelfComponent.price_eur),
            price_gbp: parseFloat(shelfComponent.price_gbp),
            price_nok: parseFloat(shelfComponent.price_nok),
            price_sek: parseFloat(shelfComponent.price_sek),
          });

          state.totalWeight = Math.max(
            state.totalWeight - Number(shelfComponent.net_weight),
            0
          );
        } else {
          throw Error(`Can't find shelf with this uuid`);
        }
        return;
      }

      const { estimatePosition } = component;
      const side = positionToSide(estimatePosition);
      const placesInSide = Object.values(state.rackComponents).filter(
        (r) => r.estimatePosition[0] === side[0]
      );
      const lastRackUuid = placesInSide.slice(-1)[0].uuid;
      if (uuid !== lastRackUuid) return;

      if (isRackComponent(component)) {
        state.rackComponents[uuid] = {
          component_type_id: 1,
          estimatePosition,
          uuid,
        };

        if (state.shelfComponents[uuid]) {
          state.shelfComponents[uuid].forEach((component) => {
            state.total_sum = decreaseTotalSum(state.total_sum, {
              price_dkk: parseFloat(component.price_dkk),
              price_eur: parseFloat(component.price_eur),
              price_gbp: parseFloat(component.price_gbp),
              price_nok: parseFloat(component.price_nok),
              price_sek: parseFloat(component.price_sek),
            });

            state.totalWeight = Math.max(
              state.totalWeight - Number(component.net_weight),
              0
            );
          });
          state.shelfComponents[uuid] = [];
        }

        app3dService.removeComponent({ component, parentUuid });
        state.isLastPlaceAvailable[side] = true;

        state.total_sum = decreaseTotalSum(state.total_sum, {
          price_dkk: parseFloat(component.price_dkk),
          price_eur: parseFloat(component.price_eur),
          price_gbp: parseFloat(component.price_gbp),
          price_nok: parseFloat(component.price_nok),
          price_sek: parseFloat(component.price_sek),
        });

        state.totalWeight = Math.max(
          state.totalWeight - Number(component.net_weight),
          0
        );
      } else if (!isShelfComponent(component)) {
        if (placesInSide.length <= 1) return;
        delete state.rackComponents[uuid];
        state.isLastPlaceAvailable[side] = false;
        app3dService.removeComponent({ component, parentUuid });
      } else {
        throw Error("Unrecognizable component");
      }
    },
    addResource: (_, { payload }: PayloadAction<FilledComponent>) => {
      app3dService.injectResource({
        url: ApiPath.components + payload.glb_model,
        resourceType: ResourceType.GLTF,
      });
    },
    setShelfPosition: (
      { shelfComponents },
      {
        payload: { uuid, parentUuid, position },
      }: PayloadAction<{ uuid: string; parentUuid: string; position: number }>
    ) => {
      const shelf = shelfComponents[parentUuid].find((s) => s.uuid === uuid);
      if (!shelf) throw Error(`Can't find shelf`);
      shelf.position = position;
    },
    setPreviews: (state, { payload }: PayloadAction<Array<ImageEntity>>) => {
      // console.log();
      state.previews = payload;
    },
    _setAvailableRacksForSide: (
      state,
      {
        payload: { side, numOfRacks },
      }: PayloadAction<{ side: Side; numOfRacks: number }>
    ) => {
      state.availableRacksForSide[side] = numOfRacks;
    },
    setError: (state, { payload }: PayloadAction<string | null>) => {
      state.error = payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(validateAddComponent.pending, (state) => {
        state.error = null;
      })
      .addCase(validateAddComponent.fulfilled, (state, action) => {
        if (!action.payload.response.isValid) {
          state.error = action.payload.response.message;
          state.shelfComponents[action.payload.parentUuid].pop();

          if (isShelfComponent(action.payload.component)) {
            state.total_sum = decreaseTotalSum(state.total_sum, {
              price_dkk: parseFloat(action.payload.component.price_dkk),
              price_eur: parseFloat(action.payload.component.price_eur),
              price_gbp: parseFloat(action.payload.component.price_gbp),
              price_nok: parseFloat(action.payload.component.price_nok),
              price_sek: parseFloat(action.payload.component.price_sek),
            });

            state.totalWeight = Math.max(
              state.totalWeight - Number(action.payload.component.net_weight),
              0
            );
          }
        }
      });
  },
});

export const validateAddComponent = createAsyncThunk<
  {
    response: { isValid: boolean; message: string };
    component: Component;
    parentUuid: string;
  },
  { component: Component; parentUuid: string },
  {
    state: RootState;
  }
>("validateAddComponent", async ({ component, parentUuid }, { getState }) => {
  const { app3d: state } = getState();
  const shelfComponents = state.shelfComponents[parentUuid];

  const response = await app3dService.validateInjectComponent({
    component: {
      ...component,
      uuid: shelfComponents[shelfComponents.length - 1].uuid,
    },
    parentUuid,
  });

  return { response, component, parentUuid };
});

export const loadCar = createAsyncThunk(
  "loadCar",
  async (params: Packagies) => {
    return await app3dService.loadCar({ preset: params });
  }
);
export const requestPreviews = createAsyncThunk("requestPreviews", async () => {
  return await app3dService.requestPreviews();
});

export const loadResources = createAsyncThunk("loadResources", async () => {
  return await app3dService.loadResources();
});
export const removeComponent = createAsyncThunk<
  void,
  { uuid: string; parentUuid?: string },
  { state: RootState; dispatch: AppDispatch }
>("removeComponent", async ({ uuid, parentUuid }, { getState, dispatch }) => {
  const { app3d: state } = getState();
  const data = { uuid, parentUuid };
  if (!parentUuid) parentUuid = uuid;
  const component = state.rackComponents[parentUuid];
  const isShelfComponent = Boolean(
    state.shelfComponents[parentUuid]?.find(
      (component) => component.uuid === uuid
    )
  );

  // Strict dispatching order
  if (isShelfComponent) {
    dispatch(setMode({ mode: "shelves" }));
  } else if (isRackComponent(component)) {
    dispatch(setMode({ mode: "storage" }));
  }
  dispatch(_removeComponent(data));
});

export const setMode = createAsyncThunk<
  void,
  { mode: Mode; blackList?: ShelfType[] },
  { state: RootState; dispatch: AppDispatch }
>("setMode", async (data, { getState, dispatch }) => {
  const { mode, blackList } = data;
  if (blackList) {
    dispatch(setBlackListShelves(blackList));
  }
  dispatch(_setMode(mode));
  const { app3d: scene } = getState();
  if (!scene.activePlaceUuid) throw Error(`Place is not avtive`);
  const component = scene.rackComponents[scene.activePlaceUuid];
  if (mode === "interior" || mode === "main") {
    dispatch(setActivePlaceUuid(null));
  }
  if (mode === "storage") {
    const side = positionToSide(
      scene.rackComponents[scene.activePlaceUuid].estimatePosition
    );
    const ans = await app3dService.requestConstraints({ side });
    // const {
    //     app3d: { constraints },
    // } = getState();
    if (!isShelfComponent(component)) {
      const { payload } = await dispatch(getRacks(ans));
      const racks = payload as RackComponent[];
      dispatch(_setAvailableRacksForSide({ side, numOfRacks: racks.length }));
    }
  }
  if (mode === "shelves") {
    if (isRackComponent(component)) {
      dispatch(getShelves(component.id));
    }
  }
  dispatch(_setMode(mode));
  return Promise.resolve();
});
export const loadPreset = createAsyncThunk<
  void,
  Preset | null,
  { state: RootState; dispatch: AppDispatch }
>("loadPreset", async (preset, { dispatch, getState }) => {
  app3dService.resetState();
  dispatch(_resetState());
  dispatch(_setPresetIsLoading(true));
  if (!preset) {
    await app3dService.loadResources();
  }
  if (preset) {
    const { unit_presets } = preset;
    if (unit_presets.length !== 0) {
      unit_presets.forEach((p) =>
        p.components.forEach((c) => dispatch(addResource(c)))
      );
      await app3dService.loadResources();
      unit_presets.forEach((p) => {
        const component = getRackComponentFromPreset(p);
        if (!component) throw Error("Can't load rack to scene.");
        const side: Side = component.estimatePosition.startsWith("l")
          ? "left"
          : "right";
        const place: InitialComponent = {
          uuid: component.uuid ?? generateUUID(),
          estimatePosition: side,
        };
        dispatch(addComponent({ component: place, parentUuid: place.uuid }));
        dispatch(addComponent({ component, parentUuid: place.uuid }));
        p.components.forEach((c) => {
          if (isShelfComponent(c)) {
            const componentWithUuid: ShelfComponent = {
              ...c,
              ...{ uuid: generateUUID() },
            };
            dispatch(
              addComponent({
                component: componentWithUuid,
                parentUuid: place.uuid,
              })
            );
          }
        });
      });
    }
  }
  const { app3d: scene } = getState();
  SIDES.forEach((side) => {
    if (isEmpty(scene.rackComponents[side])) {
      const place: InitialComponent = {
        uuid: generateUUID(),
        estimatePosition: side,
      };
      dispatch(addComponent({ component: place, parentUuid: place.uuid }));
    }
  });
  dispatch(_setMode("main"));
  app3dService.setShow();
  dispatch(_setPresetIsLoading(false));
});

export const showAddRackButton = ({
  app3d: {
    activePlaceUuid,
    isLastPlaceAvailable,
    rackComponents,
    availableRacksForSide,
  },
}: RootState): boolean => {
  if (!activePlaceUuid) return false;
  if (!rackComponents[activePlaceUuid]) return false;
  const { estimatePosition } = rackComponents[activePlaceUuid];
  const side: Side = positionToSide(estimatePosition);
  if (availableRacksForSide[side] === 0) return false;
  return (
    !isLastPlaceAvailable[side] &&
    Object.values(rackComponents)
      .filter((r) => r.estimatePosition[0] === side[0])
      .slice(-1)[0].uuid === activePlaceUuid
  );
};

export const currentPreset = ({
  app3d: { rackComponents, shelfComponents },
}: RootState): UnitPreset[] => {
  const data: UnitPreset[] = [];
  Object.values(rackComponents)
    .filter(isRackComponent)
    .forEach((rack) => {
      const components = [rack, ...(shelfComponents[rack.uuid] ?? [])];
      const preset: UnitPreset = {
        estimatePosition: rack.estimatePosition,
        components: components.map((component) => {
          return { ...component, ...{ quantity: 1 } };
        }),
        id: null,
        kit_preset_id: null,
        name: "Custom",
        position_in_auto: rack.estimatePosition,
        total_sum_assembly: [],
        total_sum: [],
      };
      data.push(preset);
    });
  return data;
};
const {
  _setMode,
  _resetState,
  _setPresetIsLoading,
  _setAvailableRacksForSide,
  _removeComponent,
} = app3dSlice.actions;

export const {
  setShow,
  setConstraints,
  setLoading,
  addComponent,
  setActivePlaceUuid,
  setShelfPosition,
  setPreviews,
  addResource,
  setError,
} = app3dSlice.actions;

export default app3dSlice.reducer;
