import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';
import { glbModelService } from '../api/glb';

type glbInitialState = {
    loading: boolean;
    glb: any;
};

const initialState: glbInitialState = {
    loading: false,
    glb: undefined,
};

const glbSlice = createSlice({
    name: 'glb',
    initialState,
    reducers: {
        setLoading: (state, { payload }) => {
            state.loading = payload;
        },
        setGlb: (state, { payload }) => {
            state.glb = payload;
        },
    },
});

export const { setLoading, setGlb } = glbSlice.actions;

export const getGlbList = createAsyncThunk('/api/', async (_, { dispatch }) => {
    dispatch(setLoading(true));
    const response = await glbModelService.getGlbModelList();
    if (response.status === 200) {
        dispatch(setGlb(response.data));
    }
    dispatch(setLoading(false));
});

export const getGlbModel = createAsyncThunk(
    '/api/',
    async (params: string, { dispatch }) => {
        dispatch(setLoading(true));
        const response = await glbModelService.getGlbModel(params);
        if (response.status === 200) {
            dispatch(setGlb(response.data));
        }
        dispatch(setLoading(false));
    }
);

export const selectGlb = (state: RootState) => state.glb.glb;

export default glbSlice.reducer;
