import React, { createContext, Context } from 'react';
import { App3d } from './App3d';
type AppContextState = {
    app: App3d | null;
};

const IntialAppConstextState: AppContextState = {
    app: null,
};
const App3dContext: Context<AppContextState> = createContext<AppContextState>(IntialAppConstextState);

export default App3dContext;
