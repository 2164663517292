import { SyntheticEvent, useReducer } from 'react';
import './main.css';
import {
    Box,
    IconButton,
    Typography,
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    DialogTitle,
    TextField,
    styled,
} from '@mui/material';
import { DataGrid, GridColumns, GridActionsCellItem, GridEnrichedColDef } from '@mui/x-data-grid';
import useAppDispatch from '../../hooks/useAppDispatch';
import AddBoxIcon from '@mui/icons-material/AddBox';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useEffect, useState } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import useAppSelector from '../../hooks/useAppSelector';
import NumberFormat from 'react-number-format';

type ModuleType = 'brands' | 'models' | 'packages';
export type DialogEditFields = {
    field: string;
    name: string;
    type: string;
    label: string;
    disabled: boolean;
    hide?: boolean;
};

interface ICarsDataGridProps<T> {
    dataSource: T[];
    gridColumns: GridColumns;
    module: ModuleType;
    moduleTitle: string;
    dialogEditFields: DialogEditFields[];
    pathForInternalModule: string;
    parentId?: string;
    createRow: (data: any) => any;
    updateRow: (data: any) => any;
    deleteRow: (data: any) => any;
}

const CarsDataGrid = <T extends object>({
    dataSource,
    gridColumns,
    module,
    moduleTitle,
    dialogEditFields,
    pathForInternalModule,
    parentId,
    createRow,
    updateRow,
    deleteRow,
}: ICarsDataGridProps<T>) => {
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const dispatch = useAppDispatch();
    const [dialogOpen, changeDialogOpen] = useState(false);
    const [columns, changeGridColumns] = useState(gridColumns);
    const disableOpenInAction = module === 'packages';
    const [dialogFields, changeDialogFields] = useReducer(
        (curFields: any, newFields: any) => Object.assign(curFields, newFields),
        {}
    );
    const brand = useAppSelector((state) => state.brand.brandInfo);
    const model = useAppSelector((state) => state.model.modelnfo);
    const MAX_VAL = 99999.999;
    const withValueLimit = ({ floatValue }: { floatValue: any }) => {
        return floatValue ? floatValue <= MAX_VAL : true;
    };
    const [previewImage, setPreviewImage] = useState('');
    const clearObject = () => {
        Object.keys(dialogFields).forEach((key) => {
            delete dialogFields[key];
        });
        changeDialogFields(dialogFields);
    };
    const CustomNumberFormt = (props: { value: any; name: string; label: string }) => {
        return (
            <NumberFormat
                customInput={TextField}
                value={props.value}
                decimalScale={3}
                thousandSeparator={false}
                fixedDecimalScale={false}
                name={props.name}
                label={props.label}
                onChange={handleInput}
                isAllowed={withValueLimit}
            />
        );
    };

    useEffect(() => {
        addActionColumn();
    }, []);

    const handleSubmit = (event: SyntheticEvent) => {
        event.preventDefault();
        let formData = new FormData();
        Object.keys(dialogFields).forEach((key) => {
            formData.set(key, dialogFields[key]);
        });
        if (!!dialogFields.id) {
            const update = updateRow(formData);
            if (update) {
                dispatch(update);
                handleCloseDialog();
            }
        } else {
            const create = createRow(formData);
            if (create) {
                dispatch(create);
                handleCloseDialog();
            }
        }
    };

    const handleAddClick = (event: any) => {
        if (!!id) {
            if (module === 'models') {
                changeDialogFields({ brand_car_id: id });
            }
            if (module === 'packages') {
                changeDialogFields({ model_car_id: id });
            }
        }
        changeDialogOpen(true);
    };

    const handleEditClick = (event: any) => {
        changeDialogFields(event.row);
        changeDialogOpen(true);
    };

    const handleDeleteClick = (event: any) => {
        if (window.confirm('Delete ' + event.row.name + '?')) {
            dispatch(deleteRow(event.row));
        }
    };

    const handleCloseDialog = (event?: any) => {
        clearObject();
        changeDialogOpen(false);
    };

    const handleInput = (event: any) => {
        changeDialogFields({ [event.target.name]: event.target.value });
    };

    const onFileChange = (event: any) => {
        setPreviewImage(URL.createObjectURL(event.target.files[0]));
        changeDialogFields({
            imageFile: event.target.files[0],
            image: event.target.files[0]?.name,
        });
    };

    const addActionColumn = () => {
        changeGridColumns([
            ...columns,
            {
                field: 'actions',
                type: 'actions',
                headerName: 'Actions',
                width: 100,
                cellClassName: 'actions',
                getActions: (id: any) => {
                    return [
                        <GridActionsCellItem
                            icon={<OpenInNewIcon />}
                            label="Open brand"
                            className="textPrimary"
                            disabled={disableOpenInAction}
                            onClick={() => {
                                history.push(
                                    generatePath('/admin/' + pathForInternalModule + '/:id', {
                                        id: id.row.id,
                                    })
                                );
                            }}
                            color="inherit"
                        />,
                        <GridActionsCellItem
                            icon={<EditIcon />}
                            label="Edit"
                            className="textPrimary"
                            onClick={() => handleEditClick(id)}
                            color="inherit"
                        />,
                        <GridActionsCellItem
                            icon={<DeleteIcon />}
                            label="Delete"
                            onClick={() => handleDeleteClick(id)}
                            color="inherit"
                        />,
                    ];
                },
            } as GridEnrichedColDef,
        ]);
    };

    return (
        <>
            <Box p={'10px'} component="div">
                <Box component="div" display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                    <Typography className={'dataGridTitle'} variant="h4">
                        {moduleTitle}
                    </Typography>

                    {!!parentId && (
                        <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                            onClick={() => {
                                let path = '/admin/brands';
                                if (module === 'packages') {
                                    path += '/models/' + model?.brand_car_id;
                                }
                                history.push(path);
                            }}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                    )}
                </Box>
                {module === 'models' && brand !== null && (
                    <Box component="div" m="10px" display={'flex'} alignItems="center">
                        {'Brand: ' + brand.name}
                        <img
                            src={brand.image}
                            alt=""
                            style={{
                                maxHeight: '55px',
                                marginLeft: '10px',
                            }}
                        />
                    </Box>
                )}

                {module === 'packages' && model !== null && (
                    <Box component="div" m="10px" display={'flex'} alignItems="center">
                        {'Brand: ' + model.name}
                    </Box>
                )}

                <Box className={'dataGridBox'} component="div">
                    <IconButton color="primary" aria-label="upload picture" component="span" onClick={handleAddClick}>
                        <AddBoxIcon />
                    </IconButton>
                    <DataGrid className="dataGrid" rows={dataSource} columns={columns} editMode="row" />
                </Box>
            </Box>

            <Dialog open={dialogOpen} onClose={handleCloseDialog}>
                <DialogTitle>{moduleTitle}</DialogTitle>
                <form onSubmit={handleSubmit}>
                    <StyledDialogContent>
                        {dialogEditFields.map((field, index) => {
                            if (field.field !== 'inputImage' && !field.hide)
                                if (field.type !== 'number')
                                    return (
                                        <TextField
                                            key={index}
                                            label={field.label}
                                            name={field.name}
                                            type={field.type}
                                            disabled={field.disabled}
                                            defaultValue={dialogFields[field.name]}
                                            onChange={handleInput}
                                        />
                                    );
                                else
                                    return (
                                        <CustomNumberFormt
                                            key={index}
                                            value={dialogFields[field.name]}
                                            name={field.name}
                                            label={field.label}
                                        />
                                    );
                        })}
                    </StyledDialogContent>
                    <img className="previewImage" src={previewImage} alt="" />

                    <StyledDialogActions>
                        <Button variant="contained" component="label">
                            Upload File
                            <input type="file" hidden onChange={onFileChange} />
                        </Button>
                        <div>
                            <Button onClick={handleCloseDialog}>Cancel</Button>
                            <Button type="submit">Send</Button>
                        </div>
                    </StyledDialogActions>
                </form>
            </Dialog>
        </>
    );
};

export { CarsDataGrid };

const StyledDialogActions = styled(DialogActions)`
    justify-content: space-between;
`;

const StyledDialogContent = styled(DialogContent)`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
`;
