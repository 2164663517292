import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';
import { usersService } from '../api/users';

export type User = {
    id: string;
    name: string;
    email: string;
    is_admin?: boolean;
};

export type UserProps = {
    name: string;
    email: string;
    password: string;
};

type userSliceInitialState = {
    loading: boolean;
    users: User[];
    user: User | null;
    me: User | null;
    error: string | null;
};

const initialState: userSliceInitialState = {
    loading: false,
    users: [],
    user: null,
    me: null,
    error: null,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setLoading: (state, { payload }) => {
            if (payload) {
                console.log('loading user');
            }
            state.loading = payload;
        },
        setUsers: (state, { payload }) => {
            state.users = payload;
        },
        setUser: (state, { payload }) => {
            state.user = payload;
        },
        setMe: (state, { payload }) => {
            state.me = payload;
        },
        setError: (state, { payload }) => {
            state.error = payload;
        },
    },
});

export const { setLoading, setUsers, setUser, setMe, setError } = userSlice.actions;
//получить текущего пользователя
export const getMe = createAsyncThunk('/users/profile', async (_, { dispatch }) => {
    dispatch(setLoading(true));
    const response = await usersService.getMe();
    if (response.status === 200 && !response.data.error) {
        dispatch(setMe(response.data));
    } else if (response.data.error) {
        dispatch(setError(response.data.error));
    }
    dispatch(setLoading(false));
});
//получить список всех пользователей
export const getUsers = createAsyncThunk('/users', async (_, { dispatch }) => {
    dispatch(setLoading(true));
    const response = await usersService.getListUsers();
    if (response.status === 200) {
        dispatch(setUsers(response.data));
    }
    dispatch(setLoading(false));
});
//получить указаного пользователя по id
export const getUser = createAsyncThunk('/users', async (id: string, { dispatch }) => {
    dispatch(setLoading(true));
    const response = await usersService.getUser(id);
    if (response.status === 200) {
        dispatch(setUsers(response.data));
    }
    dispatch(setLoading(false));
});
//создать пользователя
export const createUser = createAsyncThunk('users/create', async (params: UserProps, { dispatch }) => {
    try {
        dispatch(setLoading(true));
        await usersService.createUser(params);
        dispatch(getUsers());
    } catch (e) {
        console.log(e);
    }
});
//обновить пользователя
export const updateUser = createAsyncThunk('users/update', async (params: User, { dispatch }) => {
    try {
        dispatch(setLoading(true));
        await usersService.updateUser(params);
        dispatch(getUsers());
    } catch (e) {
        console.log(e);
    }
});
//удалить пользователя
export const deleteUser = createAsyncThunk('users/delete', async (id: string, { dispatch }) => {
    try {
        dispatch(setLoading(true));
        await usersService.deleteUser(id);
        dispatch(getUsers());
    } catch (e) {
        console.log(e);
    }
});

export const selectLoading = (state: RootState) => state.user.loading;
export const selectUsers = (state: RootState) => state.user.users;
export const selectUser = (state: RootState) => state.user.user;
export const selectMe = (state: RootState) => state.user.me;

export default userSlice.reducer;
