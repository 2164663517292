const currentName = (item: any, language: any) => {
    switch (language) {
      case 'dk':
        return item?.name_dk;
      case 'en':
        return item?.name_eng;
      default:
        return item?.name_dk;
    }
  };

export {currentName}