import { Image } from '../store/imageSlice';
import { baseURLconfig, httpClient } from '../httpClient/httpClient';

export type BrandsCreateRequestValuesType = {
    name: string;
    image: File;
};
export type BrandsUpdateRequestValuesType = {
    id: string;
    name: string;
    image: string;
};

export type ModelsCreateRequestValuesType = {
    name: string;
    image: string;
    brand_car_id: string;
};

export type ModelsUpdateRequestValuesType = {
    id: string;
    name: string;
    image: string;
    brand_car_id: string;
};

export type PackagiesCreateRequestValuesType = {
    name: string;
    image: string;
    length: number;
    height: number;
    width_read_door: number;
    width_sliding_door: number;
    model_car_id: string;
};

export type PackagiesUpdateRequestValuesType = {
    name: string;
    image: string;
    length: number;
    height: number;
    width_read_door: number;
    width_sliding_door: number;
    model_car_id: string;
    id: string;
};

export const carsService = {
    getAllBrandsCar() {
        return httpClient.get('/brands');
    },
    getBrand(id: string) {
        return httpClient.get('/brands/' + id);
    },
    createBrand(params: FormData) {
        return httpClient.post('/brands/create', params);
    },
    deleteBrand(id: string) {
        return httpClient.post('/brands/delete', id);
    },
    updateBrands(params: FormData) {
        return httpClient.post('/brands/update', params);
    },
    getBrandModels(id: string) {
        return httpClient.get('/brands/' + id + '/models');
    },
    getModel(id: string) {
        return httpClient.get('/models/' + id);
    },
    createModels(params: FormData) {
        return httpClient.post('/models/create', params);
    },
    updateModels(params: FormData) {
        return httpClient.post('/models/update', params);
    },
    deleteModels(id: string) {
        return httpClient.post('/models/delete', { id: id });
    },
    getModelPackagies(id: string) {
        return httpClient.get('/models/' + id + '/packagies');
    },
    createPackagies(params: FormData) {
        return httpClient.post('/packagies/create', params);
    },
    updatePackagies(params: FormData) {
        return httpClient.post('/packagies/update', params);
    },
    deletePackagies(id: string) {
        return httpClient.post('/packagies/delete', { id: id });
    },
    getImage(params: Image) {
        return httpClient.get('/images/' + params.module + '/' + params.nameImage);
    },
    getImageUrl(params: Image) {
        return baseURLconfig + '/images/' + params.module + '/' + params.nameImage;
    },
    getPackagesParams(id: string) {
        return httpClient.get('/packagies/' + id);
    },
};
