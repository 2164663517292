import { Box, IconButton, styled } from '@mui/material';
import React, { useEffect } from 'react';
import { useError } from '../../hooks/useError';
import CloseIcon from '../../icons/Close.svg';

const ErrorPopup = () => {
  const { 
    errorMessage, 
    setErrorMessage, 
    openError, 
    setOpenError 
  } = useError();
  
  useEffect(() => {
    const timer = setTimeout(() => {
      setErrorMessage('')
      setOpenError(false);
      
    }, 5000);
    return () => clearTimeout(timer);
  }, [openError, setErrorMessage, setOpenError ]);

  return (
    <>
      {openError ? (
        <ErrorBox>
          <Box
            component="div"
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <IconButton
              style={{ height: '12px', width: '12px' }}
              onClick={() => setOpenError(false) }
            >
              <Box
                component="img"
                src={CloseIcon}
                alt=""
                style={{ height: '12px', width: '12px' }}
              />
            </IconButton>
          </Box>
          <ErrorText>{errorMessage}</ErrorText>
        </ErrorBox>
      ) : (
        <></>
      )}
    </>
  );
};

const ErrorBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  minWidth: '400px',
  background: '#D66071',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  boxShadow:
    '0px 8px 16px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(0, 0, 0, 0.08)',
  borderRadius: '12px',
  left: '24px',
  top: '163px',
  padding: '16px 12px 16px 12px',
  zIndex: '99999',
}));

const ErrorText = styled(Box)(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '16px',
  letterSpacing: '0.1px',
  color: '#FCFDFD',
}));

export default ErrorPopup;
