import React, {ReactElement} from 'react';
import {Box, Button, css, Dialog, styled, SxProps} from "@mui/material";
import {useTranslation} from "react-i18next";

const ButtonBox = styled(Box)`
  display: flex;
  gap: 16px;
`;

const StyledButton = styled(Button)(({ theme }) => css`
  background: ${theme.palette.secondary.main};
  border-radius: 14px;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  text-transform: capitalize;
  color: #FCFDFD;
  width: 148px;
  padding: 16px;
  
  &:hover {
    background: #B3C3CF;
  }
`);

interface IProps {
  open: boolean;
  ContentComponent: ReactElement;
  okText?: string;
  cancelText?: string;
  okStyle?: SxProps;
  cancelStyle?: SxProps;
  handleOK: () => void;
  handleCancel: () => void;
  loading?: boolean;
}

const DialogBinary = ({
  open,
  ContentComponent,
  okText,
  cancelText,
  okStyle = {},
  cancelStyle = {},
  handleOK,
  handleCancel,
  loading,
}: IProps) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          background: '#FCFDFD',
          boxShadow: '0px 4px 18px rgba(0, 0, 0, 0.1)',
          borderRadius: '14px',
          padding: '40px 56px',
        }
      }}
    >
      {
        ContentComponent
        ? ContentComponent
          : null
      }
      <ButtonBox>
        <StyledButton
          onClick={handleCancel}
          disabled={loading}
          sx={cancelStyle}
        >
          { cancelText || t('Dialog.cancel') }
        </StyledButton>
        <StyledButton
          onClick={handleOK}
          sx={okStyle}
          disabled={loading}
        >
          { okText || t('Equip.presetButton.button') }
        </StyledButton>
      </ButtonBox>
    </Dialog>
  );
};

export default DialogBinary;
