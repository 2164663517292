import { Component, ShelfComponent } from '../../types/component';
import { Packagies } from '../store/packagieSlice';
import { ResourceType } from '../../types/resources';
import { Side } from '../../types/general';
import { Mode, ShelfType } from '../../types/scene';
import { Constraints } from '../../types/app3d';

export type SceneEventsMap = {
    loadCar: (e: { preset: Packagies }) => Promise<void>;

    injectResource: (e: { url: string; resourceType: ResourceType.GLTF }) => void;
    injectComponent: (e: { component: Component; parentUuid?: string }) => void;
    validateInjectComponent: (e: { component: Component; parentUuid?: string }) => Promise<{isValid: boolean, message: string}>;
    removeComponent: (e: { component: Component; parentUuid?: string }) => void;
    requestBlackList: (e: ShelfComponent[]) => Promise<ShelfType[]>;
    loadResources: () => Promise<void>;
    setMode: (e: { mode: Mode }) => void;
    setShow: () => Promise<void>;
    resetState: () => Promise<void>;
    requestConstraints: (e: { side: Side }) => Promise<Constraints>;
    requestShelfPosition: (e: { uuid: string; parentUuid: string }) => void;
    requestPreviews: () => Promise<void>;
};
export const app3dService: SceneEventsMap = {
    async loadCar(e) {
        return await sceneEventProvider.emit('loadCar', e);
    },
    async loadResources() {
        return await sceneEventProvider.emit('loadResources');
    },
    requestBlackList(e) {
        return sceneEventProvider.emit('requestBlackList', e);
    },
    setMode(e) {
        return sceneEventProvider.emit('setMode', e);
    },
    requestConstraints(e) {
        return sceneEventProvider.emit('requestConstraints', e);
    },
    setShow() {
        return sceneEventProvider.emit('setShow');
    },
    injectComponent(e) {
        return sceneEventProvider.emit('injectComponent', e);
    },
    validateInjectComponent(e) {
        return sceneEventProvider.emit("validateInjectComponent", e);
    },
    resetState() {
        return sceneEventProvider.emit('resetState');
    },
    removeComponent(e) {
        return sceneEventProvider.emit('removeComponent', e);
    },
    injectResource(e) {
        return sceneEventProvider.emit('injectResource', e);
    },
    requestShelfPosition(e) {
        return sceneEventProvider.emit('requestShelfPosition', e);
    },
    requestPreviews() {
        return sceneEventProvider.emit('requestPreviews');
    },
};

type ValidEventTypes = string | object;
export type EventNames<T extends ValidEventTypes> = T extends string ? T : keyof T;

export type ArgumentMap<T extends object> = {
    [K in keyof T]: T[K] extends (...args: any[]) => Promise<void> | void
        ? Parameters<T[K]>
        : T[K] extends any[]
        ? T[K]
        : any[];
};

export type EventListener<
    T extends ValidEventTypes,
    K extends EventNames<T>
> = T extends string
    ? (...args: any[]) => Promise<void>
    : (
          ...args: ArgumentMap<Exclude<T, string>>[Extract<K, keyof T>]
      ) => Promise<void> | void;

export type EventArgs<T extends ValidEventTypes, K extends EventNames<T>> = Parameters<
    EventListener<T, K>
>;

type DetailType<E extends ValidEventTypes, T extends EventNames<E>> = {
    args: EventArgs<E, T>;
    callback: ResolveLike<void>;
};

async function asyncDispatch<E extends string, T extends EventNames<E>>(
    eventName: T,
    args: EventArgs<E, T>
): Promise<void> {
    return new Promise<void>((resolve) => {
        // setTimeout(() => {
        const e = new CustomEvent<DetailType<E, T>>(eventName, {
            detail: { args, callback: resolve },
        });
        document.dispatchEvent(e);
        // });
    });
}
type ResolveLike<T> = (value: T | PromiseLike<T>) => void;

export class EventProvider<EventTypes extends ValidEventTypes> {
    public on<T extends EventNames<EventTypes>>(
        eventName: T,
        fn: EventListener<EventTypes, T>
    ): this {
        document.addEventListener(eventName, async (e) => {
            if (e instanceof CustomEvent) {
                const { callback, args } = e.detail;
                const ans = await fn.apply(null, args);
                if (callback) callback(ans);
            } else {
                //@ts-ignore
                await fn(e);
            }
        });
        return this;
    }
    public async emit<T extends EventNames<EventTypes>>(
        funcNameToCall: T,
        ...argsForFunc: EventArgs<EventTypes, T>
    ): Promise<any> {
        return await asyncDispatch(funcNameToCall, argsForFunc);
    }
}

const sceneEventProvider = new EventProvider<SceneEventsMap>();
