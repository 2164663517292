import { Loader, ResourseLoaderParams } from '../../../types/resources';

export class ImageResourceLoader implements Loader {
    public loadResource({ url, resource }: ResourseLoaderParams): Promise<void> {
        const img = new Image();
        return new Promise((resolve, reject) => {
            img.onload = () => {
                resource.loaded = true;
                resource.content = img.src;
                resource.error = undefined;
                resolve();
            };
            img.onerror = () => {
                resource.loaded = true;
                resource.content = undefined;
                resource.error = new Error(`Unable to load ${resource.type} resource. By address: ${url}`);
                reject();
            };
            img.src = url;
        });
    }
}
