import { DataTexture, ImageLoader } from "three";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import { ThreeResourceLoader } from "./ThreeResourceLoader";
import {
  GltfResource,
  HdrTextureResource,
  ResourseLoaderParams,
  Loader,
  Resource,
  ResourceType,
  RequestParams,
  ImageResource,
} from "../../../types/resources";
import { ImageResourceLoader } from "./ImageResourceLoader";

interface PromiseFulfilledResult<T> {
  status: "fulfilled";
  value: T;
  reason: Record<string, string>;
}

export class ResourceManager {
  // private readonly assetsLoader: PixiResourcesLoader;
  private readonly resources: Record<string, Resource> = {};
  private readonly loaders: Record<"image" | "three", Loader> = {
    image: new ImageResourceLoader(),
    three: new ThreeResourceLoader(),
  };

  public appendResource(
    url: string,
    type: ResourceType,
    label?: string,
    force: boolean = false
  ): void {
    if (!label) label = url;
    if (label in this.resources && !force) return;
    this.resources[label] = {
      loaded: false,
      url,
      type,
    };
  }

  public async loadResources(requestParams?: RequestParams): Promise<void> {
    return await Promise.allSettled(
      Object.entries(this.resources).map(([label, resource]): Promise<void> => {
        if (!resource.loaded) {
          return this.loadResource({
            url: resource.url,
            resource,
            requestParams,
          });
        } else return Promise.resolve();
      })
    ).then((values) =>
      console.log(
        values
          .filter((value) => value.status === "rejected")
          .map(
            (value) => (value as PromiseFulfilledResult<void>).reason.message
          )
      )
    );
  }

  private loadResource(params: ResourseLoaderParams): Promise<void> {
    if (params.resource.type === ResourceType.IMAGE) {
      return this.loaders.image.loadResource(params);
    } else {
      return this.loaders.three.loadResource(params);
    }
  }

  private getResourceByUrl<T extends Resource>(url: string): T | undefined {
    const resource = this.resources[url];
    return resource as T;
  }
  private getResourceContentByUrl<T extends Resource>(
    url: string
  ): Required<T>["content"] {
    const resource = this.getResourceByUrl<T>(url);
    if (!resource?.content)
      throw new Error(`Resource content for ${url} not found`);
    return resource?.content;
  }

  public getHdrTextureUrl(url: string): DataTexture {
    return this.getResourceContentByUrl<HdrTextureResource>(url);
  }

  public getGltfByUrl(url: string): GLTF {
    return this.getResourceContentByUrl<GltfResource>(url);
  }

  public getImageByLabel(label: string): string {
    return this.getResourceContentByUrl<ImageResource>(label);
  }
}
