import React from "react";
import { Page, Text, View, Document, Image } from "@react-pdf/renderer";
import PDFlog from "./Logo.png";
import Line from "./Line.png";
import { useHelp } from "../../hooks/useHelp";
import { ImageEntity } from "../../types/app3d";
import styles from "./style";

interface PDFgenerateInterface {
  preset: any;
  user: any;
  code: any;
  accessories: any;
  sumAccessories: any;
  brand: any;
  model: any;
  components: any;
  overviewPreview: ImageEntity;
  previews: Array<ImageEntity>;
  totalSum: any;
  isAssemble: any;
  unitPresets: any;
}

const GeneratePdf = (props: PDFgenerateInterface) => {
  const { currency, language } = useHelp();
  const preset = props.preset;
  const user = props.user;
  const sum = props.sumAccessories;
  const code = props.code;
  const totalSum = props.totalSum;
  const accessories = props.accessories;
  const components = props.components;
  const date = new Date().toLocaleDateString();
  const car_model = props.brand + " " + props.model;
  let images: ImageEntity[] = [];
  const usedKeys: string[] = [];
  props.previews.forEach((preview) => {
    if (preview.label !== "overview" && !usedKeys.includes(preview.label)) {
      images.push(preview);
      usedKeys.push(preview.label);
    }
  });
  const isAssembly = props.isAssemble;
  const overviewImage = props.overviewPreview;
  const unitPresets = props.unitPresets;
  const currencyPrice = (item: any) => {
    switch (currency) {
      case "gbp":
        return item?.price_gbp + " GBP";
      case "dkk":
        return item?.price_dkk + " DKK";
      case "eur":
        return item?.price_eur + " EUR";
      case "nok":
        return item?.price_nok + " NOK";
      case "sek":
        return item?.price_sek + " SEK";
      default:
        return item?.price_dkk + " DKK";
    }
  };
  const currencyName = (item: any) => {
    switch (language) {
      case "dk":
        return item?.name_dk;
      case "en":
        return item?.name_eng;
      default:
        return item?.name_dk;
    }
  };

  return (
    <>
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.header} fixed>
            <Image src={PDFlog} style={styles.logo} />
          </View>
          <View style={{ height: "698px" }}>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "30px",
                marginBottom: "80px",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              <View style={{ flexDirection: "column", flex: 2 }}>
                <Text style={styles.userTitle}>Kunde ID: </Text>
                <Text style={styles.userItem}>{user?.email}</Text>
              </View>
              <View style={{ flexDirection: "column", flex: 2 }}>
                <Text style={styles.userTitle}>Projekt ID:</Text>
                <Text style={styles.userItem}>{preset?.name}</Text>
              </View>
              <View style={{ flexDirection: "column", flex: 2 }}>
                <Text style={styles.userTitle}>Kode:</Text>
                <Text style={styles.userItem}>{code}</Text>
              </View>
            </View>
            <View style={styles.presetSection}>
              <Text style={styles.presetSectionTitle}>Bilindretning</Text>
              <Text style={styles.presetSectionModelCar}>
                {"Bil:  " + car_model}
              </Text>
              <View style={styles.tableFirstRow}>
                <Text style={styles.firstTableHeaderText}>Position </Text>
                <Text style={styles.secondPresetTableHeaderText}>Navn</Text>
                <Text style={styles.thirdTableHeaderText}>Antal</Text>
                <Text style={styles.fourthdTableHeaderText}>Pris</Text>
              </View>
              {preset.unit_presets?.map((item: any, index: any) => (
                <View style={styles.tableRow} key={index}>
                  <Text style={styles.firstTableHeaderText}>
                    {item.position_in_auto}
                  </Text>
                  <Text style={styles.secondPresetTableHeaderText}>
                    {item.name}
                  </Text>
                  <Text style={styles.thirdTableHeaderText}>1</Text>
                  <Text style={styles.fourthdTableHeaderText}>
                    {isAssembly
                      ? currencyPrice(item.total_sum_assembly)
                      : currencyPrice(item.total_sum)}
                  </Text>
                </View>
              ))}
              <View style={styles.accessoriesRow}>
                <Text style={styles.firstTableHeaderText}>Tilbehør:</Text>
                <Text style={styles.secondPresetTableHeaderText}></Text>
                <Text style={styles.thirdTableHeaderText}></Text>
                <Text style={styles.fourthdTableHeaderText}>{sum}</Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.firstTableHeaderText}>
                  Vejl. pris total ex. moms:
                </Text>
                <Text style={styles.secondPresetTableHeaderText}></Text>
                <Text style={styles.thirdTableHeaderText}></Text>
                <Text style={styles.fourthdTableHeaderText}>{totalSum}</Text>
              </View>
            </View>
            <View>
              {overviewImage ? (
                <Image
                  src={overviewImage.src}
                  style={{
                    width: "620px",
                    position: "absolute",
                    left: "-100px",
                  }}
                />
              ) : (
                <></>
              )}
            </View>
          </View>
          <View style={styles.footerSection} fixed>
            <Image
              src={Line}
              style={{ height: "3px", width: "100px", marginBottom: "8px" }}
            />
            <Text style={styles.footerText}>
              raaco bilindretning er enkel og gennemtænkt effektivitet til det
              professionelle mobile værksted. Et system i førsteklasses dansk
              kvalitet, opbygget af moduler med indbygget variation - lige til
              at montere i bilen.
            </Text>
            <View style={styles.footerContact}>
              <Text style={styles.footerContactText}> nordic@raaco.com </Text>
              <Text style={styles.footerContactText}>Tel: +45 54 88 84 44</Text>
              <Text style={styles.footerDate}>{date}</Text>
            </View>
          </View>
        </Page>

        <Page size="A4" style={styles.page}>
          <View style={styles.header} fixed>
            <Image src={PDFlog} style={styles.logo} />
          </View>
          <View style={{ height: "698px" }}>
            <View style={styles.componentnsSection}>
              <Text style={styles.tableTitle}>Stykliste</Text>
              <View style={styles.tableFirstRow}>
                <Text style={styles.firstComponentsTableHeaderText}>Navn </Text>
                <Text style={styles.secondTableHeaderText}>Varenummer</Text>
                <Text style={styles.thirdTableHeaderText}>Antal</Text>
                <Text style={styles.fourthdTableHeaderText}>Stykpris</Text>
              </View>
              {components?.map((item: any, index: any) => (
                <View style={styles.tableRow} key={index}>
                  <Text style={styles.firstComponentsTableHeaderText}>
                    {currencyName(item)}
                  </Text>
                  <Text style={styles.secondTableHeaderText}>
                    {item.item_number}
                  </Text>
                  <Text style={styles.thirdTableHeaderText}>
                    {item.quantity}
                  </Text>
                  <Text style={styles.fourthdTableHeaderText}>
                    {currencyPrice(item)}
                  </Text>
                </View>
              ))}
            </View>
          </View>
          <View style={styles.footerSection} fixed>
            <Image
              src={Line}
              style={{ height: "3px", width: "100px", marginBottom: "8px" }}
            />
            <Text style={styles.footerText}>
              raaco bilindretning er enkel og gennemtænkt effektivitet til det
              professionelle mobile værksted. Et system i førsteklasses dansk
              kvalitet, opbygget af moduler med indbygget variation - lige til
              at montere i bilen.
            </Text>
            <View style={styles.footerContact}>
              <Text style={styles.footerContactText}> nordic@raaco.com </Text>
              <Text style={styles.footerContactText}>Tel: +45 54 88 84 44</Text>
              <Text style={styles.footerDate}>{date}</Text>
            </View>
          </View>
        </Page>

        <Page size="A4" style={styles.page}>
          <View style={styles.header} fixed>
            <Image src={PDFlog} style={styles.logo} />
          </View>
          <View style={{ height: "698px" }}>
            <View style={styles.componentnsSection}>
              {unitPresets?.map((item: any, index: any) => (
                <View style={styles.componentnsSection} key={index}>
                  <Text style={styles.tableTitle}>
                    {"Stykliste " + item.position_in_auto}
                  </Text>
                  <View style={styles.tableFirstRowNoStripe}>
                    <Text style={styles.firstComponentsTableHeaderText1}>
                      Navn{" "}
                    </Text>
                    <Text style={styles.secondTableHeaderText1}>
                      Varenummer
                    </Text>
                    <Text style={styles.thirdTableHeaderText1}>Antal</Text>
                    <Text style={styles.fourthdTableHeaderText1}>Stykpris</Text>
                  </View>
                  {item.components?.map((item: any, index: any) => (
                    <View style={styles.tableRow} key={index}>
                      <Text style={styles.firstComponentsTableHeaderText}>
                        {currencyName(item)}
                      </Text>
                      <Text style={styles.secondTableHeaderText}>
                        {item.item_number}
                      </Text>
                      <Text style={styles.thirdTableHeaderText}>
                        {item.quantity}
                      </Text>
                      <Text style={styles.fourthdTableHeaderText}>
                        {currencyPrice(item)}
                      </Text>
                    </View>
                  ))}
                </View>
              ))}
            </View>
            <View style={styles.componentnsSection}>
              <Text style={styles.tableTitle}>Tilbehør</Text>
              <View style={styles.tableFirstRowNoStripe}>
                <Text style={styles.firstComponentsTableHeaderText1}>
                  Navn{" "}
                </Text>
                <Text style={styles.secondTableHeaderText1}>Varenummer</Text>
                <Text style={styles.thirdTableHeaderText1}>Antal</Text>
                <Text style={styles.fourthdTableHeaderText1}>Stykpris</Text>
              </View>
              {accessories?.map((item: any, index: any) => (
                <View style={styles.tableRow} key={index}>
                  <Text style={styles.firstComponentsTableHeaderText}>
                    {currencyName(item)}
                  </Text>
                  <Text style={styles.secondTableHeaderText}>
                    {item.item_number}
                  </Text>
                  <Text style={styles.thirdTableHeaderText}>
                    {item.quantity}
                  </Text>
                  <Text style={styles.fourthdTableHeaderText}>
                    {currencyPrice(item)}
                  </Text>
                </View>
              ))}
            </View>
          </View>
          <View style={styles.footerSection} fixed>
            <Image
              src={Line}
              style={{ height: "3px", width: "100px", marginBottom: "8px" }}
            />
            <Text style={styles.footerText}>
              raaco bilindretning er enkel og gennemtænkt effektivitet til det
              professionelle mobile værksted. Et system i førsteklasses dansk
              kvalitet, opbygget af moduler med indbygget variation - lige til
              at montere i bilen.
            </Text>
            <View style={styles.footerContact}>
              <Text style={styles.footerContactText}> nordic@raaco.com </Text>
              <Text style={styles.footerContactText}>Tel: +45 54 88 84 44</Text>
              <Text style={styles.footerDate}>{date}</Text>
            </View>
          </View>
        </Page>

        {images?.map((image, index) => (
          <Page size="A4" style={styles.page} key={index}>
            <View style={styles.header} fixed>
              <Image src={PDFlog} style={styles.logo} />
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                height: "698px",
              }}
              fixed
            >
              <Text style={styles.imageTitle}>{"Stykliste " + image.name}</Text>
              <Image src={image.src} />
            </View>
            <View style={styles.footerSection} fixed>
              <Image
                src={Line}
                style={{ height: "3px", width: "100px", marginBottom: "8px" }}
              />
              <Text style={styles.footerText}>
                raaco bilindretning er enkel og gennemtænkt effektivitet til det
                professionelle mobile værksted. Et system i førsteklasses dansk
                kvalitet, opbygget af moduler med indbygget variation - lige til
                at montere i bilen.
              </Text>
              <View style={styles.footerContact}>
                <Text style={styles.footerContactText}> nordic@raaco.com </Text>
                <Text style={styles.footerContactText}>
                  Tel: +45 54 88 84 44
                </Text>
                <Text style={styles.footerDate}>{date}</Text>
              </View>
            </View>
          </Page>
        ))}
      </Document>
    </>
  );
};
export default GeneratePdf;
