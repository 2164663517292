import { RackComponent, isRackComponent } from '../types/component';
import { Side } from '../types/general';
import { UnitPreset } from '../types/preset';

export function getRackComponentFromPreset(preset: UnitPreset): RackComponent | null {
    const component = preset.components.find((component) => isRackComponent(component));
    return (component as RackComponent) ?? null;
}

export function positionToSide<T extends string>(position: T): Side {
    return position.startsWith('l') ? 'left' : 'right';
}

export function isEmpty(obj: Record<any, any>): boolean {
    for (let _ in obj) {
        return false;
    }
    return true;
}
