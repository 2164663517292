import { CameraState } from '../SceneWrapper';
import * as THREE from 'three';
import { Mode } from '../../../types/scene';

const cameraState = new Map<Mode, CameraState>();
cameraState.set('main', { fov: 60, position: new THREE.Vector3(-3, 2, 3) });
cameraState.set('interior', { fov: 50 });
cameraState.set('shelves', { fov: 60 });
cameraState.set('storage', { fov: 60 });
cameraState.set('screenshot', { fov: 50 });
export default cameraState;
