import React from 'react';
import {Box, css, styled, Typography} from "@mui/material";
import {IPresetData} from "./index";
import {useTranslation} from "react-i18next";

const Wrapper = styled(Box)`
  width: 100%;
  margin-bottom: 24px;
`;

const TextNormal = styled(Typography)(({ theme }) => css`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.1px;
  line-height: 22px;
  color: ${theme.palette.secondary.main};
  margin-bottom: 8px;
  
  > span {
    font-weight: 600;
    font-size: 18px;
  }
`);

const TextPresetName = styled(Typography)(({ theme }) => css`
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: ${theme.palette.primary.main};
  margin-bottom: 24px;
`);

interface IProps {
  data: IPresetData;
}

const DeleteMessage = ({ data }: IProps) => {
  const { presetName, creatorName, date } = data;
  const { t } = useTranslation();

  return (
    <Wrapper>
      <TextNormal>
        {t('Extras.dialog.ask')}
      </TextNormal>
      <TextPresetName>
        { presetName }
      </TextPresetName>
      {
        creatorName
        ? <TextNormal>
            {t('Extras.dialog.createdBy')} <span>{creatorName}</span>{date ? ` ${t('Extras.dialog.on')} ${date}` : ''}
          </TextNormal>
          : null
      }
    </Wrapper>
  );
};

export default DeleteMessage;
