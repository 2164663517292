import * as THREE from 'three';
import BaseFont from 'three/examples/fonts/helvetiker_regular.typeface.json';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js';
import { Line2 } from 'three/examples/jsm/lines/Line2';
import { Side } from '../../types/general';
import { ShelfComponent } from '../../types/component';
import { ShelfType } from '../../types/scene';
let bbox = new THREE.Box3();

export function isRackFeatureHuge(feature: THREE.Mesh) {
    bbox.setFromObject(feature);
    return bbox.max.z - bbox.min.z > 0.1;
}

export function setMaterialForHugeRackFeature(feature: THREE.Mesh) {
    feature.material = new THREE.MeshStandardMaterial({
        metalness: 1,
        roughness: 0.3,
        color: new THREE.Color(
            0.890855610370636,
            0.8705766797065735,
            0.9114075899124146
        ),
        flatShading: false,
        fog: true,
        envMapIntensity: 0.3,
    });
    feature.material.needsUpdate = true;
}

export function createText(text: string, textSize = 0.05): THREE.Mesh {
    const loader = new FontLoader();
    let font = loader.parse(BaseFont);
    const textGeo = new TextGeometry(text, {
        bevelThickness: 0,
        bevelSize: 0,
        font: font,
        size: textSize,
        height: 0,
        curveSegments: 12,
        bevelEnabled: true,
    });

    const textMaterial = new THREE.MeshStandardMaterial({
        color: 0x000000,
        flatShading: false,
        fog: false,
        metalness: 0,
        roughness: 0,
        lightMapIntensity: 0,
    });
    return new THREE.Mesh(textGeo, textMaterial);
}
const material = new LineMaterial({
    color: 0xffffff,
    linewidth: 0.015,
    vertexColors: true,
    worldUnits: true,
    dashed: false,
    alphaToCoverage: true,
});

type LineParams = {
    lineWidth: number;
    direction: 'vertical' | 'horisontal';
    label: string;
    side: Side;
};

export function createLine({
    lineWidth,
    direction,
    label,
    side,
}: LineParams): THREE.Group {
    const lineGroup = new THREE.Group();
    let points = [];
    points.push(new THREE.Vector3(0, 0, 0));
    points.push(new THREE.Vector3(0, lineWidth, 0));
    let coords: Array<number> = [];
    points.forEach((p) => coords.push(p.x, p.y, p.z));
    const line = new Line2(new LineGeometry().setPositions(coords), material);
    line.computeLineDistances();
    line.scale.set(1, 1, 1);
    lineGroup.add(line);
    points.length = 0;
    coords.length = 0;
    points.push(new THREE.Vector3(-0.02, 0, 0));
    points.push(new THREE.Vector3(0.02, 0, 0));
    points.forEach((p) => coords.push(p.x, p.y, p.z));
    const line2 = new Line2(new LineGeometry().setPositions(coords), material);
    lineGroup.add(line2);

    points.length = 0;
    coords.length = 0;
    points.push(new THREE.Vector3(-0.02, lineWidth, 0));
    points.push(new THREE.Vector3(0.02, lineWidth, 0));
    points.forEach((p) => coords.push(p.x, p.y, p.z));
    const line3 = new Line2(new LineGeometry().setPositions(coords), material);
    lineGroup.add(line3);
    if (direction === 'horisontal') lineGroup.rotateZ(Math.PI / 2);

    const text = createText(`${label}`);
    text.position.y += lineWidth / 2;
    bbox.setFromObject(text);
    if (direction === 'horisontal') {
        text.position.x += 0.05;
        text.position.y += Math.abs(bbox.max.y - bbox.min.y);
        text.rotateZ(-Math.PI / 2);
    } else {
        text.position.x +=
            side === 'left' ? -(0.05 + Math.abs(bbox.max.x - bbox.min.x)) : 0.05;
    }
    lineGroup.add(text);
    return lineGroup;
}

export function getShelfType(shelf: Partial<ShelfComponent>): ShelfType {
    if (!shelf.name_eng || !shelf.glb_model) return 'none';
    let type: ShelfType | null = null;
    if (shelf.name_eng.includes('Table top')) {
        type = 'cover';
    } else {
        let cuts = shelf.glb_model.split('_');
        if (shelf.glb_model.includes('top')) {
            type = 'top';
        } else if (cuts.length > 1) {
            let possibleType = cuts[1].toLowerCase();
            switch (possibleType) {
                case 'box': {
                    let panelTypes = cuts[2].split('.');
                    if (panelTypes[0].toLowerCase() === 'top') {
                        type = 'top';
                    } else {
                        type = 'box';
                    }
                    break;
                }
                case 'shelf':
                case 'boxxser':
                case 'locker':
                case 'carrylite':
                case 'vertical':
                case 'top':
                case 'preset': {
                    type = possibleType;
                    break;
                }
                case 'panel': {
                    let panelTypes = cuts[2].split('.');
                    if (panelTypes[0].toLowerCase() === 'l') {
                        type = 'panel_l';
                    } else {
                        type = 'panel_r';
                    }
                    break;
                }
                default:
                    type = 'none';
            }
        } else {
            type = 'none';
        }
    }
    return type;
}
