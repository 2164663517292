import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { LoadingManager } from 'three';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
import {
    isGltfResource,
    Loader,
    ResourceType,
    ResourseLoaderParams,
} from '../../../types/resources';

export default function triggerScene(eventType: string, data: any) {
    const event = new CustomEvent(eventType, { detail: data });
    document.dispatchEvent(event);
}

const loadingManager = new LoadingManager();

export class ThreeResourceLoader implements Loader {
    public loadResource({
        url,
        resource,
        requestParams,
    }: ResourseLoaderParams): Promise<void> {
        if (resource.loaded) return Promise.resolve();
        const loader = isGltfResource(resource)
            ? new GLTFLoader(loadingManager)
            : new RGBELoader(loadingManager);
        if (requestParams) loader.setRequestHeader(requestParams);
        return new Promise((resolve, reject) =>
            loader.load(
                url,
                (content) => {
                    resource.loaded = true;
                    resource.content = content;
                    resource.error = undefined;
                    resolve();
                },
                () => undefined,
                () => {
                    resource.loaded = true;
                    resource.content = undefined;
                    resource.error = new Error(
                        `Unable to load ${resource.type} resource. By address: ${url}`
                    );
                    reject(resource.error);
                }
            )
        );
    }
}
