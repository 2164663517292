import React from 'react';
import DialogBinary from "../DialogBinary";
import {useHelp} from "../../hooks/useHelp";
import {EDialogs} from "../../types/general";
import DeleteMessage from "./DeleteMessage";
import {IPresetData} from "./index";
import useAppSelector from "../../hooks/useAppSelector";
import {useTranslation} from "react-i18next";

interface IProps {
  handleDelete: () => void;
  data: IPresetData;
}

const DialogDelete = ({ handleDelete, data }: IProps) => {
  const {
    openDialog,
    dialogName,
    setDialogName,
  } = useHelp();

  const open = dialogName === EDialogs.DELETE_PRESET && Boolean(openDialog);
  const loading = useAppSelector((state) => state.presets.loading);

  const { t } = useTranslation();

  const handleCancel = () => {
    setDialogName(EDialogs.PRESETS);
  }

  return (
    <DialogBinary
      open={open}
      ContentComponent={<DeleteMessage
        data={data}
      />}
      handleOK={handleDelete}
      handleCancel={handleCancel}
      loading={loading}
      okStyle={{
        background: '#CC384D',
        '&:hover': {
          background: '#D66071',
        }
      }}
      okText={t('Dialog.delete')}
    />
  );
};

export default DialogDelete;
