import { ControlsState } from "../SceneWrapper";
import { Mode } from "../../../types/scene";

const controlsState = new Map<Mode, ControlsState>();
controlsState.set("main", {
  enablePan: true,
  enableZoom: true,
  minAzimuthAngle: NaN,
  maxAzimuthAngle: NaN,
});
controlsState.set("interior", {
  minAzimuthAngle: -Math.PI / 2,
  maxAzimuthAngle: 0,
  minPolarAngle: Math.PI / 3,
  enableZoom: true,
  enablePan: false,
});
controlsState.set("screenshot", {
  minAzimuthAngle: -Math.PI / 2,
  maxAzimuthAngle: 0,
  minPolarAngle: Math.PI / 3,
  enableZoom: true,
  enablePan: false,
});
controlsState.set("shelves", {
  minAzimuthAngle: -Math.PI / 3 + Math.PI,
  maxAzimuthAngle: Math.PI / 3 + Math.PI,
  enableZoom: true,
  enablePan: false,
});
controlsState.set("storage", {
  minAzimuthAngle: -Math.PI / 3 + Math.PI,
  maxAzimuthAngle: Math.PI / 3 + Math.PI,
  enableZoom: true,
  enablePan: false,
});

controlsState.set("storageReverse", {
  minAzimuthAngle: -Math.PI / 3,
  maxAzimuthAngle: +Math.PI / 3,
  enableZoom: true,
  enablePan: false,
});
controlsState.set("shelvesReverse", {
  minAzimuthAngle: -Math.PI / 3,
  maxAzimuthAngle: +Math.PI / 3,
  enableZoom: true,
  enablePan: false,
});
export default controlsState;
