import { Constraints, ImageEntity } from "./app3d";
import { UnitPreset } from "./preset";

export type Mode =
  | "main"
  | "interior"
  | "storage"
  | "shelves"
  | "screenshot"
  | "shelvesReverse"
  | "storageReverse";

export type ShelfType =
  | "box"
  | "vertical"
  | "carrylite"
  | "locker"
  | "boxxser"
  | "panel"
  | "panel_l"
  | "panel_r"
  | "shelf"
  | "preset"
  | "top"
  | "none"
  | "cover";
export type App3dEvent = {
  providePreset: (data: Array<UnitPreset>) => void;
  provideConstraints: (data: Constraints) => void;
  provideIsDrag: (data: boolean) => void;
  provideEntityIdToRemove: (data: { uuid: string; parentUuid: string }) => void;
  provideShelfPosition: (data: {
    position: number;
    uuid: string;
    parentUuid: string;
  }) => void;
  provideRotate: (data: boolean) => void;
  provideMode: (data: { mode: Mode; blackList?: ShelfType[] }) => void;
  provideActiveComponentUuid: (data: string | null) => void;
  providePreviews: (data: Array<ImageEntity>) => void;
  provideErrorMessage: (data: string) => void;
};
export type Pointer = { x: number; y: number };

export const notDragableShelves = [
  "locker",
  "panel_l",
  "panel_r",
  "vertical",
  "top",
] as const;
const outerShelves = ["top", "panel_l", "panel_r"] as const;
export type NotDragableShelf = Extract<
  ShelfType,
  (typeof notDragableShelves)[number]
>;
export type OuterShelf = Extract<ShelfType, (typeof outerShelves)[number]>;

export function isNotDragableShelf(
  shelfType: ShelfType
): shelfType is NotDragableShelf {
  return notDragableShelves.includes(shelfType as any);
}
export function isOuterShelf(shelfType: ShelfType): shelfType is OuterShelf {
  return outerShelves.includes(shelfType as any);
}
