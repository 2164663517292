import React, { FC, useContext, useEffect } from "react";
import App3dContext from "../../app3d/context";
import useMountScene from "../../hooks/useMountScene";
import {
  setConstraints,
  setMode,
  setActivePlaceUuid,
  setShelfPosition,
  removeComponent,
  setPreviews,
} from "../../services/store/app3dSlice";
import useAppSelector from "../../hooks/useAppSelector";
import useAppDispatch from "../../hooks/useAppDispatch";
import { useError } from "../../hooks/useError";
import ErrorPopup from "../ErrorPopup/Index";

const Canvas: FC = (): JSX.Element => {
  const { app } = useContext(App3dContext);
  const { setErrorMessage, setOpenError } = useError();
  const dispatch = useAppDispatch();
  const mountScene = useMountScene({ app });
  const { show } = useAppSelector((state) => state.app3d);
  useEffect(() => {
    app
      ?.on("provideMode", (e) => dispatch(setMode(e)))
      .on(
        "provideIsDrag",
        (e) => (document.body.style.cursor = e ? "move" : "default")
      )
      .on("provideConstraints", (e) => dispatch(setConstraints(e)))
      .on("provideActiveComponentUuid", (e) => dispatch(setActivePlaceUuid(e)))
      .on("provideEntityIdToRemove", (e) => dispatch(removeComponent(e)))
      .on("provideShelfPosition", (e) => dispatch(setShelfPosition(e)))
      // .on('provideRotate', (e) => dispatch(setShelfPosition(e)))
      .on("provideErrorMessage", (e) => {
        setErrorMessage(e);
        setOpenError(true);
      })
      .on("providePreviews", (e) => dispatch(setPreviews(e)));
    return () => {
      app?.removeAllListeners();
    };
  }, [app, dispatch]);
  if (!app) return <div />;
  return (
    <div
      style={{
        position: "absolute",
        top: "0px",
        width: "100%",
        height: "100%",
        zIndex: 0,
        display: show ? "block" : "none",
      }}
    >
      <div ref={mountScene} />
    </div>
  );
};

export default Canvas;
