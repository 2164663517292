import { Box, CircularProgress } from '@mui/material';
import React from 'react';

type NoDataRowProps = {
  align?: 'center' | 'left' | 'right';
  circualSize?: number;
  item?: string[] | string;
};

const NoData = ({
  align = 'center',
  circualSize = 30,
  item,
}: NoDataRowProps) => {

  return (
    <div
      style={{
        textAlign: align,
        padding: '16px',
        fontWeight: 400,
        lineHeight: '30px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%'
      }}
    >
      <Box
        component="div"
      >
      </Box>
      <CircularProgress size={circualSize + 'px'} />
    </div>
  );
};

export default NoData;
