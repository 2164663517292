const formatErrorMessage = (errors: string[]): string => {
  let result = '';

  errors.forEach((err) => {
    result += err;
  });

  return result;
}

export default formatErrorMessage;
