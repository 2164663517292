import { httpClient } from "../httpClient";

export type UserCreateRequestValuesType ={
  name: string;
  email: string;
  password: string;
}

export type UserUpdateRequestValuesType ={
  id: string;
  name: string;
  email: string;
}

export const usersService = {
  getMe(){
    return httpClient.get('/users/profile');
  },
  getListUsers(){
    return httpClient.get('/users')
  },
  getUser(id:string){
    return httpClient.get('/users/' +id);
  },
  createUser(params: UserCreateRequestValuesType){
    return httpClient.post('/users/create', params)
  },
  updateUser(params: UserUpdateRequestValuesType){
    return httpClient.post('/users/update', params);
  },
  deleteUser(id:string){
    return httpClient.post('/users/delete', id);
  }
}