import {Accessories} from "../types/accessories";

const currencyPrice = (item: Accessories, currency: any): number => {
    switch (currency) {
        case 'gbp':
            return item.price_gbp;
        case 'dkk':
            return item.price_dkk;
        case 'eur':
            return item.price_eur;
        case 'nok':
            return item.price_nok;
        case 'sek':
            return item.price_sek;
        default:
            return item.price_gbp;
    }
};

export { currencyPrice };
