import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from '../src/locales/en/translation.json';
import translationDK from '../src/locales/dk/translation.json';

const resources = {
  en: {
      translation: translationEN
  },
  dk: {
    translation: translationDK,
  },
};

i18n
.use(initReactI18next)
.init({
  resources,
  lng: "dk",
  keySeparator: false, 

  interpolation: {
    escapeValue: false 
  }
});

export default i18n;
