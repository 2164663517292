import React, {useEffect, useState} from 'react';
import {deletePreset, presetsUser, setDeleteSuccessful} from '../../services/store/presetSlice';
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';
import DialogPresets from "./DialogPresets";
import DialogDelete from "./DialogDelete";
import {useHelp} from "../../hooks/useHelp";
import {EDialogs} from "../../types/general";
import {RootState} from "../../services/store";

export interface IPresetData {
  id: string;
  presetName: string;
  creatorName: string;
  date: string;
}

const PopupPresets = () => {
  const {
    setDialogName,
  } = useHelp();

  const [selectedData, setSelectedData] = useState<IPresetData>({
    id: '',
    presetName: '',
    creatorName: '',
    date: '',
  });

  const deleteSuccessful = useAppSelector((state: RootState) => state.presets.deleteSuccessful);
  const me = useAppSelector((state) => state.user.me);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (me && me.id !== null) {
      dispatch(presetsUser(me?.id!));
    }
  }, [me]);

  useEffect(() => {
    if (deleteSuccessful) {
      setDialogName(EDialogs.PRESETS);
      setDeleteSuccessful(false);
      dispatch(presetsUser(me?.id!));
    }
  }, [me, deleteSuccessful]);

  const askDeletePreset = (data: IPresetData) => {
    setSelectedData(data);
    setDialogName(EDialogs.DELETE_PRESET);
  }

  const handleDelete = () => {
    dispatch(deletePreset(selectedData.id));
  }

  return (
    <>
      <DialogPresets
        deletePreset={askDeletePreset}
      />
      <DialogDelete
        handleDelete={handleDelete}
        data={selectedData}
      />
    </>
  );
};



export default PopupPresets;
