import { styled, IconButton } from '@mui/material';

const StyledHomeButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    left: '60px',

    [theme.breakpoints.down('lg')]: {
        display: 'none',
    },
}));

export default StyledHomeButton;
