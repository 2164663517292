import useAppSelector from '../../../hooks/useAppSelector';
import { getBrandsAll, createBrand, deleteBrand, updateBrand } from '../../../services/store/brandSlice';
import { Box } from '@mui/material';
import { CarsDataGrid, DialogEditFields } from '../../../components/DataGrid/DataGrid';
import React, {useEffect, useMemo} from 'react';
import useAppDispatch from '../../../hooks/useAppDispatch';
import NoData from '../../../components/NoData';
import {useError} from "../../../hooks/useError";
import ErrorPopup from "../../../components/ErrorPopup/Index";

const dialogEditFields: DialogEditFields[] = [
    {
        field: 'name',
        name: 'name',
        type: 'text',
        label: 'Brand name',
        disabled: false,
    },
    {
        field: 'image',
        name: 'image',
        type: 'text',
        label: 'Image name',
        disabled: true,
    },
    {
        field: 'inputImage',
        name: 'imageFile',
        type: 'file',
        label: 'file',
        disabled: false,
    },
];

const ListBrandCars = () => {
    const brands = useAppSelector((state) => state.brand.brandsAll);
    const dispatch = useAppDispatch();
    const { setErrorMessage, setOpenError } = useError();

    const columns = useMemo(() => [
        { field: 'name', flex: 1 },
        {
            field: 'image',
            flex: 1,
            renderCell: (params: any) => {
                return !!params.value ? (
                    <>
                        <Box
                            style={{
                                maxHeight: '50px',
                                maxWidth: '50px',
                            }}
                            component="img"
                            src={params.value}
                        />
                    </>
                ) : (
                    <>-</>
                );
            },
        },
    ], []);

    useEffect(() => {
        dispatch(getBrandsAll());
    }, []);

    const onCreate = (val: FormData) => {
        const name = val.get('name') as string;
        if (!name?.trim()) {
            setErrorMessage('Brand name is required');
            setOpenError(true);
            return;
        }
        return createBrand(val);
    }

    const onUpdate = (val: FormData) => {
        const name = val.get('name') as string;
        if (!name?.trim()) {
            setErrorMessage('Brand name is required');
            setOpenError(true);
            return;
        }
        return updateBrand(val);
    }

    return brands.length > 0
      ? <>
          <CarsDataGrid
            dataSource={brands}
            gridColumns={columns}
            module={'brands'}
            moduleTitle={'Brands'}
            dialogEditFields={dialogEditFields}
            pathForInternalModule={'brands/models'}
            createRow={onCreate}
            updateRow={onUpdate}
            deleteRow={deleteBrand}
          />
          <ErrorPopup />
      </>
     : <Box component="div" display="flex" justifyContent="center">
        <NoData />
    </Box>;
};

export { ListBrandCars };
