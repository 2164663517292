import { Box, styled, Tab, Tabs } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import Headers from '../../components/Headers';
import Cars from './cars';
import Users from './users';

const Admin = () => {
  const [tab, setTab] = useState<number>(0);
  const history = useHistory();

  const onChangeTab = useCallback((event, tab) => {
    switch (tab) {
      case 0:
        setTab(0);
      history.push('/admin/users');
        break;
      case 1:
        setTab(1);
        history.push('/admin/brands');
        break;
    }
  }, [tab]);

  return (
    <>
      <Headers />
      <Box
        component="div"
        p="10px"
        display="flex"
        justifyContent="space-between"
      >
        <Box component="div">
          <StyledTabs
            orientation="vertical"
            variant="standard"
            onChange={onChangeTab}
            value={tab}
          >
            <StyledTab label="Users" value={0} />
            <StyledTab label="Cars" value={1} />
          </StyledTabs>
        </Box>
        <Box component="div" flex="3" borderLeft="1px solid #D9D9D9">
          <Switch>
            <Route path="/admin/users" component={Users} />
            <Route path="/admin/brands" component={Cars} />
          </Switch>
        </Box>
      </Box>
    </>
  );
};

export default Admin;

const StyledTabs = styled(Tabs)`
  & .MuiButtonBase-root {
    justify-content: flex-start;
    padding: 8px 24px;
  }
`;

const StyledTab = styled(Tab)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'flex-start',
}));
