import React, { ChangeEvent, useState } from 'react';
import {
    Box,
    Button,
    css,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    styled,
    TextField,
} from '@mui/material';
import CloseIcon from '../../icons/CloseIcon.svg';
import searchSvg from '../../icons/Search.svg';
import trashSvg from '../../icons/trash.svg';
import { generatePath, useHistory } from 'react-router-dom';
import { useHelp } from '../../hooks/useHelp';
import { useTranslation } from 'react-i18next';
import getFilteredPresets from '../../utils/getFilteredPresets';
import useAppSelector from '../../hooks/useAppSelector';
import {
    getPresetsList,
    selectAllPresets,
    getPreset,
    setPreset,
} from '../../services/store/presetSlice';
import { EDialogs } from '../../types/general';
import { IPresetData } from './index';
import { Presets } from '../../types/preset';
import useAppDispatch from '../../hooks/useAppDispatch';
import { useEffect } from 'react';
import { getPackagesParams } from '../../services/store/packagieSlice';
import { loadCar, loadPreset } from '../../services/store/app3dSlice';
import moment from 'moment';
import SpinnerAnimated from '../Spinner/SpinnerAnimated';
import {setAccessoriesListTemp} from "../../services/store/accessoriesSlice";

const LoadingBox = styled(Box)`
    width: 568px;
    display: flex;
    justify-content: center;
    padding: 30px 0;
`;

interface IProps {
    deletePreset: (data: IPresetData) => void;
}

const DialogPresets = ({ deletePreset }: IProps) => {
    const { dialogName, openDialog, setOpenDialog, setDialogName } = useHelp();

    const open = dialogName === EDialogs.PRESETS && Boolean(openDialog);

    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useAppDispatch();
    const [searchText, setSearchText] = useState<string>('');
    const presets = useAppSelector(selectAllPresets);
    const filteredPresets = getFilteredPresets(presets, searchText);
    const loading = useAppSelector((state) => state.presets.loading);

    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
    };

    const selectItem = async (item: Presets) => {
        history.push(
            generatePath('/configurator/equip/:id', {
                id: item.id,
            })
        );
        setDialogName(null);
        await dispatch(getPresetsList(item.package_car_id));
        await dispatch(getPackagesParams(item.package_car_id));
        const res = await dispatch(getPreset(item.id));
        // @ts-ignore
        const preset = res?.payload?.data;
        if (preset) {
            dispatch(loadPreset(preset));
            dispatch(setPreset(preset));
            dispatch(setAccessoriesListTemp([]));
            history.push(
              generatePath('/configurator/equip/:id', {
                  id: preset.id,
              })
            );
        }
        setOpenDialog(false);
    };

    const { packagesParams } = useAppSelector((state) => state.packagies);

    useEffect(() => {
        if (openDialog) dispatch(loadCar(packagesParams));
    }, [dispatch, packagesParams, openDialog]);

    const closeDialog = () => {
        setDialogName(null);
        setOpenDialog(false);
    };

    return (
        <Dialog
            open={open}
            PaperProps={{
                sx: {
                    padding: '32px',
                    margin: '0',
                },
            }}
        >
            <DialogTitle
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <StyledTitle>{t('openDialog.title')}</StyledTitle>
                <IconButton onClick={closeDialog}>
                    <Box
                        component="img"
                        src={CloseIcon}
                        alt=""
                        sx={{ height: '22px', width: '22px' }}
                    />
                </IconButton>
            </DialogTitle>

            {
                loading
              ? <LoadingBox>
                      <SpinnerAnimated />
                  </LoadingBox>
                  : (
                    presets.length > 0 ?
                      <>
                          <SearchBar
                            placeholder={t('openDialog.search')}
                            value={searchText}
                            onChange={handleSearch}
                            InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                      <img src={searchSvg} alt="" />
                                  </InputAdornment>
                                ),
                            }}
                          />
                          <ContentContainer>
                              {filteredPresets.map((item, index) => (
                                <ItemConteiner key={index}>
                                    <Box component="div" gap="8px">
                                        <ItemTitle>{item.name}</ItemTitle>
                                        <ItemSubTitle>{item.code}</ItemSubTitle>
                                    </Box>
                                    <ButtonsContainer>
                                        <IconButton
                                          onClick={() =>
                                            deletePreset({
                                                id: item.id,
                                                date: item.created_at
                                                  ? moment(item.created_at, 'YYYY-MM-DD HH:mm:ss').format(
                                                    'YYYY/MM/DD'
                                                  )
                                                  : '',
                                                creatorName: item.user_name || '',
                                                presetName: item.name,
                                            })
                                          }
                                        >
                                            <img src={trashSvg} alt="" />
                                        </IconButton>
                                        <ItemButton
                                          onClick={() => {
                                              selectItem(item);
                                          }}
                                        >
                                            {t('openDialog.item.button')}
                                        </ItemButton>
                                    </ButtonsContainer>
                                </ItemConteiner>
                              ))}
                          </ContentContainer>
                      </>
                      :
                      <Box
                        component="div"
                        style={{
                            width: '568px',
                            height: '392px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                      >
                          <ItemTitle>User has not created presets yet</ItemTitle>
                      </Box>
                  )
            }
        </Dialog>
    );
};

const StyledTitle = styled(Box)(({ theme }) => ({
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '36px',
    lineHeight: '40px',
    color: theme.palette.primary.main,
}));

const ContentContainer = styled(DialogContent)(({ theme }) => ({
    padding: '32px 16px 40px 40px',
    width: '568px',
    height: '392px',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    marginRight: '24px',

    '&::-webkit-scrollbar': {
        width: '8px',
    },

    '&::-webkit-scrollbar-thumb': {
        background: '#B3C3CF',
        borderRadius: '14px',
    },
}));

const ItemConteiner = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
}));

const ItemTitle = styled(Box)(({ theme }) => ({
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '28px',
    color: theme.palette.primary.main,
    marginBottom: '8px',
}));

const ItemSubTitle = styled(Box)(({ theme }) => ({
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.5px',
    opacity: '0.6',
    color: theme.palette.primary.main,
}));

const ItemButton = styled(Button)(
    ({ theme }) => css`
        min-width: 131px;
        max-height: 48px;
        color: #fcfdfd;
        background: ${theme.palette.primary.main};
        border-radius: 14px;
        align-items: center;
        justify-content: center;
        padding: 16px 32px;
        gap: 10px;
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        font-size: 20px;
        text-transform: capitalize;

        &:hover {
            background: ${theme.palette.secondary.main};
        }
    `
);

const ButtonsContainer = styled(Box)(
    () => css`
        display: flex;
        gap: 20px;
    `
);

const SearchBar = styled(TextField)`
    margin: 10px 23px;

    .MuiInputBase-root {
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 0.1px;
        color: #00365f;

        .MuiInputAdornment-root {
            margin-right: 10px;
        }

        fieldset {
            border: none;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
            border-radius: 12px;
        }
    }
`;

export default DialogPresets;
