import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';
import { carsService } from '../api/cars';

export type Image = {
    nameImage: any;
    module: 'brands' | 'packages' | 'models';
};

type imageInitialState = {
    loading: boolean;
    image: any;
};

const initialState: imageInitialState = {
    loading: false,
    image: undefined,
};

const imageSlice = createSlice({
    name: 'image',
    initialState,
    reducers: {
        setLoading: (state, { payload }) => {
            state.loading = payload;
        },
        setImage: (state, { payload }) => {
            console.log(payload);
            state.image = payload;
        },
    },
});

export const { setLoading, setImage } = imageSlice.actions;

export const getImage = createAsyncThunk(
    '/api/',
    async (params: Image, { dispatch }) => {
        dispatch(setLoading(true));
        const response = await carsService.getImage(params);
        if (response.status === 200) {
            console.log(response.data);
            dispatch(setImage(response.data));
        }
        dispatch(setLoading(false));
    }
);

export const imageFile = (state: RootState) => state.image.image;

export default imageSlice.reducer;
