interface IComponent {
    uuid: string;
    name_dk: string;
    name_eng: string;
    image: string;
    price_dkk: string,
    price_eur: string,
    price_gbp: string,
    price_nok: string,
    price_sek: string,
    net_weight: number;
    item_number: string;
    estimatePosition: string;
    component_type_id: number;
    glb_model: string;
    height: number;
    width: number;
    depth: 380;
    id: number;
    position: number;
    quantity: number;
    label: string;
}

export type RackComponent = IComponent & { component_type_id: 1 };
export type ShelfComponent = IComponent & { component_type_id: 2 };
export type InitialComponent = Pick<IComponent, 'uuid' | 'estimatePosition'>;

export type Component = InitialComponent | RackComponent | ShelfComponent;
export type FilledComponent = RackComponent | ShelfComponent;

export function isRackComponent(component: Component): component is RackComponent {
    return (component as RackComponent).id !== undefined && (component as RackComponent).component_type_id === 1;
}

export function isShelfComponent(component: Component): component is ShelfComponent {
    return (
        (component as ShelfComponent).id !== undefined &&
        [2, 3].includes((component as ShelfComponent).component_type_id)
    );
}
