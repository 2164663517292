import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';
import { carsService } from '../api/cars';

export type Models = {
    id: string;
    image: string;
    brand_car_id: string;
    name: string;
};

export type ModelsProps = {
    image: string;
    brand_car_id: string;
    name: string;
};

type modelSliceInitialState = {
    loading: boolean;
    brandModels: Models[];
    modelnfo: Models | null;
};

const initialState: modelSliceInitialState = {
    loading: false,
    brandModels: [],
    modelnfo: null,
};

const modelSlice = createSlice({
    name: 'model',
    initialState,
    reducers: {
        setLoading: (state, { payload }) => {
            state.loading = payload;
        },
        setBrandModels: (state, { payload }) => {
            state.brandModels = payload;
        },
        setModelInfo: (state, { payload }) => {
            state.modelnfo = payload;
        },
    },
});

export const { setLoading, setBrandModels, setModelInfo } = modelSlice.actions;
//получить список моделей конкретного бренда
export const getBrandModels = createAsyncThunk(
    '/api/brand/models',
    async (id: string, { dispatch }) => {
        dispatch(setLoading(true));
        const response = await carsService.getBrandModels(id);
        if (response.status === 200) {
            dispatch(setBrandModels(response.data));
        }
        dispatch(setLoading(false));
    }
);
//получить 1 модель
export const getModel = createAsyncThunk(
    '/api/models',
    async (id: string, { dispatch }) => {
        dispatch(setLoading(true));
        const response = await carsService.getModel(id);
        if (response.status === 200) {
            dispatch(setModelInfo(response.data));
        }
        dispatch(setLoading(false));
    }
);
//создать модель
export const createModel = createAsyncThunk(
    '/api/models/create',
    async (params: FormData, { dispatch }) => {
        try {
            dispatch(setLoading(true));
            await carsService.createModels(params);
            return true;
        } catch (err) {
        } finally {
            let brandId = params.get('brand_car_id') as string;
            dispatch(getBrandModels(brandId));
        }
        return false;
    }
);
//обновить модель
export const updateModel = createAsyncThunk(
    '/api/models/update',
    async (params: FormData, { dispatch }) => {
        try {
            dispatch(setLoading(true));
            await carsService.updateModels(params);
            return true;
        } catch (err) {
        } finally {
            let brandId = params.get('brand_car_id') as string;
            dispatch(getBrandModels(brandId));
        }
        return false;
    }
);
//удалить модель
export const deleteModel = createAsyncThunk(
    '/api/models/delete',
    async (params: Models, { dispatch }) => {
        try {
            dispatch(setLoading(true));
            await carsService.deleteModels(params.id);
            return true;
        } catch (err) {
        } finally {
            dispatch(getBrandModels(params.brand_car_id));
        }
        return false;
    }
);

export const selectLoading = (state: RootState) => state.model.loading;
export const selectBrandModels = (state: RootState) => state.model.brandModels;
export const selectModelInfo = (state: RootState) => state.model.modelnfo;

export default modelSlice.reducer;
