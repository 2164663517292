import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';
import { carsService } from '../api/cars';

export type Packagies = {
    name: string;
    image?: string;
    length?: number;
    height?: number;
    width_read_door?: number;
    width_sliding_door?: number;
    model_car_id: string;
    double_door?: boolean;
    id: string;
    a?: number;
    b?: number;
    c?: number;
    d?: number;
    e?: number;
    f?: number;
    g?: number;
    h?: number;
    i?: number;
    j?: number;
    k?: number;
    l?: number;
    m?: number;
    n?: number;
    o?: number;
    p?: number;
    r?: number;
    s?: number;
};

export type PackagiesProps = {
    name: string;
    image: string;
    length?: number;
    height?: number;
    width_read_door?: number;
    width_sliding_door?: number;
    model_car_id: string;
    double_door: boolean;
    a: number;
    b: number;
    c: number;
    d: number;
    e: number;
    f: number;
    g: number;
    h: number;
    i: number;
    j: number;
    k: number;
    l: number;
    m: number;
    n: number;
    o: number;
    p: number;
    r: number;
    s: number;
};
export type ErrorProps = {
    message: any;
};

type packagiesSliceInitialState = {
    loading: boolean;
    success: boolean;
    modelPackagies: Packagies[];
    packagesParams: Packagies;
    error: ErrorProps | null;
};

const initialState: packagiesSliceInitialState = {
    loading: false,
    success: false,
    modelPackagies: [],
    packagesParams: { id: '', name: '', model_car_id: '' },
    error: null,
};

export const getPackagesParams = createAsyncThunk('/packagies', async (id: string) => {
    return await carsService.getPackagesParams(id);
});

const packagiesSlice = createSlice({
    name: 'packagies',
    initialState,
    reducers: {
        setLoading: (state, { payload }) => {
            state.loading = payload;
        },
        setSuccess: (state, { payload }) => {
            state.success = payload;
        },
        setModelPackagies: (state, { payload }) => {
            state.modelPackagies = payload;
        },
        setPackagesParams: (state, { payload }) => {
            state.packagesParams = payload;
        },
        setError: (state, { payload }) => {
            state.error = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPackagesParams.pending, (state) => {
                state.loading = true;
            })
            .addCase(getPackagesParams.fulfilled, (state, action) => {
                state.packagesParams = action.payload.data;
                state.loading = false;
            })
            .addCase(getPackagesParams.rejected, (state, action) => {
                state.error = action.error as ErrorProps;
                console.log(state.error);
            });
    },
});

export const {
    setLoading,
    setSuccess,
    setModelPackagies,
    setPackagesParams,
    setError,
} = packagiesSlice.actions;

//получить список комплектующих модели
export const getModelPackagies = createAsyncThunk(
    '/api/models/packagies',
    async (id: string, { dispatch }) => {
        dispatch(setLoading(true));
        dispatch(setError(null));
        const response = await carsService.getModelPackagies(id);
        if (response.status === 200) {
            dispatch(setModelPackagies(response.data));
        }
        dispatch(setLoading(false));
    }
);

//создать комплектующее
export const createPackagies = createAsyncThunk(
    '/api/packagies/create',
    async (params: { dialogFields: FormData }, { dispatch }) => {
        let id = '';
        try {
            dispatch(setLoading(true));
            // dispatch(setSuccess(true));
            const res = await carsService.createPackagies(params.dialogFields);
            id = res?.data?.data?.model_car_id || '';
        } catch (err) {
        } finally {
            dispatch(getModelPackagies(id));
        }
    }
);

//обновить комплектующее
export const updatePackagies = createAsyncThunk(
    '/api/packagies/update',
    async (params: { dialogFields: FormData; id: string }, { dispatch }) => {
        const { dialogFields, id } = params;
        try {
            dispatch(setLoading(true));
            dispatch(setError(null));
            await carsService.updatePackagies(dialogFields);
        } catch (err: any) {
            dispatch(setError(err.response.data.message));
        } finally {
            dispatch(getModelPackagies(id));
        }
    }
);

//удалить комплектующее
export const deletePackagies = createAsyncThunk(
    '/api/packagies/delete',
    async (params: Packagies, { dispatch }) => {
        try {
            dispatch(setLoading(true));
            await carsService.deletePackagies(params.id);
            return true;
        } catch {
        } finally {
            dispatch(getModelPackagies(params.model_car_id));
        }
        return false;
    }
);

export const selectLoading = (state: RootState) => state.packagies.loading;
export const selectSuccess = (state: RootState) => state.packagies.success;
export const selectModelPackagies = (state: RootState) =>
    state.packagies.modelPackagies;
export const selectPackagesParams = (state: RootState) =>
    state.packagies.packagesParams;

export default packagiesSlice.reducer;
