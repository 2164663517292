import React, { FC, useEffect, useState } from 'react';
import { Box, styled } from '@mui/material'
import CommentIcon from '../../icons/CommentIcon.svg';

interface HelperProps {
  text: string;
}

const Helper: FC<HelperProps> = ({ text }) => {
  const [helpVisible, setHelpVisible] = useState<boolean>(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setHelpVisible(false);
    }, 10000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Box
      component="div"
      >
      {helpVisible ? (<HelperBox gap="12px">
        <img src={CommentIcon} alt="" />
        <HelperText>
          {text}
        </HelperText>
      </HelperBox>) : (<></>)}

    </Box>

  );
};

export default Helper;

const HelperBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  padding: '8px 16px 16px 8px',
  maxWidth: '512px',
  maxHeight: '112px',
  background: '#E5EBEF',
  borderRadius: '14px',
  margin: '24px 59px 0px',
}));

const HelperText = styled(Box)(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '16px',
  lineHeight: '20px',
  color: theme.palette.secondary.main,
}));
