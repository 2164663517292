import React from 'react';
import { Box, MenuItem, Select, styled } from '@mui/material';
import { useHelp } from '../../hooks/useHelp';

const SwitchCurrency = () => {
  const { currency, setCurrency } = useHelp();

  const currencyItems = [
    { key: 'gbp', label: 'GBP' },
    { key: 'dkk', label: 'DKK' },
    { key: 'eur', label: 'EUR' },
    { key: 'nok', label: 'NOK' },
    { key: 'sek', label: 'SEK' },
  ];

  const handleCurrency = (item: string) => {
    setCurrency(item);
  };
  return (
    <>
      <StyledSelect value={currency}>
        {currencyItems.map((item, index) => (
          <StyledSelectItem
            key={index}
            role="listitem"
            value={item.key}
            onClick={() => handleCurrency(item.key)}
          >
            <Box
              component="div"
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap="8px"
            >
              {item.label}
            </Box>
          </StyledSelectItem>
        ))}
      </StyledSelect>
    </>
  );
};

const StyledSelect = styled(Select)(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '16px',
  lineHeight: '20px',
  margin: '0px 20px',
  color: '#E9EFF1',
  cursor: 'pointer',
  gap: '4px',
  [theme.breakpoints.down('md')]: {
    margin: '0px 15px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    display: 'none',
  },
  '& .MuiSvgIcon-root': {
    color: '#FCFDFD',
  },
}));

const StyledSelectItem = styled(MenuItem)(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '16px',
  lineHeight: '20px',
  margin: '0px 20px',
  color: theme.palette.primary.main,
  cursor: 'pointer',
  [theme.breakpoints.down('md')]: {
    margin: '0px 15px',
  },
}));

export default SwitchCurrency;
