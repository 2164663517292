import { createContext } from "react";
import {TDialogNames} from "../../types/general";

export interface IHelpContext{
    language?: string;
    setLanguage: (lang: string) => void;
    brand?: string;
    setBrand: (brand: string) => void;
    model?: string;
    setModel: (model: string) => void;
    packagesModel?: string;
    setPackagesModel: (packages: string) => void;
    packagesId?: string,
    setPackagesId: (packages: string) => void;
    currency?: string;
    setCurrency: (currency: string) => void;
    weight?: string;
    setWeight:(weight: string) => void;
    totalSum?: string;
    setTotalSum:(totalSum: string) => void;
    openDialog?: boolean;
    setOpenDialog: (open: boolean) => void;
    dialogName?: TDialogNames;
    setDialogName: (name: TDialogNames) => void;
}

const HelpContext = createContext<IHelpContext>({
    setLanguage(lang: string) {},
    setCurrency(currency: string) {},
    setBrand(brand: string) {},
    setModel(model: string) {},
    setPackagesModel(packages: string) {},
    setPackagesId(packagesId: string) {},
    setWeight(weight: string) {},
    setTotalSum(totalSum: string) {},
    setOpenDialog(open: boolean) {},
    setDialogName(name: TDialogNames) {},
});

export {HelpContext}
