import { LightParams } from '../SceneWrapper';
import * as THREE from 'three';
const directionalLight: LightParams = {
    type: 'directional',
    color: 0xffffff,
    intensity: 0.8 * Math.PI,
    position: new THREE.Vector3(0.5, 5, 0.866),
};

export default directionalLight;
