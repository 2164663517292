import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';
import { presetsService } from '../api/presets';
import {PresetSlice, CreatePresetProps, AttachPresetProps, UpdatePresetProps} from '../../types/preset';
import {setAccessoriesListTemp} from "./accessoriesSlice";

const initialState: PresetSlice = {
    loading: false,
    presetsList: [],
    preset: null,
    idNewPreset: null,
    overviewPreset: null,
    overviewItemList: null,
    attachSucceful: false,
    deleteSuccessful: false,
    allPresetsList: [],
    error: null,
    success: null,
};

const presetsSlice = createSlice({
    name: 'presets',
    initialState,
    reducers: {
        setLoading: (state, { payload }) => {
            if (payload) {
                console.log('loading presets');
            }
            state.loading = payload;
        },
        setPresets: (state, { payload }) => {
            state.presetsList = payload;
        },
        setPreset: (state, { payload }) => {
            state.preset = payload;
        },
        setIdNewPreset: (state, { payload }) => {
            state.idNewPreset = payload;
        },
        setOverviewPreset: (state, { payload }) => {
            state.overviewPreset = payload;
        },
        setOverviewItemList: (state, { payload }) => {
            state.overviewItemList = payload;
        },
        setAttachSucceful: (state, { payload }) => {
            state.attachSucceful = payload;
        },
        setDeleteSuccessful: (state, { payload }) => {
            state.deleteSuccessful = payload;
        },
        setAllPresets: (state, { payload }) => {
            state.allPresetsList = payload;
        },
        setError: (state, { payload }) => {
            state.error = payload;
        },
        setSuccess: (state, { payload }) => {
            state.success = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPresetsList.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = null;
            })
            .addCase(getPresetsList.fulfilled, (state, { payload }) => {
                state.presetsList = payload.data;
                state.loading = false;
                state.attachSucceful = false;
            })
            .addCase(getPresetsList.rejected, (state, action) => {
                state.error = action.error.message ?? null;
                state.attachSucceful = false;
            })
            .addCase(getPreset.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = null;
            })
            .addCase(getPreset.fulfilled, (state, { payload }) => {
                state.preset = payload.data;
                state.loading = false;
            })
            .addCase(getPreset.rejected, (state, action) => {
                state.error = action.error.message ?? null;
            });
    },
});

export const {
    setLoading,
    setPresets,
    setPreset,
    setIdNewPreset,
    setOverviewPreset,
    setOverviewItemList,
    setAttachSucceful,
    setDeleteSuccessful,
    setAllPresets,
    setError,
    setSuccess,
} = presetsSlice.actions;

export const getPresetsList = createAsyncThunk('/kit-presets', async (id: string, { dispatch }) => {
    return await presetsService.getListPreset(id);
});

export const getPreset = createAsyncThunk('/kit-presets/{id}', async (id: string, { dispatch }) => {
    return await presetsService.getPreset(id);
});

export const createPreset = createAsyncThunk('kit-presets/create', async (data: CreatePresetProps, { dispatch }) => {
    dispatch(setLoading(true));
    try {
        dispatch(setError(null));
        dispatch(setSuccess(null));
        const response = await presetsService.createPreset(data.data);
        if (response.status === 200) {
            dispatch(setPreset(response.data.data));
            dispatch(setAccessoriesListTemp([]));
            await dispatch(
                attachPreset({
                    id: response.data.data.id,
                    package_car_ids: data.packagesid,
                })
            );
            await dispatch(getPreset(response.data.data.id));
        }
        return response.data.data.id;
    } catch (err: any) {
        dispatch(setError(err.response.data.message));
    } finally {
        dispatch(setLoading(false));
    }
    return false;
});

export const updatePreset = createAsyncThunk('kit-presets/update', async (data: UpdatePresetProps, { dispatch }) => {
    dispatch(setLoading(true));
    try {
        dispatch(setError(null));
        dispatch(setSuccess(null));
        let response = await presetsService.updatePreset(data.data);
        if (response.status === 200) {
            dispatch(setAccessoriesListTemp([]));
            await dispatch(getPreset(data.data.id));
        }

        return true;
    } catch (err: any) {
        dispatch(setError(err.response.data.message));
    } finally {
        dispatch(setLoading(false));
    }
    return false;
});

export const attachPreset = createAsyncThunk(
    'kit-presets/attach-packages-cars',
    async (data: AttachPresetProps, { dispatch }) => {
        try {
            dispatch(setError(null));
            dispatch(setSuccess(null));
            let response = await presetsService.attachPackages(data);
            if (response.status === 200) {
                dispatch(setAttachSucceful(true));
            }
            return true;
        } catch (err: any) {
            dispatch(setError(err.response.data.message));
        }
        return false;
    }
);

export const deletePreset = createAsyncThunk('kit-presets/delete', async (id: string, { dispatch }) => {
    dispatch(setLoading(true));
    dispatch(setDeleteSuccessful(false));
    const response = await presetsService.deletePreset(id);
    if (response.status === 200) {
        dispatch(setDeleteSuccessful(true));
        dispatch(setAccessoriesListTemp([]));
    }
    dispatch(setLoading(false));
});

export const overviewPreset = createAsyncThunk('kit-presets/total-sum', async (id: string, { dispatch }) => {
    dispatch(setLoading(true));
    dispatch(setAttachSucceful(false));
    const response = await presetsService.presetOverview(id);
    if (response.status === 200) {
        dispatch(setOverviewPreset(response.data));
        dispatch(setAccessoriesListTemp([]));
    }
    dispatch(setLoading(false));
});

export const allComponentsPreset = createAsyncThunk(
    '/kit-presets/{id}/components',
    async (id: string, { dispatch }) => {
        dispatch(setLoading(true));
        const response = await presetsService.allComponentOverview(id);
        if (response.status === 200) {
            dispatch(setOverviewItemList(response.data));
        }
        dispatch(setLoading(false));
    }
);

export const presetsUser = createAsyncThunk('kit-presets/{id}/for-user', async (id: string, { dispatch }) => {
    dispatch(setLoading(true));
    const response = await presetsService.userPresets(id);
    if (response.status === 200) {
        dispatch(setAllPresets(response.data));
    }
    dispatch(setLoading(false));
});

export const selectPresets = (state: RootState) => state.presets.presetsList;
export const selectPreset = (state: RootState) => state.presets.preset;
export const selectAllPresets = (state: RootState) => state.presets.allPresetsList;

export default presetsSlice.reducer;
