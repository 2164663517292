import {StyleSheet} from "@react-pdf/renderer";

export default StyleSheet.create({
  page: {
    backgroundColor: '#FFFFFF',
    padding: '26px 80px 26px 80px',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    height: '40px',
    alignItems: 'center',
    marginBottom: '30px',
  },
  logo: {
    height: '38px',
    width: '100px',
  },
  userTitle: {
    fontSize: '10px',
    color: '#5D686D',
  },
  userItem: {
    fontSize: '12px',
    color: '#00365F',
  },
  presetSection: {
    flexDirection: 'column',
    marginBottom: '20px',
  },
  presetSectionTitle: {
    fontSize: '32px',
    color: '#00365F',
    marginBottom: '24px',
  },
  presetSectionModelCar: {
    fontSize: '14px',
    marginBottom: '16px',
    color: '#5D686D',
  },
  footerSection: {
    flexDirection: 'column',
  },
  footerText: {
    fontSize: '8px',
    marginBottom: '16px',
    color: '#00365F',
  },
  footerContact: {
    flexDirection: 'row',
  },
  footerContactText: {
    fontSize: '10px',
    color: '#00365F',
    flex: 1,
  },
  footerDate: {
    fontSize: '10px',
    textAlign: 'right',
    color: '#BABEC1',
    flex: 1,
  },
  tableTitle: {
    fontSize: '14px',
    marginBottom: '10px',
    color: '#00365F',
  },
  tableFirstRow: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: '1px',
    marginBottom: '5px',
    borderBottom: '1px solid #00365F',
  },
  tableFirstRowNoStripe: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '6px',
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    height: '20px',
  },
  firstTableHeaderText: {
    fontSize: '10px',
    color: '#66869F',
    flex: 1,
  },
  firstComponentsTableHeaderText: {
    fontSize: '10px',
    color: '#66869F',
    flex: 3,
  },
  firstComponentsTableHeaderText1: {
    fontSize: '12px',
    fontWeight: 700,
    color: '#66869F',
    flex: 3,
  },
  secondTableHeaderText: {
    fontSize: '10px',
    color: '#66869F',
    flex: 1,
  },
  secondTableHeaderText1: {
    fontSize: '12px',
    fontWeight: 700,
    color: '#66869F',
    flex: 1,
  },
  secondPresetTableHeaderText: {
    fontSize: '10px',
    color: '#66869F',
    flex: 3,
  },
  thirdTableHeaderText: {
    fontSize: '10px',
    color: '#66869F',
    flex: 1,
  },
  thirdTableHeaderText1: {
    fontSize: '12px',
    fontWeight: 700,
    color: '#66869F',
    flex: 1,
  },
  fourthdTableHeaderText: {
    fontSize: '10px',
    color: '#66869F',
    textAlign: 'right',
    flex: 1,
  },
  fourthdTableHeaderText1: {
    fontSize: '12px',
    fontWeight: 700,
    color: '#66869F',
    textAlign: 'right',
    flex: 1,
  },
  accessoriesRow: {
    flexDirection: 'row',
    paddingTop: '4px',
    paddingBottom: '4px',
    marginBottom: '4px',
    borderBottom: '1px solid #00365F',
    borderTop: '1px solid #00365F',
  },
  componentnsSection: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
  },
  imageTitle: {
    fontSize: '14px',
    marginBottom: '53px',
    color: '#00365F',
  },
});
