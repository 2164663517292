import Axios from 'axios'

const config = {
  baseURL: process.env.REACT_APP_HTTP_API_URL + '/api',
  //baseURL: 'https://raaco.web3dev.group' + '/api',
};

export const baseURLconfig = config.baseURL; //для пути изображений

export const axios = Axios.create(config);



export const httpClient = {
  setToken(token: string | null) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  },

  get(url: string, data?: any){
    return axios.get(url, {params : data});
  },

  post(url: string, data?:any){
    return axios.post(url, data);
  },

  put(url: string, data?: any){
    return axios.put(url, data);
  },

  patch(url: string, data?: any){
    return axios.patch(url, data);
  },

  delete(url: string, data?: any){
    return axios.delete(url, data);
  },
};
